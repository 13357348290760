<template>
    <br><br>
    <p class="hidden">Bienvenue {{ userName }}</p>
    <p class="hidden">{{ iduser }}</p>
    <p class="hidden">{{ typeAbonnement }}</p>

    <p class="hidden">{{ userEmail }}</p>
    <p class="hidden">{{ userContact }}</p>
    <div @click="hide, menu_mobile2 = false" class="w-screen ">
        <br>
        <div class="w-screen flex justify-center">
            <div
                class="w-11/12 flex max-lg:flex-col-reverse max-lg:justify-center lg:justify-between items-center carousel bg-white py-2 ">
                <div class="lg:w-1/2 max-md:hidden lg:block flex justify-start items-center">
                    <div class="flex justify-start items-center h-full">
                        <div class="h-fit">
                            <p class="text-left max-sm:text-sm text-gray-600 max-lg:py-4">
                                Bienvenue sur notre plateforme dédiée à l'information agricole, où les données les plus
                                récentes se combinent à des analyses
                                approfondies pour éclairer vos décisions et optimiser vos rendements. Nous
                                permettons de fournir
                                des divers informations agricoles et des conseils pratiques sur ce domaine.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/2 max-lg:w-full">
                    <div class=" w-full">
                        <img src="../../assets/img.jpeg " alt="image panel" class="clip-path-polygon max-lg:hidden">
                        <img src="../../assets/img.jpeg " alt="image panel" class="lg:hidden">
                    </div>
                </div>
                >
            </div>
        </div>
        <br><br>
        <div class="flex w-screen  flex-col   bg-slate-50 justify-center items-center ">
            <Service></Service>
            <br>
            <div class="w-screen bg-white flex justify-center">
                <Propos></Propos>
            </div>
            <br>
            <div class="flex justify-center items-center w-screen" id="Contact">
                <div class="bg-slate-50 py-3  overflow-hidden w-full flex justify-center items-center relative z-10">
                    <div class="flex flex-col justify-start w-11/12 items-center">
                        <div class="">
                            <h1 class="max-sm:text-xl sm:text-3xl text-green-600  font-semibold">
                                Contact
                            </h1>
                        </div>
                        <div
                            class=" ">
                            <div class=" w-full ">
                                <div class="w-full mb-12 lg:mb-0">
                                    <h2 class="
                      text-dark
                      mb-6
                      uppercase
                      font-bold
                      text-xl
                      ">


                                    </h2>
                                    <p
                                        class="text-center text-body-color leading-relaxed mb-9 text-gray-600">
                                        N'hesitez pas à nous contacter pour toute question, demande d'information ou
                                        assistance. <br> Nous sommes là pour vous aider.
                                    </p>
                                </div>
                            </div>
                            <div class="w-full flex justify-center items-center">
                                <form class="max-md:w-full md:w-8/12 flex flex-col items-start justify-center p-4 bg-white shadow-sm shadow-gray-300 rounded"
                                    @submit.prevent="envoyerMessage">
                                    <div class="mb-3 w-full">

                                        <input type="text" v-model="userName" disabled placeholder="Votre nom" class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <input type="email" v-model="userEmail" disabled placeholder="votre e-mail"
                                            class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <input type="text" v-model="userContact" disabled placeholder="Votre numéro"
                                            class="
                        w-full hidden
                        placeholder:text-sm
                        py-1
                        px-1
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary rounded
                        " />
                                    </div>
                                    <div class="mb-3 w-full">
                                        <textarea rows="6" v-model="message" placeholder="Votre message" class="
                        w-full
                        rounded
                        py-1 px-2
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        placeholder:text-sm
                        focus-visible:shadow-none
                        focus:border-primary
                        "></textarea>

                                    </div>
                                    <div class="w-full">
                                        <button type="submit" class="
                            w-full
                            text-white
                            bg-green-600 hover:bg-green-500
                            px-1
                            border border-green-600 hover:border-green-600 
                             py-1
                            transition
                            ">
                                            Envoyer
                                        </button>
                                    </div>
                                </form>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" menu_mobile:menu_mobile
        @menu_mobile_show="data_menu_mobile"></Header2>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection"
        v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class=" bg-gray-400 text-white p-1" @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="  bg-blue-400 hover:bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div class=" fixed top-0 modal-mobile flex  w-screen h-screen shadow-sm shadow-gray-300 bg-black bg-opacity-50 items-center justify-center"
        v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
            <div class="flex justify-end items-center">
                <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2 = false"></button></div>
            </div>
            <div class="p-6 flex flex-col gap-5 text-left">
                <div>
                    <RouterLink to="/Client/Accueil2" class="font-bold">
                        Accueil
                    </RouterLink>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Produit</summary>
                        <div class="flex flex-col p-1 gap-1 ml-5">
                            <div>
                                <RouterLink to="/Client/Produit">
                                    <h6>produit culture</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Marché</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Prix">
                                    <h6>Prix</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Client">
                                    <h6>Client</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Fournisseur">
                                    <h6>Fournisseur</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Outils à la decision</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Courbe">
                                    <h6>Courbe</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
            </div>
        </div>
    </div>


    <div class="modal_ajout fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start"
        v-show="modalValidation">
        <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4 mt-20">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modalValidation = false"></div>
            </div>
            <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2"></div>
            <div class="w-9/12">
                <p class="text-gray-500">Votre message a été bien envoyé !</p>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>
<style sccoped>
.clip-path-polygon {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
}
</style>
<script>

//import { RouterLink } from 'vue-router'
import apiUrl from '../../apiConfig';
import axios from 'axios'
import Propos from '@/components/Propos.vue'
import Service from '@/components/Service.vue'
import '@/components/style/header.css'
import '@/components/style/bodyAccueil.css'
import '@/components/style/all.css'
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
//import Body2 from '@/components/Body2.vue'
export default {
    name: "Accueil2",
    components: {
        Header2,
        Footer,
        Propos,
        Service
        //Body2,


    },
    data() {
        return {

            typeAbonnement: '',
            modalValidation: false,
            iduser: '',
            message: '',
            typeAbo: '',
            userName: '',
            name: '',
            userEmail: '',
            email: '',
            userContact: '',
            contact: '',

            menu_mobile: false,
            modal_deconnection: false

        }
    },


    mounted() {

        // Accéder au nom de l'utilisateur à partir du computed
        /*const userName = this.userName;
        const userEmail = this.userEmail;
        const userId = this.userId;
        console.log('Nom de l\'utilisateur:', userName);
        console.log('Email de l\'utilisateur:', userEmail);
        console.log('Id de l\'utilisateur:', userId);*/

        // Pour récupérer depuis le localStorage
        this.iduser = localStorage.getItem('iduser');


        this.getTypeAbonnement();
        this.getUserName();
        this.getUserEmail();
        this.getUserContact();
    },

    methods: {
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        },


        getTypeAbonnement() {
            axios.get(apiUrl + 'api/type_abonnement', { params: { iduser: this.iduser } })
                .then(response => {
                    this.typeAbo = response.data[0].type;

                    localStorage.setItem('typeAbonnement', this.typeAbo);

                    this.typeAbonnement = localStorage.getItem('typeAbonnement');

                }).catch(error => {
                    console.log(error)
                })

        },

        getUserName() {
            axios.get(apiUrl + 'api/user_name', { params: { iduser: this.iduser } })
                .then(response => {
                    this.name = response.data[0].name;

                    localStorage.setItem('nomUser', this.name);

                    this.userName = localStorage.getItem('nomUser');

                }).catch(error => {
                    console.log(error)
                })
        },
        getUserEmail() {
            axios.get(apiUrl + 'api/user_email', { params: { iduser: this.iduser } })
                .then(response => {
                    this.email = response.data[0].email;

                    localStorage.setItem('emailUser', this.email);

                    this.userEmail = localStorage.getItem('emailUser');

                }).catch(error => {
                    console.log(error)
                })
        },
        getUserContact() {
            axios.get(apiUrl + 'api/user_contact', { params: { iduser: this.iduser } })
                .then(response => {
                    this.contact = response.data[0].contact;

                    localStorage.setItem('contactUser', this.contact);

                    this.userContact = localStorage.getItem('contactUser');

                }).catch(error => {
                    console.log(error)
                })
        },

        envoyerMessage() {
            const formData = new FormData();
            formData.append('nom', this.userName);
            formData.append('email', this.userEmail);
            formData.append('numero', this.userContact);
            formData.append('message', this.message);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/envoyer_message', formData)
                .then(response => {
                    this.userName = '', this.userEmail = '', this.userContact = '', this.message = '';
                    this.modalValidation = true;
                    console.log('Message envoyer avec succès');
                })
                .catch(error => {
                    console.log(error)
                });
        },
    }

}
</script>