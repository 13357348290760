<template>
    <div class="">
        <br><br><br><br><br>
        <div class="grid max-sm:grid-cols-1 sm:grid-cols-2  justify-center items-center">
            <div class="max-sm:hidden">
                <img src="./../../assets/undraw_sign_in_re_o58h.svg" alt="illustration connection">
            </div>
            <div class="">
                <div>
                    <h3 class="font-semibold">Connexion</h3>
                    <p class="max-sm:text-sm text-lg">Bienvenue sur notre plateforme de connexion. <br> Veuillez entrez
                        vos identifiants ci-dessous.</p><br>
                </div>
                <form @submit.prevent="connexion">
                    <div class="flex justify-center items-center md:mx-4">
                        <div class="text-left  max-sm:w-full max-sm:px-7 gap-y-3 flex flex-col">
                            <div class="">
                                <label for="" class="text-left text-gray-500">Email</label><br>
                                <input type="email" class="border border-gray-400  p-1 placeholder:text-sm  w-full"
                                    required v-model="email"><br>
                            </div>
                            <div class="">
                                <label for="" class="text-left text-gray-500">Mot de passe</label><br>
                                <input type="password" class="border border-gray-400    placeholder:text-sm  p-1 w-full"
                                    required v-model="password">
                            </div>
                            <div>
                                <input type="checkbox" class="border border-3 m-2" v-model="souvenir"><span class="">Se
                                    souvenir de moi</span><br><br>
                            </div>
                            <div>
                                <p class="text-red-500">{{ message }}</p>
                            </div>
                            <div class="flex justify-center items-center ">
                                <div class="max-sm:w-full">
                                    <button type="submit"
                                        class="hover:bg-green-500 w-full bg-green-600 h-8 text-white py-1 px-4">Se
                                        connecter</button><br><br>
                                </div>
                            </div>
                            <div class="flex justify-center items-center w-full">
                                <div class="w-">
                                    <span class="max-sm:text-sm">Vous n'avez pas de compte?</span>
                                    <RouterLink to="/Client/Inscription" class="no-underline"><span
                                            class="text-green-600 pl-2">S'inscrire</span></RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Footer></Footer>
    </div>
    <!--modal menu mobile-->
    <div class="modal_header transition-all shadow-sm shadow-gray-300   duration-500 w-screen h-fit   overflow-hidden"
        ref="modal_menu">
        <div class="">
            <div class="flex justify-between  px-4 bg-white h-fit overflow-hidden  items-center">
                <div class="flex justify-start items.-center gap-x-3">
                    <router-link to="/">
                        <img src="../../components/img/logovf.png" class="w-7 sm:w-10">
                    </router-link>
                    <span class="font-bold md:pt-3 max-md:pt-2 mt-1">
                        AGRIBUSINESS
                    </span>
                </div>
                <div class="flex justify-end items-center gap-x-3">
                    <div class="max-lg:hidden   overflow-hidden h-fit">
                        <div
                            class="flex  gap-x-4 overflow-hidden  max-sm:text-sm items-center justify-between h-fit">
                            <div v-for="(section, index) in sections" :key="index" class="px-3 h-fit">
                                <a class=" overflow-hidden  no-underline text-gray-800  h-fit  transition duration-500text-lg hover:text-blue-500"
                                    :href="`/#${section.id}`" :class="{ active: section.isActive }">{{ section.title
                                    }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="flex  items-center justify-end py-2">
                        <div class="lg:hidden ">
                            <button @click="show()" ref="btn_menu"
                                class="active:bg-gray-200 fas text-black fa-bars fa-2x transition-all   duration-500"></button>
                        </div>
                    </div>
                </div>
            </div>
            <Transition>
                <div v-show="modal_menu" class=" bg-slate-800 z-10 w-12/12 overflow-hidden">
                    <ul class=" divide-y  flex flex-col items-center justify-start gap-y-3  w-11/12">
                        <li v-for="item in sections" class="divide-y  text-left py-2 w-full">
                            <a :href="item.link" class=" no-underline  text-white ">{{ item.title }}</a>
                        </li>
                        <div class="w-full flex justify-between items-center gap-x-2 py-2">
                            <router-link to="/Client/Insciption" class="w-1/2"><button
                                    class="h-8 border w-full border-white text-white ">S'inscrire</button></router-link>
                            <router-link to="/Client/Connection" class="w-1/2"><button
                                    class="h-8 bg-green-600 w-full hover:bg-green-500 text-white ">Se
                                    connecter</button></router-link>
                        </div>
                    </ul>
                </div>
            </Transition>
        </div>
    </div>
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal1">
        <div class="bg-white p-4">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modal1 = false, init()"></div>
            </div>
            <div class="w-full flex justify-center items-center p-3">
                <div class="rounded-full p-2 fas fa-circle-notch fa-2x animate-spin text-gray-400"></div>
            </div>
            <div class="w-full flex justify-start items-center">
                <div>
                    <div class="">
                        <p class="text-gray-600">Votre abonnement est en cours de validation, veuillez attendre s'il
                            vous plaît !</p>
                    </div>
                </div>
            </div>
            <div class="w-full flex items-center justify-center">
                <div class="">
                    <button class="px-4 py-1 bg-green-600 hover:bg-green-500 text-white"
                        @click="modal1 = false, init()">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateX(900px);
}

.notif {
    position: fixed;
    top: 4vh;
    right: 30vw;
    z-index: 5;
}
</style>
<script>
//import { Transition } from 'vue';
import axios from 'axios';
import apiUrl from '../../apiConfig';
import HeaderVue from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'Connection',
    components: {
        HeaderVue,
        Footer
    },
    data() {
        return {
            modal1: false,
            email: '',
            password: '',
            message: '',
            modal_menu: false,
            sections: [{
                id: 'Service',
                title: 'Service',
                link: '/#Service'
            },
            {
                id: 'Propos',
                title: 'A propos',
                link: '/#Propos'
            },
            {
                id: 'Pricing',
                title: 'Abonnement',
                link: '/#Pricing'
            },
            {
                id: 'Contact',
                title: 'Contact',
                link: '/#Contact'
            },
                // Add more sections as needed
            ],
        }
    },
    mounted() {
    },
    methods: {
        connexion() {
            const formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);
            axios.post(apiUrl + 'api/login', formData)
                .then(({
                    data
                }) => {
                    console.log(data)
                    try {
                        if (data.reponse === 'En attente') {
                            // alert("Connexion avec succès");
                            this.$router.push("/Client/Abonnement/" + data.idUser);
                        } else if (data.reponse === 'A valider') {
                            // alert("Connexion avec succès");
                            this.modal1 = true;
                        } else if (data.reponse === 'Actif') {
                            // alert("Connexion avec succès");
                            this.$router.push("/Client/Accueil2");
                            // eslint-disable-next-line no-undef
                            //this.user = response.data.user; 
                            // eslint-disable-next-line no-undef
                            //this.$store.commit('setUser', data.user);
                            localStorage.setItem('iduser', data.idUser);
                        } else if (data.reponse === 'admin') {
                            //this.message = "Admin se connecte";
                            this.$router.push("/Admin/AccueilAdmin");
                        } else {
                            this.message = "Email ou mot de passe incorrect !";
                            this.email = "";
                            this.password = ""
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }
                )
        },
        init() {
            this.email = '';
            this.password = ''
        },
        show: function () {
            if (this.modal_menu == false) {
                this.modal_menu = true
            } else {
                this.modal_menu = false
            }
        }
    }
}
</script>
