<template>
  <div>
    <Bar :data="chartData" :options="chartOptions"></Bar>
  </div>
</template>
<script>
import { Bar } from 'vue-chartjs';
// eslint-disable-next-line no-unused-vars
import { Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import axios from'axios'
import apiUrl from '../apiConfig';


export default {
  name: 'BarChart',
  components: {
    Bar
  },
  data() {
    return {
      nbBasic: 0,
      nbStandard: 0,
      nbPremium: 45,

    };
  },
  /*mounted(){
    this.renderChart(this.chartData, this.chartOptions);
  },*/
  computed: {
    chartData() {
      return {
        labels: ['Basic', 'Standard', 'Premium'],
        datasets: [
          {
            label: 'Nombre d\'abonnés',
            backgroundColor: ['red', 'blue', 'green'],
            data: [this.nbBasic, this.nbStandard, this.nbPremium],
            barThickness: 170,
          },
        ],

        chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            x: {
              title: {
                display: true,
                text: 'Abonnement', // Remplacez par le nom que vous souhaitez
              },
            },
            y: {
              title: {
                display: true,
                text: 'Nombre', // Remplacez par le nom que vous souhaitez
              },
              ticks: {
                beginAtZero: true,
              },
              
            },
            //height: 800,
          },
          

      },


      }
    }
  },

  mounted() {
    this.getNombreBasic();
    this.getNombreStandard();
    this.getNombrePremium();

  },

  methods: {
    getNombreBasic() {
          axios.get(apiUrl + 'api/nombre_basic').then(response => {
              this.nbBasic = response.data.nbBasic;
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
            })
    },

    getNombreStandard() {
          axios.get(apiUrl + 'api/nombre_standard').then(response => {
              this.nbStandard = response.data.nbStandard;
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
            })
    },

    getNombrePremium() {
          axios.get(apiUrl + 'api/nombre_premium').then(response => {
              this.nbPremium = response.data.nbPremium;
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
            })
    },
  }
}

/*import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  data(){
    return{
      chartData:{
        labels:["2015-01","2015-02","2015-03","2015-04","2015-05","2015-06","2015-07","2015-08",
        "2015-09","2015-10","2015-11","2015-12"],
        datasets:[{
          label:'Bar Chart',
          borderWidth: 1,
          backgroundColor:[
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor:[
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 99, 132, 1)',
          ],
          pointBorderColor:'#2554FF',
          data:[12,19,3,5,2,3,20,3,5,6,2,1]
        }]
      },
      options:{
        scales:{
          yAxes:[{
            ticks:{
              beginAtZero:true
            }
          }],
          xAxes:[{
            gridLines:{
              display:false
            }
          }]
        },
        legend:{
          display: true
        },
        responsive:true,
        maintainAspectRatio: false
      }
    }
  },
  created() {
    this.renderChart(this.chartData,this.options)
  }
}*/

</script>