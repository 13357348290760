import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Client/Accueil.vue'
import Connection from '../views/Client/Connection.vue'
import Accueil2 from '../views/Client/Accueil2.vue'
import Inscription from '../views/Client/Inscription.vue'
import Produit from '../views/Client/Produit.vue'
import ProduitElevage from '../views/Client/ProduitElevage.vue'
import Calendrier from '../views/Client/Calendrier.vue'
import DetailsCalendrier from '../views/Client/DetailsCalendrier.vue'
import DetailsProd from '../views/Client/DetailsProd.vue'
import Courbe from '../views/Client/Courbe.vue'
import Fournisseur from '../views/Client/Fournisseur.vue'
import Acteur from '../views/Client/Acteur.vue'
import Client from '../views/Client/Client.vue'
import Prix from '../views/Client/Prix.vue'
import Abonnement from '../views/Client/Abonnement.vue'
import DetailsProdElevage from '../views/Client/DetailsProdElevage.vue'
//import Dashboard from '../views/Admin/Dashboard.vue'
import AccueilAdmin from '../views/Admin/Accueil.vue'
import ActeurAdmin from '../views/Admin/ActeurAdmin.vue'
import CultureAdmin from '../views/Admin/CultureAdmin.vue'
//import Dashboard2 from '../views/Admin/Dashboard2.vue'
import FournisseurAdmin from '../views/Admin/FournisseurAdmin.vue'
import ClientAdmin from '../views/Admin/ClientAdmin.vue'
import UtilisateurAdmin from '../views/Admin/UtilisateurAdmin.vue'
import ElevageAdmin from '../views/Admin/ElevageAdmin.vue'
import CalendrierAdmin from '../views/Admin/CalendrierAdmin.vue'
import CategorieAdmin from '../views/Admin/CategorieAdmin.vue'
import ComparaisonAdmin from '../views/Admin/ComparaisonAdmin.vue'
import AbonnementAdmin from '../views/Admin/AbonnementAdmin.vue'
import LoginAdmin from '../views/Admin/LoginAdmin.vue'
import MessageAdmin from '../views/Admin/MessageAdmin.vue'


import PrixAdmin from '../views/Admin/PrixAdmin.vue'



import Paiement from '../views/Client/Paiement.vue'
import Verification from '../views/Client/Verification.vue'

const routes = [
  
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },{
    path: '/Client/Connection',
    name: 'Connection',
    component: Connection
  },{
    path: '/Client/Paiement/:iduser',
    name: 'Paiement',
    component: Paiement
  },{
    path: '/Client/Verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/Client/Acteur',
    name: 'Acteur',
    component: Acteur
  },
  {
    path: '/Client/Prix',
    name: 'Prix',
    component: Prix
  },
  {
    path: '/Client/Abonnement/:iduser',
    name: 'Abonnement',
    component: Abonnement
  },
  {
    path: '/Client/Inscription',
    name: 'Inscription',
    component: Inscription
  },
  {
    path: '/Client/Accueil2',
    name: 'Accueil2',
    component: Accueil2
  },
  {
    path: '/Client/Fournisseur',
    name: 'Fournisseur',
    component: Fournisseur
  },
  {
    path: '/Client/Client',
    name: 'Client',
    component: Client
  },
  {
    path: '/Client/DetailsProd/:idculture',
    name: 'DetailsProd',
    component: DetailsProd
  },
  {
    path: '/Client/DetailsProdElevage/:idelevage',
    name: 'DetailsProdElevage',
    component: DetailsProdElevage
  },
  {
    path: '/Client/Produit',
    name: 'Produit',
    component: Produit
  },{
    path: '/Client/ProduitElevage',
    name: 'ProduitElevage',
    component: ProduitElevage
  },
  {
    path: '/Client/Calendrier',
    name: 'Calendrier',
    component: Calendrier
  }
  ,
  {
    path: '/Client/DetailsCalendrier/:idcal',
    name: 'DetailsCalendrier',
    component: DetailsCalendrier
  }
  ,
  {
    path: '/Client/Courbe',
    name: 'Courbe',
    component: Courbe
  },

  {
    path: '/Admin/ActeurAdmin',
    name: 'ActeurAdmin',
    component: ActeurAdmin
  },
  {
    path: '/Admin/AccueilAdmin',
    name: 'AccueilAdmin',
    component: AccueilAdmin
  },
  {
    path: '/Admin/CultureAdmin',
    name: 'CultureAdmin',
    component: CultureAdmin
  },

  {
    path: '/Admin/FournisseurAdmin',
    name: 'FournisseurAdmin',
    component: FournisseurAdmin
  },
  {
    path: '/Admin/ClientAdmin',
    name: 'ClientAdmin',
    component: ClientAdmin
  },
  {
    path: '/Admin/UtilisateurAdmin',
    name: 'UtilisateurAdmin',
    component: UtilisateurAdmin
  },
  {
    path: '/Admin/ElevageAdmin',
    name: 'ElevageAdmin',
    component: ElevageAdmin
  },
  {
    path: '/Admin/CalendrierAdmin',
    name: 'CalendrierAdmin',
    component: CalendrierAdmin
  },
  {
    path: '/Admin/CategorieAdmin',
    name: 'CategorieAdmin',
    component: CategorieAdmin
  },

  {
    path: '/Admin/ComparaisonAdmin',
    name: 'ComparaisonAdmin',
    component: ComparaisonAdmin
  },

  {
    path: '/Admin/AbonnementAdmin',
    name: 'AbonnementAdmin',
    component: AbonnementAdmin
  },

  {
    path: '/Admin/LoginAdmin',
    name: 'LoginAdmin',
    component: LoginAdmin
  },

  {
    path: '/Admin/PrixAdmin',
    name: 'PrixAdmin',
    component: PrixAdmin
  },

  {
    path: '/Admin/MessageAdmin',
    name: 'MessageAdmin',
    component: MessageAdmin
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
