<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div>
        <br><br><br><br>
        <div class=" flex w-screen justify-center items-center">
            <div class="flex max-md:flex-col  w-11/12 md:justify-between md:items-center max-md:justify-center max-md:items-start gap-4">
                <div class="flex justify-start items-center ">
                    <div class="flex justify-center items-center gap-x-3 w-fit">
                        <div class="fa fa-home  cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                        <div class="fas fa-caret-right  text-gray-500"></div>
                        <div class="w-fit cursor-pointer text-center"><span>Marché</span></div>
                        <div class="fas fa-caret-right  text-gray-500"></div>
                        <div class="w-fit cursor-pointer text-center" @click="$router.push('/Client/Acteur')"><span>Acteur</span></div>
                    </div>
                </div>
                <div class="flex max-md:flex-col gap-y-3 max-md:justify-center max-md:items-end md:justify-end md:items-center gap-x-4 ">
                    <div class="flex  border w-fit ">
                        <div class="flex items-center justify-center">
                            <!-- <div class="border-r"><label for="" class="px-1">Prix</label></div> -->
                            <div class="border-r">
                                <input type="text" class="py-1 w-full focus:outline-none  placeholder:text-sm" placeholder="Domaine ..." name="" id="" v-model="inpDomaine">
                            </div>
                        </div>
                        <div>
                            <div class="flex border-r ">
                                <input type="text " name="" id="" v-model="inpNom" placeholder="Nom d'acteur ..." class="w-full  py-1 focus:outline-none  placeholder:text-sm">
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-x-4">
                        <div>
                            <div class="py-1 px-4 text-white w-full h-full bg-green-600 hover:bg-green-500 placeholder:text-sm overflow-hidden focus:outline-none hover:cursor-pointer" @click="rechercheActeur">Rechercher</div>
                        </div>
                        <div class="flex ">
                            <div class="" v-if="content_mode">
                                <button @click="change_content_mode(false)" class="fas fa-table-list fa-2x text-gray-600"></button>
                            </div>
                            <div class="" v-else>
                                <button @click="change_content_mode(true)" class="fas fa-table-cells fa-2x text-gray-600"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <div class="w-screen flex flex-col items-center bg-slate-50 ">


            <div class="w-11/12 " v-if="content_mode" >
                <div class="grid max-sm:grid-cols-1  items-center  md:grid-cols-3 gap-4 ">
                    <div v-for="acteur in acteurs" :key="acteur.idacteur" class="flex  bg-white justify-between items-end shadow-sm shadow-slate-300" @click=" nom= acteur.nom,contact=acteur.contact,email=acteur.email,

                        adresse=acteur.adresse,domaine=acteur.domaine, description=acteur.description" >
                        

                        <div class=" w-fit">
                            <div class="flex justify-start items-center w-full text-left">
                                <div class="text-left m-2">
                                    <h5 class="font-semibold">{{ acteur.nom }} </h5>
                                </div>
                            </div>
                            <div class="m-2">
                                <div>
                                    <p class=" text-gray-600 text-left">
                                        Contact: <span class="text-gray-500">{{ acteur.contact }}</span> <br> Adresse: <span class="text-gray-500">{{acteur.adresse}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end  items-start mx-4 mb-3">
                            <div class="">
                                <button type="button" @click="modal_detail_show()" class="px-2 py-1 border-2  bg-slate-400  border-slate-400 hover:bg-slate-500  hover:border-gray-500 text-white ">Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" w-11/12 overflow-x-auto" v-else>
                <table class="w-full max-w-full ">
                    <thead>
                        <th style="display: none;">Id</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">Nom</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">Contact</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 " style="display: none;">Email</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">Adresse</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">Domaine</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 " style="display: none;">Description</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 "></th>
                    </thead>
                    <tbody class="text-gray-600 max-sm:text-sm">
                        <tr v-for="acteur in acteurs" :key="acteur.idacteur" class="hover:bg-gray-100 cursor-pointer text-sm text-left" @click="modal_detail_show(), nom= acteur.nom,contact=acteur.contact,email=acteur.email,
                            adresse=acteur.adresse,domaine=acteur.domaine,description=acteur.description ">
                            <td style="display: none;">{{ acteur.idacteur }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{acteur.nom}}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ acteur.contact }}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{acteur.email}}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{acteur.adresse}}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{acteur.domaine}}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{acteur.description}}</td>
                            <td class="px-3 py-2 border-b border-gray-200">
                                <div class="fas fa-eye"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        <Footer></Footer>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile" class="z-[4]"></Header2>
    <div class="bg-black bg-opacity-50 modal_ajout flex justify-center items-center w-screen h-screen" v-show="modal_detail">
        <div class="box-shadow bg-white w-fit h-fit p-8">
            <div class="flex justify-end items-center">
                <div><button class="fas fa-times fa-2x" @click="modal_detail=false,modal_back=false"></button></div>
            </div>
            <h5 class="font-bold text-xl text-left">{{ nom }}</h5>
            <hr>
            <br>
            <p class="">
                <div class="flex justify-start items-start text-left gap-12">
                    <div class="flex flex-col gap-y-2">
                        <div><span class="">Contact:</span> <span class="text-gray-500">{{contact}}</span> </div>
                        <div><span class="">Email: </span> <span class="text-gray-500">{{email}}</span> </div>
                        <div><span class="">adresse:</span> <span class="text-gray-500">{{adresse}}</span> </div>
                    </div>
                    <div class="flex flex-col gap-y-2">
                        <div><span class="">Domaine:</span> <span class="text-gray-500">{{domaine}}</span> </div>
                        <div><span class="">Description :</span> <span class="text-gray-500">{{description}}</span> </div>
                    </div>
                </div>
            </p>
        </div>
    </div>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal_ajout" v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnexion</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn rounded bg-gray-400 text-white p-1" @click="modal_deconnection=false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn rounded bg-blue-500 text-white p-1"><Router-Link to="/">Se deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile
            <div  class=" modal_ajout flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
                <div class=" rounded fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                        <div class="flex justify-end items-center">
                            <div class=""><button class="fa fa-times fa-2x m-2" @click="menu_mobile2=false"></button></div>
                        </div>
                        <div class="p-6 flex flex-col gap-5 text-left">
                            <div>
                                <RouterLink to="/Client/Accueil2" class="font-bold">
                                    Accueil
                                </RouterLink>
                            </div>
                            <div>
                                <details>
                                    <summary class="font-bold">Produit</summary>
                                    <div class="flex flex-col p-1 gap-1 ml-5">
                                        <div><RouterLink to="/Client/Produit"><h6>Culture</h6></RouterLink></div>
                                        <div><RouterLink to="/Client/ProduitAgricole"><h6>Agricole</h6></RouterLink></div>
                                    </div>
                                </details>
                            </div>
                            <div>
                                <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                            </div>
                            <div>
                                <details>
                                    <summary class="font-bold">Marché</summary>
                                    <div class="flex flex-col p-1 gap-1  ml-5">
                                        <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                        <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                        <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                                    </div>
                                </details>
                            </div>
                            <div>
                                <details>
                                    <summary class="font-bold">Outils à la decision</summary>
                                    <div class="flex flex-col p-1 gap-1  ml-5">
                                        <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                        <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                                    </div>
                                </details>
                            </div>
                            
                        </div>
                </div>-->
            </div>
</template>
<script>
    import apiUrl from '../../apiConfig';
    // import { RouterLink } from 'vue-router'
    import '@/components/style/marche.css'
    import '@/components/style/all.css'
    import '@/components/style/header.css'
    import Header2 from '@/components/Header2.vue';
    import Footer from '@/components/Footer.vue';
    //import Triage from '@/components/Triage.vue';
    import Pagination from '@/components/Pagination.vue';
    import '@/assets/tailwind.css'
    import axios from 'axios';
    export default {
        name: "Acteur",
        components: {
            Header2,
            Footer,
            // eslint-disable-next-line vue/no-unused-components
            Pagination
        },
        data() {
            return {
                inpNom: '',
                inpDomaine: '',
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                domaine: '',
                description: '',
                content_mode: Boolean,
                modal_detail: false,
                modal_back: false,
                sections: [{
                        id: 'Service',
                        title: 'Service',
                        isActive: false
                    },
                    {
                        id: 'Propos',
                        title: 'Propos',
                        isActive: false
                    },
                    {
                        id: 'Pricing',
                        title: 'Pricing',
                        isActive: false
                    },
                    {
                        id: 'Contact',
                        title: 'Contact',
                        isActive: false
                    },
                    // Add more sections as needed
                ],
                menu_mobile2: false,
                modal_deconnection: false,
                items: {},
                acteurs: []
            }
        },
        mounted() {
            this.d();
            console.log(localStorage.getItem('content_mode'));
            this.getActeurs();
        },
        // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
        beforeDestroy() {
            localStorage.setItem('content_mode', String(this.content_mode))
        },
        methods: {
            rechercheActeur() {
                axios.get(apiUrl + 'api/recherche_acteur', {
                    params: {
                        inpDomaine: this.inpDomaine,
                        inpNom: this.inpNom
                    }
                }).then(response => {
                    this.acteurs = response.data
                }).catch(error => {
                    console.log(error)
                })
            },
            d() {
                this.content_mode = JSON.parse(localStorage.getItem('content_mode'))
            },
            change_content_mode: function(data) {
                this.content_mode = data
                localStorage.setItem('content_mode', String(this.content_mode))
            },
            // eslint-disable-next-line no-unused-vars
            modal_detail_show: function(item) {
                if (this.modal_detail) {
                    this.modal_detail = false
                    this.modal_back = false
                } else {
                    this.modal_detail = true
                    this.modal_back = true
                }
            },
            data_menu_mobile(data) {
                this.menu_mobile2 = data
            },
            data_modal_deconnnection(data) {
                this.modal_deconnection = data
            },
            getActeurs() {
                axios.get(apiUrl + 'api/affichage_acteur').then(response => {
                    this.acteurs = response.data
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                    this.acteurs = []
                })
            },
        }
    }
</script>