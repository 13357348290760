<template>
    <div class="w-screen flex flex-col justify-center items-center">
        <!-- <input v-model="$route.params.idelevage"> -->
        <br><br><br><br>
        <div class="w-11/12 ">
            <div class="flex flex-col items-start justify-center ">
                <div class="">
                    <h6 class="">
                        <span class="font-semibold"> Produit :</span><span class="px-4"> 
                            {{ 
                                nomElevage
                            }}
                            </span>
                        </h6>
                </div>
                <div>
                    <h6 class="">
                        <span class="font-semibold">Origine :</span>
                        <span class="px-4">{{ origine }}</span>
                    </h6>
                </div>
            </div>
            <br>
            <div class="w-screen flex justify-beteen items-center sm:hidden  py-3 gap-x-4">
                <div class="w-1/2">
                    <div class="bg-green-700 py-1 px-2  text-white flex justisy-center items-center gap-x-4"
                        @click="generer_pdf()">
                        <div class="fas fa-download"></div>
                        <div>
                            Generer en pdf
                        </div>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="px-4 flex justify-start items-center gap-x-4 py-1 border"
                        @click="modal_menu = !modal_menu">
                        <div class="fas fa-angle-up" v-if="modal_menu"></div>
                        <div class="fas fa-angle-down" v-else></div>
                        <div>{{ modal_menu_value }}</div>
                    </div>
                </div>
            </div>
            <div class=" border">
                <div class="max-sm:hidden grid grid-cols-5 ">
                    <div class="bg-green-700   text-white cursor-pointer flex items-center justify-center gap-2 border-r border-white"
                        @click="generer_pdf()">
                        <div class="flex justify-center items-center">
                            <div class="fas fa-download"></div>
                        </div>
                        <div class="flex justify-center items-center ">
                            <div>
                                <h6 class="my-auto"> Telecharger</h6>
                            </div>
                        </div>
                    </div>
                    <div class=" page-1   py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Karazany ? 'white' : 'RGB(36 47 65)', color: Karazany ? 'black' : 'white', borderTop: Karazany ? '2px solid #0067b8' : 'none' }"
                        @click="p_Karazany()">
                        <h6 class="my-auto py-1">Karazany</h6>
                    </div>
                    <div class=" page-2  py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Fikarakarana ? 'white' : 'RGB(36 47 65)', color: Fikarakarana ? 'black' : 'white', borderTop: Fikarakarana ? '2px solid #0067b8' : 'none' }"
                        @click="p_Fikarakarana()">
                        <h6 class="my-auto py-1">Fikarakarana</h6>
                    </div>
                    <div class=" page-3   py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Sakafo ? 'white' : 'RGB(36 47 65)', color: Sakafo ? 'black' : 'white', borderTop: Sakafo ? '2px solid #0067b8' : 'none' }"
                        @click="p_Sakafo()">
                        <h6 class="my-auto py-1">Sakafo</h6>
                    </div>
                    <div class=" page-4   py-1 cursor-pointer flex justify-center items-center border-r border-white"
                        :style="{ background: Fonenany ? 'white' : 'RGB(36 47 65)', color: Fonenany ? 'black' : 'white', borderTop: Fonenany ? '2px solid #0067b8' : 'none' }"
                        @click="p_Fonenany()">
                        <h6 class="my-auto py-1">Fonenany</h6>
                    </div>
                </div>
                <div class=" m-2 ">
                    <div class=""></div>
                    <div class=" m-4 ">
                        <div v-show="Karazany" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Nom :</h6>
                                </div>
                                <div>
                                    <p class="text-gray-700">{{ nom2 }}</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">
                                        Description :
                                    </h6>
                                </div>
                                <div>
                                    <p>{{ description1 }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-show="Fikarakarana" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Tanjona :</h6>
                                </div>
                                <div>
                                    <p>{{ tanjona1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Dingana :</h6>
                                </div>
                                <div>
                                    <p>{{ dingana1 }}</p>
                                </div>


                            </div>

                        </div>
                        <div v-show="Sakafo" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fotoana :</h6>
                                </div>
                                <div>
                                    <p>{{ fotoana1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fomba atao :</h6>
                                </div>
                                <div>
                                    <p>{{ fombaAtao1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fatrany :</h6>
                                </div>
                                <div>
                                    <p>{{ fatrany1 }}</p>
                                </div>


                            </div>
                        </div>
                        <div v-show="Fonenany" class="flex flex-col justify-start items-start text-left w-full">
                            <div>
                                <div>
                                    <h6 class="font-semibold">Mombamomba :</h6>
                                </div>
                                <div>
                                    <p>{{ mombamomba1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Refiny :</h6>
                                </div>
                                <div>
                                    <p>{{ refiny1 }}</p>
                                </div>


                            </div>
                            <div>
                                <div>
                                    <h6 class="font-semibold">Fitaovana :</h6>
                                </div>
                                <div>
                                    <p>{{ fitaovana1 }}</p>
                                </div>


                            </div>




                        </div>
                    </div>
                    <div class=""></div>
                </div>
            </div>
        </div>
        <br><br><br>
        <Footer></Footer>
    </div>
    <div>
        <div class="py-2 px-5" ref="pdf" v-show="download">
            <div class="flex flex-col items-start justify-center">
                <h1 class="">
                    <span class="font-semibold"> Produit :</span>
                    <span class="px-4">{{ nomElevage }}</span>
                </h1>
                <h1>
                    <span class="font-semibold">Origine :</span>
                    <span class="px-4">{{ origine }}</span>
                </h1>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full bt-2">
                <div>
                    <div>
                        <h6 class="font-semibold">Nom :</h6>
                    </div>
                    <div>
                        <p class="text-gray-700">{{ nom2 }}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">
                            Description :
                        </h6>
                    </div>
                    <div>
                        <p>{{ description1 }}</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Tanjona :</h6>
                    </div>
                    <div>
                        <p>{{ tanjona1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Dingana :</h6>
                    </div>
                    <div>
                        <p>{{ dingana1 }}</p>
                    </div>


                </div>

            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Fotoana :</h6>
                    </div>
                    <div>
                        <p>{{ fotoana1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fomba atao :</h6>
                    </div>
                    <div>
                        <p>{{ fombaAtao1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fatrany :</h6>
                    </div>
                    <div>
                        <p>{{ fatrany1 }}</p>
                    </div>


                </div>
            </div>
            <div class="flex flex-col justify-start items-start text-left w-full">
                <div>
                    <div>
                        <h6 class="font-semibold">Mombamomba :</h6>
                    </div>
                    <div>
                        <p>{{ mombamomba1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Refiny :</h6>
                    </div>
                    <div>
                        <p>{{ refiny1 }}</p>
                    </div>


                </div>
                <div>
                    <div>
                        <h6 class="font-semibold">Fitaovana :</h6>
                    </div>
                    <div>
                        <p>{{ fitaovana1 }}</p>
                    </div>


                </div>




            </div>
        </div>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile"></Header2>
    <!-- modal notif download -->
    <div class="w-screen h-screen modal_ajout top-0 right-0 z-50 flex justify-center items-center bg-slate-700  bg-opacity-50"
        v-show="download">
        <div class="w-1/2 flex flex-col justify-center items-center bg-white p-4 shadow-sm shadow-gray-300">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times fa-2x" @click="download = false"></div>
            </div>
            <div class="p-4 text-center">
                <p>
                    Le téléchargement est réussi!
                </p>
            </div>
        </div>
    </div>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection"
        v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn  bg-gray-400 text-white p-1"
                            @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn  bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center"
        v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
            <div class="flex justify-end items-center">
                <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2 = false"></button></div>
            </div>
            <div class="p-6 flex flex-col gap-5 text-left">
                <div>
                    <RouterLink to="/Client/Accueil2" class="font-bold">
                        Accueil
                    </RouterLink>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Produit</summary>
                        <div class="flex flex-col p-1 gap-1 ml-5">
                            <div>
                                <RouterLink to="/Client/Produit">
                                    <h6>produit culture</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitElevage">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Marché</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Prix">
                                    <h6>Prix</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Client">
                                    <h6>Client</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/Fournisseur">
                                    <h6>Fournisseur</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>
                <div>
                    <details>
                        <summary class="font-bold">Outils à la decision</summary>
                        <div class="flex flex-col p-1 gap-1  ml-5">
                            <div>
                                <RouterLink to="/Client/Courbe">
                                    <h6>Courbe</h6>
                                </RouterLink>
                            </div>
                            <div>
                                <RouterLink to="/Client/ProduitAgricole">
                                    <h6>produit agricole</h6>
                                </RouterLink>
                            </div>
                        </div>
                    </details>
                </div>

            </div>
        </div>
    </div>
    <!--modal menu-->
    <Transition>
        <div class="bg-slate-700 text-white px-4  absolute z-[3] top-44 py-2 shadow-sm shadow-gray-300  right-2 "
            v-show="modal_menu">
            <div class="flex justify-end items-center w-full">
                <div class="fa fa-times py-3" @click="modal_menu = false"></div>
            </div>
            <div class="flex flex-col gap-y-2 px-2 text-left">
                <div class=" page-1  cursor-pointer px-2" :style="{ border: Karazany ? ' solid #009100' : 'white' }"
                    @click="p_Karazany()">Karazany</div>
                <div class=" page-2  cursor-pointer px-2" :style="{ border: Fikarakarana ? ' solid #009100' : 'white' }"
                    @click="p_Fikarakarana()">Fikarakarana</div>
                <div class=" page-3  cursor-pointer px-2" :style="{ border: Sakafo ? ' solid #009100' : 'white' }"
                    @click="p_Sakafo()">Sakafo</div>
                <div class=" page-4  cursor-pointer px-2" :style="{ border: Fonenany ? ' solid #009100' : 'white' }"
                    @click="p_Fonenany()">Fonenany</div>
            </div>
        </div>
    </Transition>

</template>
<style scoped>
.v-enter-active,
.v-leave-active {
    transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateX(500px);
}
</style>
<script>
import apiUrl from '../../apiConfig';
import axios from 'axios'

//import { Transition } from 'vue';
import jsPDF from 'jspdf';

import { Transition } from 'vue';
// import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';


import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
import 'jspdf-autotable';

export default {
    name: "DetailsProd",
    components: {
        Footer,
        Header2,
    },

    data() {
        return {
            download: false,
            modal_menu_value: 'Karazany',
            nom2: '', description1: '',
            tanjona1: '', dingana1: '',
            fotoana1: '', fombaAtao1: '', fatrany1: '',
            mombamomba1: '', refiny1: '', fitaovana1: '',

            modal_menu: false,

            Karazany: true,
            Fikarakarana: false,
            Sakafo: false,
            Fonenany: false, sections: [
                { id: 'Service', title: 'Service', isActive: false },
                { id: 'Propos', title: 'Propos', isActive: false },
                { id: 'Pricing', title: 'Pricing', isActive: false },
                { id: 'Contact', title: 'Contact', isActive: false },
                // Add more sections as needed
            ], menu_mobile2: false,
            modal_deconnection: false
        }
    },

    mounted() {
        this.detail_produit();
        this.detail_karazany();
        this.detail_fikarakarana();
        this.detail_sakafo();
        this.detail_fonenany();
    },

    methods: {

        detail_produit() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_elevage_detail', { params: { idelevage3: this.$route.params.idelevage } }).then(response => {
                this.nomElevage = response.data[0].nom;
                this.origine = response.data[0].origine;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },

        detail_karazany() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_karazany', { params: { idelevage3: this.$route.params.idelevage } }).then(response => {
                this.nom2 = response.data[0].nom;
                this.description1 = response.data[0].description;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },

        detail_fikarakarana() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fikarakarana', { params: { idelevage3: this.$route.params.idelevage } }).then(response => {
                this.tanjona1 = response.data[0].tanjona;
                this.dingana1 = response.data[0].dingana;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },

        detail_sakafo() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_sakafo', { params: { idelevage3: this.$route.params.idelevage } }).then(response => {
                this.fotoana1 = response.data[0].fotoana;
                this.fombaAtao1 = response.data[0].fombaAtao;
                this.fatrany1 = response.data[0].fatrany;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },

        detail_fonenany() {
            // eslint-disable-next-line no-unused-vars
            axios.get(apiUrl + 'api/affichage_fonenany', { params: { idelevage3: this.$route.params.idelevage } }).then(response => {
                this.mombamomba1 = response.data[0].mombamomba;
                this.refiny1 = response.data[0].refiny;
                this.fitaovana1 = response.data[0].fitaovana;
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                })
        },

        p_Karazany: function () {

            this.Karazany = true
            this.Fikarakarana = false
            this.Sakafo = false
            this.Fonenany = false
            this.modal_menu_value = 'Karazany'
        },
        p_Fikarakarana: function () {

            this.Karazany = false
            this.Fikarakarana = true
            this.Sakafo = false
            this.Fonenany = false
            this.modal_menu_value = 'Fikarakarana'
        },
        p_Sakafo: function () {

            this.Karazany = false
            this.Fikarakarana = false
            this.Sakafo = true
            this.Fonenany = false
            this.modal_menu_value = 'Sakafo'
        },
        p_Fonenany: function () {

            this.Karazany = false
            this.Fikarakarana = false
            this.Sakafo = false
            this.Fonenany = true
            this.modal_menu_value = 'Fonenany'
        },
        generer_pdf: function () {

            const pdf = new jsPDF('p', 'mm', [210, 297]);
            // eslint-disable-next-line no-unused-vars
            const img = require('@/assets/logoAgri.jpg')
            let yPos = 20; // Position verticale de départ
            const margin = 10; // Marge supérieure
            yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
            const pageHeight = pdf.internal.pageSize.height;
            if (yPos + 10 > pageHeight) {
                pdf.addPage();
                yPos = margin;
            }
            // pdf.addImage(img,'PNG',10,0,15,25)
            // eslint-disable-next-line no-unused-vars
            const date = new Date()
            // eslint-disable-next-line no-unused-vars
            const y = 10
            // pdf.text(date.toLocaleDateString(), pdf.internal.pageSize.width/2,  pdf.internal.pageSize.height-10,);
            pdf.setFont('Arial', 12, false)
            pdf.setTextColor('blue')
            pdf.text(this.nomElevage + ' - ' + this.origine, 100, 10, 'center');
            pdf.setTextColor('black')
            pdf.text("1. Karazany", 15, 20,)
            pdf.text("1.1. Anarana:", 40, 30)
            pdf.text(this.nom2, 55, 38)
            pdf.text("1.2. Mombamomba", 40, 48)
            pdf.text(this.description1, 55, 58)
            pdf.text("2. Fikarakarana", 15, 68)
            pdf.text("2.1. Tanjona:", 40, 78)
            pdf.text(this.tanjona1, 55, 88)
            pdf.text("2.2. Dingana", 40, 98)
            pdf.text(this.dingana1, 55, 108)
            pdf.text("3. Sakafo", 15, 118)
            pdf.text("3.1. Fotoana:", 40, 128)
            pdf.text(this.fotoana1, 55, 138)
            pdf.text("3.2. Fomba atao", 40, 148)
            pdf.text(this.fombaAtao1, 55, 158)
            pdf.text("3.3. Fatrany", 40, 168)
            pdf.text(this.fatrany1, 55, 178)
            pdf.text("4. Fonenany", 15, 188)
            pdf.text("4.1. Mombamomba:", 40, 198)
            pdf.text(this.mombamomba1, 55, 208)
            pdf.text("4.2. Refiny", 40, 218)
            pdf.text(this.refiny1, 55, 228)
            pdf.text("4.3. Fitaovana", 40, 238)
            pdf.text(this.fitaovana1, 55, 248)
            // eslint-disable-next-line no-unused-vars
            const startY = 40
            pdf.output('dataurlnewwindow')


            if (this.download = true) {
                const options = {
                    filename: 'mi_archivo.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: {},
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                };

                // Elemento a convertir
                const content = this.$refs.pdf;


                // Iniciar la conversión a PDF
                html2pdf().from(content).set(options).save();
            }


        },
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        }
    }
}
</script>
