<template>
 <div class="w-screen flex flex-col items-center">
    <br><br><br>
    <div class="w-11/12 flex  max-sm:flex-col  max-sm:justify-center max-sm:items-start md:justify-between md:items-center max-sm:gap-y-2">
        <div class="flex justify-start items-center ">
            <div class="flex justify-center items-center gap-x-2   w-fit">
                <div class="fa fa-home px-1 cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                <div class="fas fa-caret-right px-1 text-gray-500"></div>
                <div class="w-fit cursor-pointer" @click="$router.push('/Client/Calendrier')"><span>Calendrier</span></div>
            </div>
        </div>
        <div class="flex max-sm:w-full  md:w-fit justify-end items-center gap-x-2 ">
            <div class="flex border max-sm:w-full md:w-fit ">
                <div>
                    <div class="flex border-r ">
                        <input type="text " name="" id="" v-model="inpProduit" placeholder="Produit ..." class="w-full  p-1 focus:outline-none  placeholder:text-sm">
                    </div>
                </div>
            </div>
            <div>
                <button class="py-1 px-4 text-white w-full h-full bg-green-600 hover:bg-green-500 hover:cursor-pointer placeholder:text-sm overflow-hidden focus:outline-none" @click="rechercheCalendrier">Rechercher</button>
            </div>
        </div>
    </div>
    <br>
        <div class="flex w-screen  justify-center bg-slate-50 max-sm:py-2">
            <div class="grid max-sm:grid-cols-1 w-11/12  md:grid-cols-3 lg:grid-cols-4 justify-center items-start gap-3 py-3">
                <div v-for="calendrier in calendriers" :key="calendrier.idcal" class="bg-white shadow-sm flex justify-between items-end max-sm:w-full shadow-slate-300 p-3">
                    <div class="flex flex-col justify-center items-start">
                        <div class="text-left"><h5 class="font-semibold">{{ calendrier.nomPro }}</h5></div>
                        <div class="text-left text-gray-600">
                            <h6>{{ calendrier.zone }}</h6>
                        </div>
                        <div class="text-left text-gray-500 py-1">
                            <p>{{ calendrier.typeCult }}</p>
                        </div>
                    </div>
                    <div class="flex  justify-end items-end">
                        <div class="px-4 py-1  bg-slate-400  border-slate-400 hover:bg-slate-500  hover:border-gray-500 text-white  cursor-pointer text-sm" @click="$router.push('/Client/DetailsCalendrier/' + calendrier.idcal)">Details</div>
                    </div>
                </div>
            </div>
        </div><br><br><br><br><br><br>
    <Footer></Footer>
 </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection"  @menu_mobile_show="data_menu_mobile"></Header2>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center" >
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div>
                <div>
                    <h5>Deconnexion</h5>
                </div>
                <div>
                    <form class="form form-check">
                        <button @click.prevent="" class="btn rounded bg-blue-600 text-white p-1"><Router-Link to="/Client/Accueil2">Se connecter</Router-Link></button><br>
                        <span>Vous n'avez pas de compte?</span><RouterLink to="/Client/Inscription"><span class="text-blue-400">s'inscrire</span></RouterLink>
                    </form>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    
</template>
<script>
import axios from 'axios';
import apiUrl from '../../apiConfig';
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
    export default {
        name:'Calendrier',
        components:{
            Footer,
            Header2,
        },
        data(){
            return{
                calendriers:[], inpProduit:'',

                sections: [
          { id: 'Service', title: 'Service', isActive: false },
          { id: 'Propos', title: 'Propos', isActive: false },
          { id: 'Pricing', title: 'Pricing', isActive: false },
          { id: 'Contact', title: 'Contact', isActive: false },
          // Add more sections as needed
        ],menu_mobile2:false,
        modal_deconnection:false
        }},
        mounted(){
            this.getCalendriers();
            if(this.calendriers!=null){
                console.log(JSON.stringify(this.calendriers))
            } else {
                console.log('HELLLO')
            }
        },
        methods:{
            getCalendriers(){
                axios.get(apiUrl + 'api/affichage_calendrier').then(response => {
                this.calendriers = response.data
                // eslint-disable-next-line no-undef
                
                }).catch(error =>{
                console.log(error)
                this.calendriers=[]
                })
            },
            rechercheCalendrier(){
                axios.get(apiUrl + 'api/recherche_calendrier_front', {params: {inpProduit: this.inpProduit}}).then(response => {
                    this.calendriers = response.data
                    }).catch(error =>{
                    console.log(error)
                })
            },
            data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data
            }
        }
    }
</script>