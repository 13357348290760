<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="w-screen flex justify-center items-center flex-col">
        <br><br>
        <br>
        <div
            class="flex gap-y-3 w-11/12 md:gap-x-4 max-md:flex-col max-md:items-start max-md:justify-center md:justify-between md:items-start">
            <div class="flex justify-start items-center md:pt-2">
                <div class="flex justify-center items-center  gap-x-3 w-fit">
                    <div class="fa fa-home  cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                    <div class="fas fa-caret-right   text-gray-500"></div>
                    <div class="w-fit "><span>Marché</span></div>
                    <div class="fas fa-caret-right   text-gray-500"></div>
                    <div class="w-fit "><span @click="$router.push('/Client/Fournisseur')"
                            class="cursor-pointer">Fournisseur</span></div>
                </div>
            </div>

            <div class="flex w-fit max-lg:flex-col max-lg:justify-start max-lg:items-snd lg:justify-end lg:items-center gap-3">
                <div class="flex border w-fit">
                    <div class="flex items-center justify-center  lg:w-56">
                        <!-- <div class="border-r"><label for="" class="px-1">Prix</label></div> -->
                        <div class="border-r"><input type="number"
                                class="p-1 w-full focus:outline-none placeholder:text-sm" placeholder="Prix entre ..."
                                v-model="inpPrix1"></div>
                        <div class="border-r"><input type="number"
                                class="p-1 w-full focus:outline-none  placeholder:text-sm" placeholder="Et ..." name=""
                                id="" v-model="inpPrix2"></div>
                    </div>

                    <div class="lg:w-36">
                        <select name="" class="border-r w-full p-1 focus:outline-none bg-white placeholder:text-sm"
                            placeholder="Categorie ..." id="" v-model="inpCat">
                            <option v-for="item in categories" :key="item.nomcat" class="" :value="item.nomcat">{{
                        item.nomcat }}</option>
                        </select>
                    </div>


                </div>
                <div class="flex justify-end items-center max-md:gap-x-3 md:gap-x-4">
                    <div class="flex border md:w-24">
                        <input type="text " name="" id="" v-model="inpProduit" placeholder="Recherche ..."
                            class="w-full  p-1 focus:outline-none  placeholder:text-sm">

                    </div>
                    <div>

                        <div class="py-1 px-4 text-white w-full h-full bg-green-600 hover:bg-green-500   placeholder:text-sm overflow-hidden focus:outline-none cursor-pointer"
                            @click="rechercheFournisseur">Rechercher</div>

                    </div>
                    <div class="flex items-center justify-center">
                        <div class="" v-if="content_mode">
                            <button @click="change_content_mode(false)"
                                class="fas fa-table-list fa-2x text-gray-600"></button>
                        </div>
                        <div class="" v-else>
                            <button @click="change_content_mode(true)"
                                class="fas fa-table-cells fa-2x text-gray-600"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="content">
            <div class="w-screen bg-slate-50  max-md:p-3 md:p-5 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 max-md:gap-4 md:gap-4"
                v-if="content_mode">

                <!-- <div v-if="fournisseurs.length > 0"> -->

                <div v-for="fournisseur in fournisseurs" :key="fournisseur.idfourni"
                    class="flex bg-white justify-center items-cennter shadow-sm shadow-slate-300"
                    @click="nom = fournisseur.nom, contact = fournisseur.contact, email = fournisseur.email,
                        adresse = fournisseur.adresse, statut = fournisseur.statut, prodDispo = fournisseur.prodDispo, prixUnitaire = fournisseur.prixUnitaire, quantite = fournisseur.quantite, nomcat = fournisseur.nomcat">

                    <div class=" w-full flex justify-between ">
                        <div>
                            <div class="flex justify-start items-center w-full text-left">
                                <div class="text-left m-2">
                                    <h5 class="font-semibold">{{ fournisseur.nom }} </h5>
                                </div>
                            </div>
                            <div class="m-2">
                                <div>
                                    <p class=" text-gray-600 text-left">
                                        Contact: <span class="text-gray-500">{{ fournisseur.contact }}</span> <br>
                                        Email: <span class="text-gray-500">{{ fournisseur.email }}</span> <br>
                                        Adresse: <span class="text-gray-500">{{ fournisseur.adresse }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end  items-end mx-4 mb-3">
                            <div class="">
                                <button type="button" @click="modal_detail_show()"
                                    class="px-2 py-1  bg-slate-400  border-slate-400 hover:bg-slate-500  hover:border-gray-500 text-white ">Details</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- </div> -->

                <!-- <div v-else>
                Tsisy lty an, tsisyy !
            </div> -->


            </div>
            <div class="px-3 w-full overflow-x-auto" v-else>
                <br><br>
                <table class="min-w-full">
                    <thead class="">
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300  text-left font-semibold text-gray-600 ">
                            Nom</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                            Contact</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 "
                            style="display: none;">Email</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 "
                            style="display: none;">Adresse</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                            Statut</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                            Produit disponible</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                            PrixUnitaire</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                            Quantité</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 "
                            style="display: none;">Nomcat</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left  font-semibold text-gray-600 ">
                        </th>
                    </thead>
                    <tbody v-if="fournisseurs.length > 0">
                        <tr class="text-sm text-left" v-for="fournisseur in fournisseurs" :key="fournisseur.idfourni"
                            @click="modal_detail_show(), nom = fournisseur.nom, contact = fournisseur.contact, email = fournisseur.email,
                        adresse = fournisseur.adresse, statut = fournisseur.statut, prodDispo = fournisseur.prodDispo, prixUnitaire = fournisseur.prixUnitaire, quantite = fournisseur.quantite, nomcat = fournisseur.nomcat">
                            <td style="display: none;">{{ fournisseur.idfourni }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.nom }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.contact }}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{ fournisseur.email }}
                            </td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">
                                {{ fournisseur.adresse }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.statut }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.prodDispo }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.prixUnitaire }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ fournisseur.quantite }}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{ fournisseur.nomcat }}
                            </td>
                            <td class="px-3 py-2 border-b border-gray-200">
                                <div class="fas fa-eye"></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" rowspan="">Pas de résultat qui conforme au filtre !</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> <br><br><br><br><br><br><br><br>
        <Footer></Footer>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile"></Header2>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection"
        v-show="modal_deconnection">
        <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn  bg-gray-400 text-white p-1"
                            @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn  bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile
    <div  class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class="  fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>-->
    <!--modal_details-->
    <div class="bg-black bg-opacity-50 modal_ajout flex justify-center items-center w-screen h-screen"
        v-show="modal_detail">
        <div class="box-shadow bg-white w-fit h-fit p-8">
            <div class="flex justify-end items-center">
                <div><button class="fas fa-times fa-2x" @click="modal_detail = false"></button></div>
            </div>
            <h5 class="font-bold text-xl text-left">{{ nom }}</h5>
            <hr>
            <br>
            <p class="">
            <div class="flex justify-start items-start text-left gap-12">
                <div class="flex flex-col gap-y-2">
                    <div><span class="">Contact:</span> <span class="text-gray-500">{{ contact }}</span> </div>
                    <div><span class="">Email: </span> <span class="text-gray-500">{{ email }}</span> </div>
                    <div><span class="">adresse:</span> <span class="text-gray-500">{{ adresse }}</span> </div>
                    <div><span class="">Statut :</span> <span class="text-gray-500"> {{ statut }}</span> </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div><span class="">Produit Disponible:</span> <span class="text-gray-500">{{ prodDispo }}</span>
                    </div>
                    <div><span class="">Prix Unitaire :</span> <span class="text-gray-500">{{ prixUnitaire }}</span>
                        <span></span> </div>
                    <div><span class="">Quantité :</span> <span class="text-gray-500">{{quantite}}</span> </div>
                    <div><span class="">Nom du catégorie :</span> <span class="text-gray-500"> {{ nomcat }}</span>
                    </div>
                </div>
            </div>
            </p>

        </div>
    </div>
</template>
<script>
import apiUrl from '../../apiConfig';
import axios from 'axios';
//import { RouterLink } from 'vue-router'
import '@/components/style/marche.css'
import '@/components/style/all.css'
import '@/components/style/header.css'
import Header2 from '@/components/Header2.vue';
import Footer from '@/components/Footer.vue';
//import Triage from '@/components/Triage.vue';
import Pagination from '@/components/Pagination.vue';

export default {
    name: "Fournisseur",
    components: {
        Header2,
        Footer,
        // eslint-disable-next-line vue/no-unused-components
        Pagination
    },
    data() {
        return {
            inpCat: '', inpPrix1: '', inpPrix2: '', inpProduit: '',

            nom: '', contact: '', email: '', adresse: '', type: '', prodDispo: '', prixUnitaire: '', quantite: '', nomcat: '', idfourni: '',
            fournisseur: {
                nom: "", contact: "", email: "", adresse: "", type: "", prodDispo: "", prixUnitaire: "", quantite: "", nomcat: ""
            },
            categories: [],
            fournisseurs: [],
            content_mode_table: true,
            content_mode_modal: false,
            modal_detail: false,
            modal_back: false, sections: [
                { id: 'Service', title: 'Service', isActive: false },
                { id: 'Propos', title: 'Propos', isActive: false },
                { id: 'Pricing', title: 'Pricing', isActive: false },
                { id: 'Contact', title: 'Contact', isActive: false },
                // Add more sections as needed
            ], menu_mobile2: false,
            modal_deconnection: false,
            content_mode: Boolean,
        }
    }, mounted() {
        this.d();
        console.log(localStorage.getItem('content_mode'));

        this.getFournisseurs();
        this.categorieSelection();
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        localStorage.setItem('content_mode', String(this.content_mode))
    },

    methods: {


        categorieSelection() {
            axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                this.categories = response.data
            }).catch(error => {
                console.log(error)

            })
        },

        rechercheFournisseur() {
            axios.get('http://127.0.0.1:8000/api/recherche_fournisseur', { params: { inpProduit: this.inpProduit, inpPrix1: this.inpPrix1, inpPrix2: this.inpPrix2, inpCat: this.inpCat } }).then(response => {
                this.fournisseurs = response.data
            }).catch(error => {
                console.log(error)
            })
        },

        d() {

            this.content_mode = JSON.parse(localStorage.getItem('content_mode'))

        },
        change_content_mode: function (data) {
            this.content_mode = data
            localStorage.setItem('content_mode', String(this.content_mode))
        },
        modal_detail_show: function () {
            if (this.modal_detail) {
                this.modal_detail = false
                this.modal_back = false
            } else {
                this.modal_detail = true
                this.modal_back = true
            }
        },
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        },

        getFournisseurs() {
            axios.get(apiUrl + 'api/affichage_fournisseur').then(response => {
                this.fournisseurs = response.data
                // eslint-disable-next-line no-undef

            }).catch(error => {
                console.log(error)
                this.fournisseurs = []
            })
        },
    }
}
</script>