<template>
  <br><br>
  <div class="w-screen flex justify-center items-center">
    <div class="flex gap-3  justify-center  items-center">
      <div class="fas fa-square-caret-left fa-2x"></div>
      <div>1  2   3  ...  8   9   10</div>
      <div class="fas fa-square-caret-right fa-2x"></div>
    </div>
  </div>
</template>
<script>

    export default {
        name:"Pagination",
        data(){
            return{
              data:[]
            }
        }
    }
</script>