<template lang="">
     <div id="Service" class=" w-11/12  ">
                <h2 class="max-sm:text-xl text-green-600 sm:text-3xl font-semibold py-2">Service</h2>
                <div class="">
                    <p class=" max-sm:text-sm text-justify  text-gray-600 ">
                        Explorez le monde de l'agriculture avec nos informations exclusives, vous offrant des analyses
                        approfondies, des client et fournisseurs des produits agricoles, nos services de conseils en
                        gestion agricole pour optimiser la rentabilité de votre exploitation.
                        Découvrez la différence avec notre service exceptionnel, dédiée à surpasser vos attentes et à
                        ouvrir de nouvelles perspectives dans votre domaine.
                    </p>
                    <br>
                </div>
                <div
                    class="grid max-md:grid-cols-none max-lg:grid-cols-2 lg:grid-cols-3 w-fit  gap-3 text-sm py-8 sm:text-base justify-center items-start ">
                    <div
                        class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300  items-start justify-center gap-3 ">
                        <div class="w-full flex text-blue-600 justify-start items-center">
                            <div class="w-full flex  items-center max-sm:gap-x-5 sm:gap-x-9">
                                <div class="fas fa-user fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Client</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-2">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez notre large clientèle diversifiée, englobant tous les domaines de
                                    l'agriculture et de l'élevage, qui veulent des produits agricoles et élevages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full text-blue-600 flex items-center gap-x-9">
                                <div class="fas fa-hand-holding-hand fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Fournisseur</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-2">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez une multitude de fournisseur dans tous les domaines de l'agriculture et de
                                    l'élevage sur notre plateforme, qui ont des produits agricoles et élevages.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col p-3 sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full text-green-600 flex items-center justify-start max-sm:gap-x-4 sm:gap-x-8">
                                <div class="fas fa-seedling fa-3x md:pr-2"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit agricole</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Explorez les divers étapes minutieuses de la culture, depuis la préparation du sol
                                    jusqu'à la récolte, pour vous offrir des produits cultivés avec passion et
                                    expertise.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full text-green-600 flex items-center gap-x-9">
                                <div class="fas fa-cow fa-3x md:pr-2"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Produit elevage</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Explorez les étapes respectueuses de notre processus d'élevage, où le confort, la
                                    nutrition, et la santé de nos animaux sont prioritaires.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full grid grid-cols-2flex justify-start items-center">
                            <div class="w-full flex items-center text-red-600 gap-x-9">
                                <div class="fas fa-chart-line fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Courbe</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Vous pouvez accéder au courbe montrant les prix des différents produits agricoles
                                    des année précedents qui vous aidera à prendre des décisions sur les marchés.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-col p-3  sm:p-3  bg-white shadow-sm shadow-slate-300 items-center justify-center gap-3">
                        <div class="w-full flex justify-start items-center">
                            <div class="w-full flex items-center text-red-600 gap-x-9">
                                <div class="fas fa-calendar-days fa-3x"></div>
                                <div>
                                    <h5 class=" max-sm:text-lg sm:text-xl font-bold">Calendrier agricole</h5>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mx-4">
                            <br>
                            <div class="text-left">
                                <p class="text-gray-600">
                                    Découvrez notre calendrier agricole, un guide saisonnier qui vous accompagne au long
                                    de l'année avec les dates respectives pour les différents pratiques agricoles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>