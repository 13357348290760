<template lang="">

        <div id="Propos" class="w-11/12">
                <h2 class="text-xl sm:text-3xl  font-semibold text-green-600">A propos</h2><br>
                <div>
                    <p class="  max-sm:text-sm text-justify text-gray-600">
                        Notre plateforme web est bien plus qu'un simple espace en ligne. Grâce à notre service, vous
                        pouvez accéder à une vaste liste de clients qui cherchent des produits de qualité. Nous mettons
                        également à votre disposition un éventail de fournisseurs dans
                        tous les domaines de l'agriculture et de l'élevage. Vous avez de l'accès sur le calendrier
                        agricole pour rester en phase avec les cycles naturels. Des recommandations saisonnières
                        détaillées vous guideront dans la gestion optimale
                        de votre exploitaion, de la préparation du sol à la récolte.
                    </p><br>
                </div>
                <div class="grid grid-row-2 gap-y-20  py-8">
                    <div class="sm:flex md:flex md:flex-col lg:grid lg:grid-cols-2 h-fit border gap-3 w-fit ">
                        <div class="w-full ">
                            <img src="./../assets/pexels-cottonbro-studio-4911708.jpg" class="" alt="">
                        </div>
                        <div class="flex items-start justify-start  text-justify m-auto p-4">
                            <div class="">
                                <div class="">
                                    <h6 class="font-semibold text-green-600">Produit d'élevage</h6>
                                    <p class="max-md:text-sm w-full text-justify text-gray-600">
                                        Explorez notre technique d'élevage qui marie habilement des pratiques modernes
                                        avec une tradition d'excellence. Des installations de pointe sont combinées à
                                        des méthodes éprouvées pour offrir des produits qui répondent aux normes les
                                        plus élevées de qualité
                                        et de durabilité. Les méthodes traditionnelles sont fusionnées avec des normes
                                        modernes pour créer des produits d'élevage qui incarnent une qualité supérieur
                                        et une attention méticuleuse à chaque détail... des normes
                                        modernes pour créer des produits d'élevage qui incarnent une qualité supérieur
                                        et une attention méticuleuse à chaque détail. <br><br>
                                        <!-- Découvrez notre approche consciente de l'élevage, où le respect des animaux et de l'environnement est fondamental. Chaque phase du processus reflète notre notre -->
                                        <!-- engagement envers la durabilité, depuis les conditions de vie confortables jusqu'à une alimentation équilibrée. Le résultat est une gamme exceptionnelle de produits d'élevage éthiques. <br><br> -->
                                    </p>
                                    <div class="flex text-green-600 justify-start items-center gap-x-4   cursor-pointer">
                                        <div class="">En savoir plus</div>
                                        <div class="fas fa-arrow-right"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex max-lg:flex-col-reverse lg:grid lg:grid-cols-2 border h-fit gap-3 w-fit ">
                        <div class="flex items-start justify-start  text-justify mx-auto p-4">
                            <div class="">
                                <div class="">
                                    <h6 class="font-semibold text-green-600">Produit d'agriculture</h6>
                                    <p class="max-md:text-sm text-gray-600 w-full text-justify">
                                        Bienvenue dans notre parcours de production agricole, soigneusement élaboré pour
                                        garantir des produits de la plus haute qualité. Tout commence par la sélection
                                        attentive des semences, suivie d'une préparation méticuleuse du sol pour assurer
                                        des conditions
                                        de croissance optimale. Nous accordons une attention particulière à chaque
                                        étape, de la germination des cultures à la récolte finale, pour vous offrir des
                                        produits cultivés avec dévouement et expertise.<br> Explorez avec nous le cycle
                                        complet de la production agricole, commençant par les semailles qui marquent le
                                        début de notre engagement envers la croissance durable. ...
                                        <!-- À mesure que les cultures grandissent, nous veillons à un entretien attentif, avec des pratiques agricoles respectueuses de l'environnement. La récolte, point culminant
                                   de notre processus, symbolise la concrétisation de nos efforts, offrant des produits de  première qualité. -->
                                    </p>
                                    <div class="flex text-green-600 justify-start items-center gap-x-4   cursor-pointer">
                                        <div class="">En savoir plus</div>
                                        <div class="fas fa-arrow-right"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <img src="./../assets/pexels-mark-stebnicki-2749165.jpg" class="" alt="">
                        </div>
                    </div>
                </div>
            </div>
   
</template>
<script>
export default {

}
</script>
<style lang="">

</style>