<template>

  <div class="modal-background" v-if="showAddModal"></div>
  <!--<div class="modal-background" v-if="showSuppressionModal"></div>-->
  
    
  <header class="navbar sticky-top p-0" data-bs-theme="white">
    
    <ul class="navbar-nav flex-row d-md-none mr-28 ml-3">
      <li class="nav-item text-nowrap">
        <div class="fas fa-bars-staggered fa-2x text-black pt-1 mr-2" style="width: 40px; height:38px; color: white;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
      </li>
    </ul>

    <div class="d-md-none text-xl text-green-600 mr-3">
      Agribusiness
    </div>

</header>

<div class="offcanvas offcanvas-end" style="z-index: 1050;" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
            
            <div style="display: inline-flex; padding-left: 20px; border-radius: 5px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class="font-semibold text-gray-700" style="text-decoration: none; font-size: 20px;"> Statistique</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
              <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
              <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
            </div>
        
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier agricole</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class="text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
            </div>
        
        </ul>
  </div>
</div>



<div class="container-fluid">
  <div class="row">
    <div class="sidebar fixed h-full border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">

            <div class="" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                <div><img src="../../assets/logoAgri.jpg" class="logoList" style="width: 40px; height:38px; color: white;"></div>
                <div class=" mt-2 text-2xl text-green-600 ml-3 md:ml-1 md:text-xl md:mt-3">Agribusiness</div>
              </div>
              <div class="border-b-2 mb-3"></div>
            
            <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
                <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
              </div>
          
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200 py-1" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px; background-color: rgb(207, 205, 205);" aria-current="page" href="#">
                <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
              </div>

              <logout></logout>
          
          </ul>
        </div>
      </div>
      </div>

    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Culture</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjout">Ajouter</button>
        </div>
      </div>


      <div class="lg:flex input-group mb-3 d-flex lg:w-28">
          <div>
            <input type="text" class="form-control h-8 rounded-tr-none rounded-br-none" placeholder="Nom d'agriculture..." aria-label="Nom d'agriculture" aria-describedby="button-addon2" v-model="nomRecherche">
          </div>
          <div>
            <button class="btn btn-primary btn-sm" @click="rechercheCulture">Rechercher</button>
          </div>
          
      </div><br>


      <div class="overflow-x-auto md:mx-full  max-sm:mx-4 ">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="">
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden">Id</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Origine</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Catégorie</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="culture in cultures" :key="culture.idculture">
                            
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7 hidden">{{ culture.idculture }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{culture.nom}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{culture.origine}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{culture.categorie}}</td>
                            
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7"><div class="fas fa-eye me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalDetail"  @click="idculture=culture.idculture,nom1=culture.nom,
                              image=culture.image,origine1=culture.origine,categorie1=culture.categorie, affichageImage()"></div><div class="fas fa-pen-to-square text-blue-600 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalMod" @click="idculture=culture.idculture,
                              nom1=culture.nom, origine1=culture.origine,categorie1=culture.categorie"></div><div class="fas fa-trash-can text-red-700 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalSuppression" @click="idculture3=culture.idculture"></div></td>
                        </tr>
                    </tbody>
                </table>
        </div>


      <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      </div>

      </div>
      </div>

      <div class="modal" role="dialog" tabindex="-1" ref="myModalMenu" >
          <div class="modal-dialog" role="document">
                <div class="modal-content">               
                  <div class="modal-body">
                    <div><button class="btn btn-danger" @click="closeModalMenu">Retour</button></div>
                        <ul class="nav flex-column">
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                              <div><router-link to="./AccueilAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./FournisseurAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CalendrierAdmin"><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CultureAdmin"><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./AbonnementAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./ComparaisonAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" style="text-decoration: none; color:black; font-size: 20px;">Comparaison</router-link></div>
                          </div>
                        </ul> 
                    </div>
                  </div>
                </div>
          </div>

    
    <div class="modal" tabindex="-1" role="dialog" ref="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mon Modal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Contenu du modal -->
            <p>Contenu du modal ici.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Fermer</button>
            <button type="button" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjout" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_culture" method="POST" @submit.prevent="saveCulture">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ajout</h4>   
                    <div class="row5">
                        <div><label class="font-semibold" for="">Image :</label></div>
                        <div><input type="file" class="border w-80 rounded mb-4" ref="imageInput" @change="handleFileChange" required></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="nom" required> </div>
                    </div>
                      
                      <div class="row5">
                        <div><label class="font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="origine" required> </div>
                      </div>
                      
                      <div class="row5">
                        <div><label class="font-semibold" for="">Catégorie :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="categorie" required></textarea></div>
                    </div>
                      
                    
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary"  data-bs-toggle="modal" data-bs-target="#myModalAjoutKarazany">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>


        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_culture_karazany" method="POST"  @submit.prevent="ajoutKarazany">
                <div class="modal-content">
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Karazany</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Nom :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="nomKarazany" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Description :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="description" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutAnkapobeny">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutAnkapobeny" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_ankapobeny" method="POST"  @submit.prevent="ajoutAnkapobeny">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ankapobeny</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Toe-tany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="toeTany" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fotoam-pambolena :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fotoanaFambolena" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Toetr'andro :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="toeTrandro" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutFahavalo">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutFahavalo" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_fahavalo" method="POST"  @submit.prevent="ajoutFahavalo">
                <div class="modal-content">    
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fahavalo</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Anarana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="anarana" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="mombamomba" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Asany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="asany" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutFitsaboana">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutFitsaboana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_fitsaboana" method="POST"  @submit.prevent="ajoutFitsaboana">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fitsaboana</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Anaran'aretina :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="anaranAretina" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Vaha olana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="vahaOlana" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fomba fisehony :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fombaFisehony" required></textarea></div>
                    </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutFambolena">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutFambolena" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_fambolena" method="POST"  @submit.prevent="ajoutFambolena">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fambolena</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fikarakarana ny tany :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakaranaTany"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Famafazana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="famafazana"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fikarakarana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakarana"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Famindrana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="famindrana"></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fijinjana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fijinjana"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fiotazana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fiotazana"></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fanetsana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fanetsana"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fitehirizana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fitehirizana"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class="font-semibold" for="">Fatran'ny zezika :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fatraZezika"></textarea>
                        </div>              

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Terminer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>



        <div class="modal fade" role="dialog" tabindex="-1" id="myModalMod" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_culture" method="POST" @submit.prevent="modCulture">
                <div class="modal-content">              
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Modification</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="nom1" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="origine1" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Catégorie :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="categorie1" required></textarea></div>
                    </div><br>
                      <div> <button type="submit" class="bg-blue-500 w-40 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-sm" data-bs-dismiss="modal">Modifier</button></div><br>

                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalModKarazany" @click="detail_karazany">Karazany</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#myModalModAnkapobeny" @click="detail_ankapobeny">Ankapobeny</button>
                      </div>
                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#myModalModFahavalo" @click="detail_fahavalo">Fahavalo</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#myModalModFitsaboana"  @click="detail_fitsaboana">Fitsaboana</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 5px;" data-bs-toggle="modal" data-bs-target="#myModalModFambolena" @click="detail_fambolena">Fambolena</button>
                      </div>
                      <div class="btn btn-group">
                        
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" @click="initDetail">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_culture_karazany" method="POST" @submit.prevent="modCultureKarazany">
              <div class="modal-content">
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Karazany</h4>
                    <input v-model="idculture" style="display: none;">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Anarana :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="nom2" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="description1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailKarazany">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod" >Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModAnkapobeny" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_ankapobeny" method="POST" @submit.prevent="modCultureAnkapobeny">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ankapobeny</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Toe-tany :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="toeTany1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fotoam-pambolena :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fotoanaFambolena1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Toetr'andro :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="toeTrandro1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailAnkapobeny">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod" >Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModFahavalo"  style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_fahavalo" method="POST" @submit.prevent="modCultureFahavalo">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fahavalo</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Anarana :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="anarana1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="mombamomba1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Asany :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="asany1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailFahavalo">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod" >Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModFitsaboana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_fitsaboana" method="POST" @submit.prevent="modCultureFitsaboana">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fitsaboana</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Anaran'aretina :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="anaranAretina1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Vaha olana :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="vahaOlana1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fomba fisehony :</label></div>
                        <div><textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fombaFisehony1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailFitsaboana">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod" >Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModFambolena" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_fambolena" method="POST" @submit.prevent="modCultureFambolena">
              <div class="modal-content">       
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fambolena</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                          <div><label class=" font-semibold" for="">Fikarakarana ny tany :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakaranaTany1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Famafazana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="famafazana1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fikarakarana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakarana1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Famindrana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="famindrana1"></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fijinjana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fijinjana1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fiotazana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fiotazana1"></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fanetsana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fanetsana1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fitehirizana :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fitehirizana1"></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fatran'ny zezika :</label></div>
                          <textarea class="border border-black pl-2" name="" id="i" cols="45" rows="3" v-model="fatraZezika1"></textarea>
                        </div>  
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailFambolena">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalSuppression" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/suppression_culture" method="POST" @submit.prevent="suppressionCulture(); suppressionKarazany(); suppressionAnkapobeny();
            suppressionFahavalo(); suppressionFitsaboana() ;suppressionFambolena() ">
                <div class="modal-content">        
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Suppression</h4>
                    <input v-model="idculture3" class="hidden">
                    <div class="row5">
                        <div>Voulez-vous supprimer cet culture ?</div>
                    </div>                    

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-sm btn-danger" data-bs-dismiss="modal">Supprimer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetail" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="" method="">
                <div class="modal-content">     
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Détail</h4> 
                    <input v-model="idculture"  class="hidden">
                    <div class="row5">
                        <div><label class="font-semibold">Image:</label></div>
                        <div class="">
                          <img :src="getImageUrl(image)" class="" alt="Image">
                        </div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="nom1" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="origine1" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Catégorie :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="categorie1" disabled></textarea></div>
                    </div><br>

                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" data-bs-toggle="modal" data-bs-target="#myModalDetailKarazany" type="button" @click="detail_karazany">Karazany</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalDetailAnkapobeny" @click="detail_ankapobeny">Ankapobeny</button>
                      </div>
                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalDetailFahavalo" @click="detail_fahavalo">Fahavalo</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalDetailFitsaboana"  @click="detail_fitsaboana">Fitsaboana</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalDetailFambolena" @click="detail_fambolena">Fambolena</button>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" @click="initDetail">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_karazany" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">karazany</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="nom2" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Description :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="description1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailKarazany">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailAnkapobeny" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_ankapobeny" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ankapobeny</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Toe-tany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="toeTany1" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fotoam-pambolena :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fotoanaFambolena1" disabled></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Toetr'andro :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="toeTrandro1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailAnkapobeny">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailFahavalo" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_fahavalo" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fahavalo</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Anarana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="anarana1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="mombamomba1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Asany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="asany1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailFahavalo">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailFitsaboana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_fitsaboana" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fitsaboana</h4>
                    <input v-model="idculture" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Anaran'aretina :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="anaranAretina1" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Vaha olana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="vahaOlana1" disabled></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fomba fisehony :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fombaFisehony1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailFitsaboana">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailFambolena" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_fambolena" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fambolena</h4>
                    <input type="number" v-model="idculture2" class="hidden">
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fikarakarana ny tany :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakaranaTany1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Famafazana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="famafazana1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fikarakarana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fikarakarana1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Famindrana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="famindrana1" disabled></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fijinjana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fijinjana1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fiotazana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fiotazana1" disabled></textarea>
                        </div>  
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fanetsana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fanetsana1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fitehirizana :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fitehirizana1" disabled></textarea>
                        </div>
                        <div class="row5">
                          <div><label class=" font-semibold" for="">Fatran'ny zezika :</label></div>
                          <textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fatraZezika1" disabled></textarea>
                        </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailFambolena">Fermer</button>
                  </div>

                </div>
              </form>
          </div>
        </div>

</template>

<script>
import axios from 'axios'
import apiUrl from '../../apiConfig';
import logout from '@/components/logoutAdmin.vue';

import 'datatables.net-responsive-bs5'
//import 'datatables.net';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.js';
//import DataTable from 'datatables.net-bs5';

import 'datatables.net-responsive-bs5'
//import DataTable from 'datatables.net-vue3';
export default {
  name: 'CultureAdmin',
  components: {
    logout
  },
  data () {
    return {
          showModificationModal: false, showSuppressionModal: false, AjoutClientModal: false,
          showNoir: false, showAddModal: false, showDetailModal: false, isModalOpen: false,
          idculture2: '', showDiv: true, idculture3: '', idculture: '',
          nom1:'', origine1: '', categorie1:'', nom2:'',
          anarana:'', mombamomba:'', asany:'', anarana1:'', mombamomba1:'', asany1:'',
          nomKarazany: '', description: '', nomKarazany1: '', description1: '',
          toeTany:'', fotoanaFambolena:'',toeTrandro:'', toeTany1:'', fotoanaFambolena1:'',toeTrandro1:'', 
          anaranAretina:'',vahaOlana:'',fombaFisehony:'', anaranAretina1:'',vahaOlana1:'',fombaFisehony1:'',
          fikarakaranaTany:'', famafazana:'', fikarakarana:'', famindrana:'', fijinjana:'', fiotazana:'', fanetsana:'', fitehirizana:'', fatraZezika:'',
          fikarakaranaTany1:'', famafazana1:'', fikarakarana1:'', famindrana1:'', fijinjana1:'', fiotazana1:'', fanetsana1:'', fitehirizana1:'', fatraZezika1:'',
          nom: '', image: '', origine: '', categorie: '',
          culture:{
            image:"", nom: "", origine:"", categorie:""
          },
          isModModal: false,
          cultures:[], categories:[],
          divWidth: 15, selectedImage: null, divWidth2: 85,
          imageUrl: null,
    }
  },
  mounted() {
    this.getCultures();
  },

  methods: {
    
    getImageUrl(image) {
      // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
      return apiUrl + `images/${image}`;
    },

    affichageImage(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_image', {params:{idculture: this.culture.idculture}}).then(response => {
        const img = response.data.image;
        this.imageUrl= img;
      })
      .catch(error => {(
              console.log(error)
            )}); 
    },
    
    getCultures(){
            axios.get(apiUrl + 'api/affichage_culture').then(response => {
              this.cultures = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.cultures=[]
            })
      },

    handleFileChange(event) {
      this.image = event.target.files[0];
    },

    initDetail(){
      this.nom1='', this.origine1='', this.categorie1=''
    },
    initDetailKarazany(){
      this.nom2='', this.description1=''
    },
    initDetailAnkapobeny(){
      this.toeTany1='', this.fotoanaFambolena1='', this.toeTrandro1=''
    },
    initDetailFahavalo(){
      this.anarana1='', this.mombamomba1='', this.asany1=''
    },
    initDetailFitsaboana(){
      this.anaranAretina1='', this.vahaOlana1='', this.fombaFisehony1=''
    },
    initDetailFambolena(){
      this.fikarakaranaTany1='', this.famafazana1='', this.fikarakarana1='',
      this.famindrana1='', this.fijinjana1='', this.fiotazana1='',
      this.fanetsana1='', this.fitehirizana1='', this.fatraZezika1=''
    },

    saveCulture(){           
            // eslint-disable-next-line no-unused-vars
            const formData = new FormData();
            formData.append('nom', this.nom);
            formData.append('origine', this.origine);
            formData.append('categorie', this.categorie);
            formData.append('image', this.image);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_culture', formData).then(response => { 
              const idculture1 = response.data.idculture;
              //console.log(idelevage1);
              this.idculture2= idculture1;
              
                this.image = ''; this.nom= '', this.origine='', this.categorie='' 
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    rechercheCulture(){
          axios.get(apiUrl + 'api/recherche_culture', {params: {nomRecherche: this.nomRecherche}}).then(response => {
                this.cultures = response.data
              }).catch(error =>{
                console.log(error)
              })
      },

    ajoutKarazany(){      
            const formData = new FormData();
            formData.append('nom', this.nomKarazany);
            formData.append('idculture2', this.idculture2);
            formData.append('description', this.description);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_culture_karazany', formData).then(response => {
              
                this.nomKarazany='', this.description=''
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutAnkapobeny(){          
            const formData = new FormData();
            formData.append('toeTany', this.toeTany);
            formData.append('idculture2', this.idculture2);
            formData.append('fotoanaFambolena', this.fotoanaFambolena);
            formData.append('toeTrandro', this.toeTrandro);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_ankapobeny', formData).then(response => {
              
                this.toeTany='', this.fotoanaFambolena='', this.toeTrandro=''
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutFahavalo(){  
            const formData = new FormData();
            formData.append('anarana', this.anarana);
            formData.append('idculture2', this.idculture2);
            formData.append('mombamomba', this.mombamomba);
            formData.append('asany', this.asany);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fahavalo', formData).then(response => {
             
                this.anarana='', this.mombamomba='', this.asany=''
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutFitsaboana(){           
            const formData = new FormData();
            formData.append('anaranAretina', this.anaranAretina);
            formData.append('idculture2', this.idculture2);
            formData.append('vahaOlana', this.vahaOlana);
            formData.append('fombaFisehony', this.fombaFisehony);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fitsaboana', formData).then(response => {
              
                this.getCultures();
                this.anaranAretina='', this.vahaOlana='', this.fombaFisehony=''
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutFambolena(){           
            const formData = new FormData();
            formData.append('idculture2', this.idculture2);
            formData.append('fikarakaranaTany', this.fikarakaranaTany);  formData.append('famafazana', this.famafazana); formData.append('fikarakarana', this.fikarakarana);
            formData.append('famindrana', this.famindrana); formData.append('fijinjana', this.fijinjana); formData.append('fiotazana', this.fiotazana);
            formData.append('fanetsana', this.fanetsana); formData.append('fitehirizana', this.fitehirizana); formData.append('fatraZezika', this.fatraZezika);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fambolena', formData).then(response => {
              
                this.getCultures();
                this.fikarakaranaTany='', this.famafazana='', this.fikarakarana='', this.fanetsana='', this.fitehirizana='',
                this.famindrana='', this.fijinjana='', this.fiotazana='', this.fatraZezika=''
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    modCulture(){
      axios.post(apiUrl + 'api/modification_culture', {idculture:this.idculture, nom1:this.nom1, origine1:this.origine1,categorie1:this.categorie1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  this.getCultures();
                  this.initDetail();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modCultureKarazany(){
      axios.post(apiUrl + 'api/modification_culture_karazany', {idculture:this.idculture, anarana:this.nom2, mombamomba:this.description1
              // eslint-disable-next-line no-unused-vars      
            }).then(response => {
                              
                  this.getCultures();
                  this.initDetailKarazany();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modCultureAnkapobeny(){
      axios.post(apiUrl + 'api/modification_ankapobeny', {idculture:this.idculture, toeTany1:this.toeTany1, fotoanaFambolena1:this.fotoanaFambolena1, toeTrandro1:this.toeTrandro1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                    
                  this.getCultures();
                  this.initDetailAnkapobeny();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modCultureFahavalo(){
      axios.post(apiUrl + 'api/modification_fahavalo', {idculture:this.idculture, anarana1:this.anarana1, mombamomba1:this.mombamomba1, asany1:this.asany1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                             
                  this.getCultures();
                  this.initDetailFahavalo();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modCultureFitsaboana(){
      axios.post(apiUrl + 'api/modification_fitsaboana', {idculture:this.idculture, anaranAretina1:this.anaranAretina1, vahaOlana1:this.vahaOlana1, fombaFisehony1:this.fombaFisehony1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
               
                  this.getCultures();
                  this.initDetailFitsaboana();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modCultureFambolena(){
      axios.post(apiUrl + 'api/modification_fambolena', {idculture:this.idculture, fikarakaranaTany1:this.fikarakaranaTany1, famafazana1:this.famafazana1, fikarakarana1:this.fikarakarana1,
        famindrana1:this.famindrana1, fijinjana1:this.fijinjana1, fiotazana1:this.fiotazana1, fanetsana1:this.fanetsana1, fitehirizana1:this.fitehirizana1, fatraZezika1:this.fatraZezika1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                             
                  this.getCultures();
                  this.initDetailFambolena();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    detail_karazany(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_culture_karazany',{params:{idculture3: this.idculture}}).then(response => {
        this.nom2 = response.data[0].anarana;
        this.description1 = response.data[0].mombamomba;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_ankapobeny(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_ankapobeny',{params:{idculture3: this.idculture}}).then(response => {
        this.toeTany1 = response.data[0].toeTany;
        this.fotoanaFambolena1 = response.data[0].fotoanaFambolena;
        this.toeTrandro1 = response.data[0].toeTrandro;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_fahavalo(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_fahavalo',{params:{idculture3: this.idculture}}).then(response => {
        this.anarana1 = response.data[0].anarana;
        this.mombamomba1 = response.data[0].mombamomba;
        this.asany1 = response.data[0].asany;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_fitsaboana(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_fitsaboana',{params:{idculture3: this.idculture}}).then(response => {
        this.anaranAretina1 = response.data[0].anaranAretina;
        this.vahaOlana1 = response.data[0].vahaOlana;
        this.fombaFisehony1 = response.data[0].fombaFisehony;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_fambolena(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_fambolena',{params:{idculture3: this.idculture}}).then(response => {
        this.fikarakaranaTany1 = response.data[0].fikarakaranaTany;
        this.famafazana1 = response.data[0].famafazana; 
        this.fikarakarana1 = response.data[0].fikarakarana;  
        this.famindrana1 = response.data[0].famindrana;
        this.fijinjana1 = response.data[0].fijinjana;
        this.fiotazana1 = response.data[0].fiotazana;
        this.fanetsana1 = response.data[0].fanetsana;
        this.fitehirizana1 = response.data[0].fitehirizana;
        this.fatraZezika1 = response.data[0].fatraZezika;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    suppressionCulture(){
            axios.post(apiUrl + 'api/suppression_culture', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
                  this.closeModalSuppression;
                  this.getCultures();
                
              }).catch(error => {(
                console.log(error)
              )});
    },
     suppressionKarazany(){
            axios.post(apiUrl + 'api/suppression_culture_karazany', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionAnkapobeny(){
            axios.post(apiUrl + 'api/suppression_ankapobeny', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionFahavalo(){
            axios.post(apiUrl + 'api/suppression_fahavalo', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionFitsaboana(){
            axios.post(apiUrl + 'api/suppression_fitsaboana', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    suppressionFambolena(){
            axios.post(apiUrl + 'api/suppression_fambolena', {idculture:this.idculture3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modificationFournisseur(){
            axios.post(apiUrl + 'api/modification_fournisseur', {idfourni:this.idfourni, nom:this.nom, contact:this.contact,email:this.email,adresse:this.adresse,
              // eslint-disable-next-line no-unused-vars
              statut:this.statut,prodDispo:this.prodDispo,prixUnitaire:this.prixUnitaire,quantite:this.quantite,nomcat:this.nomcat}).then(response => {
                
                  this.closeModalModification();
                  this.getFournisseurs();
                
              }).catch(error => {(
                console.log(error)
              )});
    },


    // Ajoutez la logique de mise à jour des données ici
  },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../components/styleAdmin/dash1.css';
  
</style>

<head>
  <script src="/docs/5.3/assets/js/color-modes.js"></script>
  <link rel="canonical" href="https://getbootstrap.com/docs/5.3/examples/dashboard/">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@docsearch/css@3">

  <link href="/docs/5.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <!-- Favicons -->
  <link rel="apple-touch-icon" href="/docs/5.3/assets/img/favicons/apple-touch-icon.png" sizes="180x180">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-32x32.png" sizes="32x32" type="image/png">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-16x16.png" sizes="16x16" type="image/png">
  <link rel="manifest" href="/docs/5.3/assets/img/favicons/manifest.json">
  <link rel="mask-icon" href="/docs/5.3/assets/img/favicons/safari-pinned-tab.svg" color="#712cf9">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon.ico">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css" rel="stylesheet">
</head>


