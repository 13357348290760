<template>

  <div class="modal-background" v-if="showAddModal"></div>
  <!--<div class="modal-background" v-if="showSuppressionModal"></div>-->
  
    
  <header class="navbar sticky-top p-0" data-bs-theme="white">
    
    <ul class="navbar-nav flex-row d-md-none mr-28 ml-3">
      <li class="nav-item text-nowrap">
        <div class="fas fa-bars-staggered fa-2x text-black pt-1 mr-2" style="width: 40px; height:38px; color: white;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
      </li>
    </ul>

    <div class="d-md-none text-xl text-green-600 mr-3">
      Agribusiness
    </div>

</header>

<div class="offcanvas offcanvas-end" style="z-index: 1050;" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
            
            <div style="display: inline-flex; padding-left: 20px; border-radius: 5px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;"> Statistique</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
              <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
              <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
            </div>
        
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier </router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class="text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
            </div>
        
        </ul>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="sidebar fixed h-full border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">

            <div class="" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                <div><img src="../../assets/logoAgri.jpg" class="logoList" style="width: 40px; height:38px; color: white;"></div>
                <div class=" mt-2 text-2xl text-green-600 ml-3 md:ml-1 md:text-xl md:mt-3">Agribusiness</div>
              </div>
              <div class="border-b-2 mb-3"></div>
            
            <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
                <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
              </div>
          
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200 py-1" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px; background-color: rgb(207, 205, 205);" aria-current="page" href="#">
                <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
              </div>

              <logout></logout>
          
          </ul>
        </div>
      </div>
      </div>

    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Calendrier</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <button type="button" class="btn btn-sm btn-primary"  data-bs-toggle="modal" data-bs-target="#myModalAjout">Ajouter</button>
        </div>
      </div><br>

      <!--<div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <h4>Luciano</h4>
          </div>
          <div class="col-8 col-md-4">
            <input type="text"> 
          </div>
            
        </div>
        <div class="col-12 col-md-6">
          <h4>Esmerldo</h4><input type="text">
        </div>
      </div>

      <div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-3">
            <p>Produit:</p>
          </div>
          <div class="col-8 col-md-3">
            <input type="text" style="height: 24px; border-radius: 6px;" v-model="inpProduit"> 
          </div>   
        </div>
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <p>Catégorie:</p>
          </div>
          <div class="col-8 col-md-4">
            <select v-model="inpCat">
              <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
            </select>
          </div>   
        </div> 
      </div>
      <div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-3">
            <p>Prix entre:</p>
          </div>
          <div class="col-8 col-md-3">
            <input type="number" style="height: 24px; border-radius: 6px;" v-model="inpPrix1">
          </div>   
        </div>
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <p>Et:</p>
          </div>
          <div class="col-8 col-md-4">
            <input type="number" style="height: 24px; border-radius: 6px;" v-model="inpPrix2">
          </div>   
        </div> 
      </div>
      <div class="d-flex justify-content-center">      
            <button class="btn btn-sm btn-primary" @click="getClients(), init()">Initialiser</button>
            <button class="btn btn-sm btn-primary" style="margin-left: 10px;" @click="rechercheClient()">Rechercher</button>    
      </div><br>-->


      <div class="overflow-x-auto md:mx-full  max-sm:mx-4 ">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="">
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden">Id</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nom du produit</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Zone</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Type de culture</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Observation</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    </thead>
                    <tbody v-if="calendriers.length > 0" class="bg-white divide-y divide-gray-200">
                        <tr v-for="calendrier in calendriers" :key="calendrier.idcal" class="hover:bg-gray-100">
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7 hidden">{{ calendrier.idcal }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{ calendrier.nomPro }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{calendrier.zone}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{calendrier.typeCult}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{calendrier.observation}}</td>
                            
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7"><div class="fas fa-eye me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalDetail"  @click="idcal=calendrier.idcal,nomPro=calendrier.nomPro, zone=calendrier.zone, typeCult=calendrier.typeCult,observation=calendrier.observation"></div>
                            <div class="fas fa-pen-to-square text-blue-600 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalModification" @click="idcal=calendrier.idcal,nomPro=calendrier.nomPro, zone=calendrier.zone, typeCult=calendrier.typeCult,observation=calendrier.observation"></div>
                            <div class="fas fa-trash-can text-red-700 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalSuppression" @click="idcal1=calendrier.idcal"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        

      <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      </div>

      


      </div>
      </div>

      <div class="modal" role="dialog" tabindex="-1" ref="myModalMenu" >
          <div class="modal-dialog" role="document">
                <div class="modal-content">               
                  <div class="modal-body">
                    <div><button class="btn btn-danger" @click="closeModalMenu">Retour</button></div>
                        <ul class="nav flex-column">
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                              <div><router-link to="./AccueilAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./FournisseurAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ElevageAdmin"><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CultureAdmin"><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./AbonnementAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./ComparaisonAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" style="text-decoration: none; color:black; font-size: 20px;">Comparaison</router-link></div>
                          </div>
                        </ul> 
                    </div>
                  </div>
                </div>
          </div>

    
    <div class="modal" tabindex="-1" role="dialog" ref="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mon Modal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Contenu du modal -->
            <p>Contenu du modal ici.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Fermer</button>
            <button type="button" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>

    
        <div class="modal" role="dialog" tabindex="-1" id="myModalAjout" style="z-index: 1000;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_calendrier" method="POST" @submit.prevent="ajoutCalendrier">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ajout</h4>                 
                      <div class="row5">
                        <div><label class="font-semibold" for="">Nom du produit :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="calendrier.nomPro" > </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Zone :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="calendrier.zone" > </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Type de culture :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="calendrier.typeCult" > </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Observation :</label></div>
                        <div><textarea class="border w-80 rounded" name="" id="i" cols="45" rows="3" v-model="calendrier.observation" ></textarea> </div>
                      </div>
                      <div>
                        <div><label class="font-semibold" for="">Couleur :</label></div>
                        <div><input type="color" class="border w-80 rounded mb-4" v-model="calendrier.couleur" @input="updateColor" /></div>
                      </div>
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button class="btn btn-primary" type="submit" data-bs-toggle="modal" data-bs-target="#myModalAjoutDate">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalAjoutDate" style="z-index: 1000;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_date" method="POST" @submit.prevent="ajoutDate">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ajout</h4>   
                      <input type="number" class="border border-black hidden" v-model="idcal2">
                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Préparation du sol :</label></div>
                        <div>
                          <select v-model="prepaSold1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="prepaSolf1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="prepaSold2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="prepaSolf2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Semis :</label></div>
                        <div>
                          <select v-model="semisd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="semisf1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>            
                          </select>
                        </div>
                        <div>
                          <select v-model="semisd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="semisf2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                 
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Marcottage :</label></div>
                        <div>
                          <select v-model="marcod1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="marcof1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                     
                          </select>
                        </div>
                        <div>
                          <select v-model="marcod2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="marcof2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>              
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Répiquage et plantation :</label></div>
                        <div>
                          <select v-model="repd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="repf1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="repd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="repf2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Entretien :</label></div>
                        <div>
                          <select v-model="entretiend1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="entretienf1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                        <div>
                          <select v-model="entretiend2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="entretienf2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Fécondation artificielle :</label></div>
                        <div>
                          <select v-model="fecondationd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="fecondationf1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="fecondationd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="fecondationf2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Récolte :</label></div>
                        <div>
                          <select v-model="recolted1" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="recoltef1" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="recolted2" class="border border-black w-25 mb-2 mr-4 rounded ml-5">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="recoltef2" class="border border-black w-25 ml-4 rounded">
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>               
                          </select>
                        </div>
                      </div><br>
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button class="btn btn-primary" type="submit" data-bs-dismiss="modal">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalDetailDate" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_date" method="POST">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Détail</h4>
                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Préparation du sol :</label></div>
                        <div>
                          <select v-model="PrepaSold1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>

                          au
                          <select v-model="PrepaSolf1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="PrepaSold2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="PrepaSolf2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>
                      
                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Semis :</label></div>
                        <div>
                          <select v-model="Semisd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Semisf1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>            
                          </select>
                        </div>
                        <div>
                          <select v-model="Semisd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Semisf2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                 
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Marcottage :</label></div>
                        <div>
                          <select v-model="Marcod1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Marcof1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                     
                          </select>
                        </div>
                        <div>
                          <select v-model="Marcod2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Marcof2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>              
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Répiquage et plantation :</label></div>
                        <div>
                          <select v-model="Repd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Repf1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="Repd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Repf2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Entretien :</label></div>
                        <div>
                          <select v-model="Entretiend1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Entretienf1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                        <div>
                          <select v-model="Entretiend2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Entretienf2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Fécondation artificielle :</label></div>
                        <div>
                          <select v-model="Fecondationd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Fecondationf1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="Fecondationd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Fecondationf2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Récolte :</label></div>
                        <div>
                          <select v-model="Recolted1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Recoltef1" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="Recolted2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Recoltef2" class="border border-black w-25 ml-4 rounded" disabled>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>               
                          </select>
                        </div>
                      </div><br>
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="init3()">Fermer</button>
            
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalModDate" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_date" method="POST" @submit.prevent="modificationDate">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Modification</h4>
                    <input v-model="idcal" class="hidden">
                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Préparation du sol :</label></div>
                        <div>
                          <select v-model="PrepaSold1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="PrepaSolf1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="PrepaSold2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="PrepaSolf2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>
                      
                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Semis :</label></div>
                        <div>
                          <select v-model="Semisd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Semisf1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>            
                          </select>
                        </div>
                        <div>
                          <select v-model="Semisd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Semisf2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                 
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Marcottage :</label></div>
                        <div>
                          <select v-model="Marcod1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Marcof1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                     
                          </select>
                        </div>
                        <div>
                          <select v-model="Marcod2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Marcof2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>              
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Répiquage et plantation :</label></div>
                        <div>
                          <select v-model="Repd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Repf1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="Repd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Repf2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Entretien :</label></div>
                        <div>
                          <select v-model="Entretiend1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Entretienf1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                    
                          </select>
                        </div>
                        <div>
                          <select v-model="Entretiend2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Entretienf2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Fécondation artificielle :</label></div>
                        <div>
                          <select v-model="Fecondationd1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Fecondationf1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                   
                          </select>
                        </div>
                        <div>
                          <select v-model="Fecondationd2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Fecondationf2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                  
                          </select>
                        </div>
                      </div><br>

                      <div class="row5">
                        <div><label class="font-semibold" for="" style="margin-left: 29px;">Récolte :</label></div>
                        <div>
                          <select v-model="Recolted1" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Recoltef1" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>                
                          </select>
                        </div>
                        <div>
                          <select v-model="Recolted2" class="border border-black w-25 mb-2 mr-4 rounded ml-5" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>
                          </select>
                          au
                          <select v-model="Recoltef2" class="border border-black w-25 ml-4 rounded" required>
                            <option value="0">0</option><option value="0.5">0,5</option><option value="1">1</option><option value="1.5">1,5</option><option value="2">2</option><option value="2.5">2,5</option><option value="3">3</option><option value="3.5">3,5</option><option value="4">4</option><option value="4.5">4,5</option><option value="5">5</option><option value="5.5">5,5</option>
                            <option value="6">6</option><option value="6.5">6,5</option><option value="7">7</option><option value="7.5">7,5</option><option value="8">8</option><option value="8.5">8,5</option><option value="9">9</option><option value="9.5">9,5</option><option value="10">10</option><option value="10.5">10,5</option><option value="11">11</option><option value="11.5">11,5</option>               
                          </select>
                        </div>
                      </div><br>
                  </div>
                  <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal" @click="init2(); init3()">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalSuppression" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/suppression_calendrier" method="POST" @submit.prevent="suppressionCalendrier(); suppressionDate()">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Suppression</h4>  
                    <input v-model="idcal1" class="hidden">               
                    <div class="row5">
                        <div>Voulez-vous supprimer ce calendrier ?</div>
                    </div>                    

                  </div>
                  <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-danger" data-bs-dismiss="modal">Supprimer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

      <div class="modal" role="dialog" tabindex="-1" id="myModalModification" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_calendrier" method="POST" @submit.prevent="modificationCalendrier">
                <div class="modal-content">          
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Modification</h4>  
                    <input v-model="idcal" class="hidden">               
                    <div class="row5">
                        <div><label class="font-semibold" for="">Nom du produit :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="nomPro" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Zone :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="zone" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Type de culture :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="typeCult" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Observation :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="observation" required></textarea> </div>
                      </div>
                      <div> <button type="submit" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal">Modifier</button></div><br>
                      <div>
                        <button class="btn btn-sm btn-primary px-4" type="button" @click="detail_date" data-bs-toggle="modal" data-bs-target="#myModalModDate">Date</button>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalDetail" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form >
                <div class="modal-content">               
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Détail</h4>  
                    <input v-model="idcal" class="hidden">               
                    <div class="row5">
                        <div><label class="font-semibold" for="">Nom du produit :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="nomPro" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Zone :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="zone" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Type de culture :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="typeCult" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Observation :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="observation" disabled></textarea> </div>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary me-5" data-bs-dismiss="modal" @click="init2">Fermer</button>
                    <button type="button" class="btn btn-primary" @click="detail_date(); init2()" data-bs-toggle="modal" data-bs-target="#myModalDetailDate">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

  

</template>

<script>
import apiUrl from '../../apiConfig';
import logout from '@/components/logoutAdmin.vue';

import axios from 'axios'
import 'datatables.net-responsive-bs5'
//import 'datatables.net';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.js';
//import DataTable from 'datatables.net-bs5';

import 'datatables.net-responsive-bs5'
//import DataTable from 'datatables.net-vue3';
export default {
  name: 'CalendrierAdmin',
  components: {
    logout
  },  
  data () {
    return {
          showModificationModal: false,
          showSuppressionModal: false,
          AjoutClientModal: false,
          showAddModal: false,
          showDetailModal: false,
          isModalOpen: false,
          catSelection: null,
          inpProduit: '',
          idcal1: '',
          inpPrix1:'',
          inpPrix2:'',
          inpCat:'',
          showDiv: true,
          idcal: "", nomPro: "", zone:"", typeCult:"", observation:"", couleur:"",
          calendrier:{
            nomPro: "", zone:"", typeCult:"", observation:"", couleur:"",
          },
          isModModal: false,
          calendriers:[],
          categories:[],
          divWidth: 15,
          divWidth2: 85,
          idcal2:"",
          prepaSold1:"", prepaSold2:"", prepaSolf1:"", prepaSolf2:"", semisd1:"", semisf1:"", semisd2:"", semisf2:"", marcod1:"", marcof1:"", marcod2:"", marcof2:"",
          repd1:"", repf1:"", repd2:"", repf2:"", entretiend1:"", entretienf1:"", entretiend2:"", entretienf2:"", fecondationd1:"", fecondationf1:"", fecondationd2:"", fecondationf2:"",
          recolted1:"", recoltef1:"", recolted2:"", recoltef2:"",

          PrepaSold1:"", PrepaSold2:"", PrepaSolf1:"", PrepaSolf2:"", Semisd1:"", Semisf1:"", Semisd2:"", Semisf2:"", Marcod1:"", Marcof1:"", Marcod2:"", Marcof2:"",
          Repd1:"", Repf1:"", Repd2:"", Repf2:"", Entretiend1:"", Entretienf1:"", Entretiend2:"", Entretienf2:"", Fecondationd1:"", Fecondationf1:"", Fecondationd2:"", Fecondationf2:"",
          Recolted1:"", Recoltef1:"", Recolted2:"", Recoltef2:"",

          date:{
            prepaSold1:"", prepaSold2:"", prepaSolf1:"", prepaSolf2:"", semisd1:"", semisf1:"", semisd2:"", semisf2:"", marcod1:"", marcof1:"", marcod2:"", marcof2:"",
          repd1:"", repf1:"", repd2:"", repf2:"", entretiend1:"", entretienf1:"", entretiend2:"", entretienf2:"", fecondationd1:"", fecondationf1:"", fecondationd2:"", fecondationf2:"",
          recolted1:"", recoltef1:"", recolted2:"", recoltef2:"",
          }
    }
  },
  mounted() {
    this.getCalendriers();
  },

  methods: {
    updateColor(event) {
      // Mettez à jour la variable selectedColor à chaque changement de couleur
      this.couleur = event.target.value;
    },

    getCalendriers(){
            axios.get(apiUrl + 'api/affichage_calendrier').then(response => {
              this.calendriers = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.calendriers=[]
            })
      },

    init2(){
      this.nomPro='', this.zone='', this.typeCult='', this.observation=''
    },

    init3(){
      this.PrepaSold1="", this.PrepaSolf1="", this.PrepaSold2="", this.PrepaSolf2="", this.Semisd1="", this.Semisf1="", this.Semisd2="", this.Semisf2="", 
      this.Marcod1="", this.Marcof1="", this.Marcod2="", this.Marcof2="", this.Repd1="", this.Repf1="", this.Repd2="", this.Repf2="", 
      this.Entretiend1="", this.Entretienf1="", this.Entretiend2="", this.Entretienf2="", this.Fecondationd1="", this.Fecondationf1="", this.Fecondationd2="", this.Fecondationf2="", 
      this.Recolted1="", this.Recoltef1="", this.Recolted2="", this.Recoltef2=""
    },

    ajoutCalendrier(){
            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_calendrier', this.calendrier).then(response => { 
              const idcal1 = response.data.idcal;
              //console.log(idelevage1);
              this.idcal2= idcal1;
              
                this.getCalendriers();
                this.calendrier.nomPro= ""; this.calendrier.zone=""; this.calendrier.typeCult=""; this.calendrier.observation=""
                this.closeModalAjout()
              
            })
            .catch(error => {(
              console.log(error)
            )});
            
          },

        ajoutDate(){           
            const formData = new FormData();
            
            formData.append('idcal2', this.idcal2);
            formData.append('prepaSold1', this.prepaSold1); formData.append('prepaSolf1', this.prepaSolf1);
            formData.append('prepaSold2', this.prepaSold2); formData.append('prepaSolf2', this.prepaSolf2);
            formData.append('semisd1', this.semisd1); formData.append('semisf1', this.semisf1);
            formData.append('semisd2', this.semisd2); formData.append('semisf2', this.semisf2);
            formData.append('marcod1', this.marcod1); formData.append('marcof1', this.marcof1);
            formData.append('marcod2', this.marcod2); formData.append('marcof2', this.marcof2);
            formData.append('repd1', this.repd1); formData.append('repf1', this.repf1);
            formData.append('repd2', this.repd2); formData.append('repf2', this.repf2);
            formData.append('entretiend1', this.entretiend1); formData.append('entretienf1', this.entretienf1);
            formData.append('entretiend2', this.entretiend2); formData.append('entretienf2', this.entretienf2);
            formData.append('fecondationd1', this.fecondationd1); formData.append('fecondationf1', this.fecondationf1);
            formData.append('fecondationd2', this.fecondationd2); formData.append('fecondationf2', this.fecondationf2);
            formData.append('recolted1', this.recolted1); formData.append('recoltef1', this.recoltef1);
            formData.append('recolted2', this.recolted2); formData.append('recoltef2', this.recoltef2);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_date', formData).then(response => {
              if(confirm('date ajouté avec succès')){
                this.prepaSold1='', this.prepaSold2='', this.prepaSolf1='', this.prepaSolf2=''
              }
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    suppressionCalendrier(){
            axios.post(apiUrl + 'api/suppression_calendrier', {idcal:this.idcal1
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
                  this.closeModalSuppression();
                  this.getCalendriers();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    suppressionDate(){
            axios.post(apiUrl + 'api/suppression_date', {idcal:this.idcal1
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
              
              }).catch(error => {(
                console.log(error)
              )});
    },

    modificationCalendrier(){
            axios.post(apiUrl + 'api/modification_calendrier', {idcal:this.idcal, nomPro:this.nomPro, zone:this.zone,typeCult:this.typeCult,observation:this.observation
                          // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getCalendriers();
                  this.init2();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    detail_date(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_date',{params:{idcal: this.idcal}}).then(response => {
        this.PrepaSold1 = response.data[0].prepaSold1;
        this.PrepaSolf1 = response.data[0].prepaSolf1;
        this.PrepaSold2 = response.data[0].prepaSold2;
        this.PrepaSolf2 = response.data[0].prepaSolf2;

        this.Semisd1 = response.data[0].semisd1;
        this.Semisf1 = response.data[0].semisf1;
        this.Semisd2 = response.data[0].semisd2; 
        this.Semisf2 = response.data[0].semisf2;

        this.Marcod1 = response.data[0].marcod1; 
        this.Marcof1 = response.data[0].marcof1;
        this.Marcod2 = response.data[0].marcod2; 
        this.Marcof2 = response.data[0].marcof2;

        this.Repd1 = response.data[0].repd1; 
        this.Repf1 = response.data[0].repf1;
        this.Repd2 = response.data[0].repd2; 
        this.Repf2 = response.data[0].repf2;

        this.Entretiend1 = response.data[0].entretiend1; 
        this.Entretienf1 = response.data[0].entretienf1;
        this.Entretiend2 = response.data[0].entretiend2; 
        this.Entretienf2 = response.data[0].entretienf2;

        this.Fecondationd1 = response.data[0].fecondationd1; 
        this.Fecondationf1 = response.data[0].fecondationf1;
        this.Fecondationd2 = response.data[0].fecondationd2; 
        this.Fecondationf2 = response.data[0].fecondationf2;

        this.Recolted1 = response.data[0].recolted1; 
        this.Recoltef1 = response.data[0].recoltef1;
        this.Recolted2 = response.data[0].recolted2; 
        this.Recoltef2 = response.data[0].recolted2;
        
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    modificationDate(){
      axios.post(apiUrl + 'api/modification_date', {idcal:this.idcal, prepaSold1:this.PrepaSold1,prepaSolf1:this.PrepaSolf1,prepaSold2:this.PrepaSold2,prepaSolf2:this.PrepaSolf2,
        semisd1:this.Semisd1,semisf1:this.Semisf1,semisd2:this.Semisd2,semisf2:this.Semisf2, marcod1:this.Marcod1,marcof1:this.Marcof1,marcod2:this.Marcod2,marcof2:this.Marcof2,
        repd1:this.Repd1,repf1:this.Repf1,repd2:this.Repd2,repf2:this.Repf2, entretiend1:this.Entretiend1,entretienf1:this.Entretienf1,entretiend2:this.Entretiend2,entretienf2:this.Entretienf2,
        fecondationd1:this.Fecondationd1,fecondationf1:this.Fecondationf1,fecondationd2:this.Fecondationd2,fecondationf2:this.Fecondationf2, recolted1:this.Recolted1,recoltef1:this.Recoltef1,recolted2:this.Recolted2,recoltef2:this.Recoltef2
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                if(confirm('Date modifié avec succès')){
                  
                  this.getCalendriers();
                  this.init3(); this.init2();
                }
              }).catch(error => {(
                console.log(error)
              )});
    }

  },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../components/styleAdmin/dash1.css';
  
</style>

<head>
  <script src="/docs/5.3/assets/js/color-modes.js"></script>
  <link rel="canonical" href="https://getbootstrap.com/docs/5.3/examples/dashboard/">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@docsearch/css@3">

  <link href="/docs/5.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <!-- Favicons -->
  <link rel="apple-touch-icon" href="/docs/5.3/assets/img/favicons/apple-touch-icon.png" sizes="180x180">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-32x32.png" sizes="32x32" type="image/png">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-16x16.png" sizes="16x16" type="image/png">
  <link rel="manifest" href="/docs/5.3/assets/img/favicons/manifest.json">
  <link rel="mask-icon" href="/docs/5.3/assets/img/favicons/safari-pinned-tab.svg" color="#712cf9">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon.ico">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css" rel="stylesheet">
</head>


