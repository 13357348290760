<!-- eslint-disable vue/no-parsing-error -->
<template>

    <div class="w-screen">
        <br><br><br>
        <div class="">
            <div
                class="flex max-md:flex-col max-md:items-start max-md:justify-center md:justify-between md:items-center max-md:gap-y-4 gap-x-2 max-md:w-full mx-auto max-md:px-4  md:w-11/12">
                <div class="flex justify-start items-center gap-x-3  ">
                    <div class="flex justify-start items-center gap-x-2  w-full">

                        <div class="fa fa-home  cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                        <div class="fas fa-caret-right  px-1 text-gray-500"></div>
                        <div class="w-fit"><span>Marché</span></div>
                        <div class="fas fa-caret-right  px-1 text-gray-500"></div>

                        <div class="w-fit"><span @click="$router.push('/Client/Client')"
                                class="cursor-pointer">Client</span></div>
                    </div>
                </div>

                <div class="flex  gap-x-2 max-md:flex-col gap-y-3  md:justify-end md:items-center max-md:justify-center max-md:items-end">
                    <div class="flex border w-full  md:justify-end items-center">

                        <div class="flex  justify-end items-center w-full">
                            <div class="border-r "><input type="number"
                                    class="p-1 w-full focus:outline-none placeholder:text-sm"
                                    placeholder="Prix entre ..." v-model="inpPrix1"></div>
                            <div class="border-r "><input type="number"
                                    class="p-1 w-full focus:outline-none  placeholder:text-sm" placeholder="Et ..."
                                    name="" id="" v-model="inpPrix2"></div>

                        </div>
                        <div>
                            <select name="" class="border-r w-full p-1 focus:outline-none bg-white placeholder:text-sm"
                                placeholder="Categorie ..." id="" v-model="inpCat">
                                <option v-for="item in categories" :key="item.nomcat" class="" :value="item.nomcat">
                                    {{ item.nomcat }}</option>
                            </select>
                        </div>

                    </div>
                    <div class=" flex items-center justify-end  gap-x-2">
                        
                            <div class="flex border max-md:w-full md:w-28">
                                <input type="text " name="" id="" v-model="inpProduit" placeholder="Recherche ..."
                                    class="w-full  p-1 focus:outline-none  placeholder:text-sm">
                            </div>
                        
                        <div class="flex justify-end items-center">
                            <div class="py-1 px-3 text-white  bg-green-600 hover:bg-green-500   placeholder:text-sm overflow-hidden focus:outline-none cursor-pointer"
                                @click="rechercheClient">Recherche</div>
                        </div>
                        <div class="flex justify-end items-center">

                            <div class="" v-if="content_mode">
                                <button @click="change_content_mode(false)"
                                    class="fas fa-table-list fa-2x text-gray-600"></button>
                            </div>
                            <div class="" v-else>
                                <button @click="change_content_mode(true)"
                                    class="fas fa-table-cells fa-2x text-gray-600"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br><br>
        <div class="w-screen bg-slate-50 flex justify-center items-center">
            <div class="w-11/12" v-if="content_mode">
                <div class="grid max-sm:grid-cols-none  items-start  md:grid-cols-3 lg:grid-cols-4 gap-4  ">
                    <div v-for="client in clients" :key="client.idcli"
                        class="flex flex-col bg-white justify-center items-cennter shadow-sm shadow-slate-300"
                        @click=" nom = client.nom, contact = client.contact, email = client.email,
                            adresse = client.adresse, type = client.type, prodDemande = client.prodDemande, prixUnitaire = client.prixUnitaire, statut = client.statut, quantite = client.quantite, nomcat = client.nomcat">

                        <div class=" w-full flex justify-between items-end p-3">
                            <div class="w-fit flex flex-col items-start justify-center">
                                <div class="flex justify-start items-center w-full text-left">
                                    <div class="text-left m-2">
                                        <h5 class="font-semibold">{{ client.nom }} </h5>
                                    </div>
                                </div>
                                <div class="m-2">
                                    <div>
                                        <p class=" text-gray-600 text-left">
                                            Contact: <span class="text-gray-500">{{ client.contact }}</span> <br>
                                            Email: <span class="text-gray-500">{{ client.email }}</span> <br>
                                            Adresse: <span class="text-gray-500">{{ client.adresse }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-end  items-end ">
                                <div class="">
                                    <button type="button" @click="modal_detail_show()"
                                        class="px-2 py-1  bg-slate-400  border-slate-400 hover:bg-slate-500  hover:border-gray-500 text-white ">Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" w-11/12 overflow-x-auto" v-else>
                <table class="w-full max-w-full bg-white">
                    <thead>
                        <th style="display: none;">Id</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Nom</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Contact</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 "
                            style="display: none;">Email</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 "
                            style="display: none;">Adresse</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Statut</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Produit demandée</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Prix unitaire</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Quantité</th>
                        <th class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 "
                            style="display: none;">Nomcat</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                        </th>
                    </thead>
                    <tbody class="text-gray-600 max-sm:text-sm">

                        <tr v-for="client in clients" :key="client.idcli"
                            class="hover:bg-gray-100 cursor-pointer text-sm text-left"
                            @click="modal_detail_show(), nom = client.nom, contact = client.contact, email = client.email,
                            adresse = client.adresse, statut = client.statut, prodDemande = client.prodDemande, prixUnitaire = client.prixUnitaire, quantite = client.quantite, nomcat = client.nomcat">
                            <td style="display: none;">{{ client.idcli }}</td>

                            <td class="px-3 py-2 border-b border-gray-200">{{ client.nom }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ client.contact }}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{ client.email }}
                            </td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{ client.adresse }}
                            </td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ client.statut }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ client.prodDemande }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ client.prixUnitaire }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ client.quantite }}</td>
                            <td class="px-3 py-2 border-b border-gray-200" style="display: none;">{{ client.nomcat }}
                            </td>
                            <td class="px-3 py-2 border-b border-gray-200">
                                <div class="fas fa-eye"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <Footer></Footer>
    </div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile" class="z-[4]">
    </Header2>

    <div class="bg-black bg-opacity-50 modal_ajout flex justify-center items-center w-screen h-screen"
        v-show="modal_detail">
        <div class="box-shadow bg-white w-fit h-fit p-8">
            <div class="flex justify-end items-center">
                <div><button class="fas fa-times fa-2x" @click="modal_detail = false, modal_back = false"></button>
                </div>
            </div>
            <h5 class="font-bold text-xl text-left">{{ nom }}</h5>
            <hr>
            <br>
            <p class="">
            <div class="flex justify-start items-start text-left gap-12">
                <div class="flex flex-col gap-y-2">
                    <div><span class="">Contact:</span> <span class="text-gray-500">{{ contact }}</span> </div>
                    <div><span class="">Email: </span> <span class="text-gray-500">{{ email }}</span> </div>
                    <div><span class="">adresse:</span> <span class="text-gray-500">{{ adresse }}</span> </div>
                    <div><span class="">Statut :</span> <span class="text-gray-500"> {{ statut }}</span> </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div><span class="">Produit Disponible:</span> <span class="text-gray-500">{{ prodDemande }}</span>
                    </div>
                    <div><span class="">Prix Unitaire :</span> <span class="text-gray-500">{{ prixUnitaire }}</span>
                        <span class="text-gray-500">Ar</span> </div>
                    <div><span class="">Quantité :</span> <span class="text-gray-500">{{ quantite }}</span> </div>
                    <div><span class="">Nom du catégorie :</span> <span class="text-gray-500"> {{ nomcat }}</span>
                    </div>
                </div>
            </div>
            </p>
        </div>
    </div>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal_ajout"
        v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn rounded bg-gray-400 text-white p-1"
                            @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn rounded bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile
    <div  class=" modal_ajout flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class=" rounded fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x m-2" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>-->
</template>
<script>
import apiUrl from '../../apiConfig';
// import { RouterLink } from 'vue-router'
import '@/components/style/marche.css'
import '@/components/style/all.css'
import '@/components/style/header.css'
import Header2 from '@/components/Header2.vue';
import Footer from '@/components/Footer.vue';
//import Triage from '@/components/Triage.vue';
import Pagination from '@/components/Pagination.vue';

import '@/assets/tailwind.css'
import axios from 'axios';

export default {
    name: "Client",
    components: {
        Header2,
        Footer,
        // eslint-disable-next-line vue/no-unused-components
        Pagination
    },
    data() {
        return {
            categories: [],
            inpCat: '', inpPrix1: '', inpPrix2: '', inpProduit: '',

            nom: '', contact: '', email: '', adresse: '', type: '', prodDemande: '', prixUnitaire: '', quantite: '', nomcat: '',
            client: {
                nom: "", contact: "", email: "", adresse: "", type: "", prodDemande: "", prixUnitaire: "", quantite: "", nomcat: ""
            },

            content_mode: Boolean,
            modal_detail: false,
            modal_back: false,
            sections: [
                { id: 'Service', title: 'Service', isActive: false },
                { id: 'Propos', title: 'Propos', isActive: false },
                { id: 'Pricing', title: 'Pricing', isActive: false },
                { id: 'Contact', title: 'Contact', isActive: false },

                // Add more sections as needed
            ], menu_mobile2: false,
            modal_deconnection: false, items: {},
            clients: []
        }
    },

    mounted() {
        this.d();
        console.log(localStorage.getItem('content_mode'));

        this.getClients();
        this.categorieSelection();
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    beforeDestroy() {
        localStorage.setItem('content_mode', String(this.content_mode))
    },


    methods: {

        categorieSelection() {
            axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                this.categories = response.data
            }).catch(error => {
                console.log(error)

            })
        },

        rechercheClient() {
            axios.get(apiUrl + 'api/recherche_client', { params: { inpProduit: this.inpProduit, inpPrix1: this.inpPrix1, inpPrix2: this.inpPrix2, inpCat: this.inpCat } }).then(response => {
                this.clients = response.data
            }).catch(error => {
                console.log(error)
            })
        },

        d() {
            this.content_mode = JSON.parse(localStorage.getItem('content_mode'))

        },
        change_content_mode: function (data) {
            this.content_mode = data
            localStorage.setItem('content_mode', String(this.content_mode))
        },

        // eslint-disable-next-line no-unused-vars
        modal_detail_show: function () {

            if (this.modal_detail) {
                this.modal_detail = false
                this.modal_back = false
            } else {
                this.modal_detail = true
                this.modal_back = true
            }





        },
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        },

        getClients() {
            axios.get(apiUrl + 'api/affichage_client').then(response => {
                this.clients = response.data
                // eslint-disable-next-line no-undef

            }).catch(error => {
                console.log(error)
                this.clients = []
            })
        },


    }
}
</script>