<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div>
        <input v-model="$route.params.iduser" class="hidden">
        <div>
            <div class=" ">
		<div class="w-screen flex flex-col justify-center items-center max-md:px-4 md:px-16 max-md:overflow-x-scroll ">
            <br>
            <div class="w-full flex justify-start items-center">
                <div><h5 class='font-semibold'>Choix d'abonnement</h5></div>
            </div>
			<div class="max-w-full min-w-full overflow-x-auto mt-10">
				<table class="w-full">
				<thead>
					<tr>
						<th></th>
						<th scope="col " class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Basic</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">20.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
						<th scope="col" class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Standard</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">40.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
						<th scope="col" class="max-md:px-5">
							<h2 class="px-2 text-lg font-medium">Premium</h2>
							<p class="mb-3">
								<span class="max-sm:text-sm sm:text-4xl text-gray-900">80.000Ar</span>
								<span class="font-medium text-gray-600">/mois</span>
							</p>
						</th>
					</tr>
				</thead>
				<tbody class="space-y-6 text-center divide-y divide-gray-300">
					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Clients</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Fournisseurs</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Acteurs</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Free plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>

						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Produit d'élevage</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>

					<tr>

					<th scope="row" class="text-left">
						<p class="py-3 max-sm:text-sm text-gray-600">Produit d'agriculture</p>
					</th>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
							<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
						</svg>
					</td>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Standard plan" class="w-5 h-5 mx-auto text-blue-600">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
						</svg>
					</td>
					<td>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
						</svg>
					</td>
					</tr>



					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Calendrier agricole</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Standard plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
					<tr>
						<th scope="row" class="text-left">
							<p class="py-3 max-sm:text-sm text-gray-600">Courbe</p>
						</th>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Free plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Not included in Standard plan" class="w-5 h-5 mx-auto text-gray-400">
								<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
							</svg>
						</td>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-label="Included in Premium plan" class="w-5 h-5 mx-auto text-blue-600">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
							</svg>
						</td>
					</tr>
                    <tr>
                        <td></td>
                        <td><button class="choix1 m-2 bg-blue-400 hover:bg-blue-500 text-white py-1 px-4" @click="choixAbonnement('basic','10000 '),$router.push('/Client/Paiement/' + $route.params.iduser)">Choisir</button></td>
                        <td><button class="choix2 m-2 bg-blue-400 hover:bg-blue-500 text-white py-1 px-4" @click="choixAbonnement('standard','40000 '),$router.push('/Client/Paiement/' + $route.params.iduser)">Choisir</button></td>
                        <td><button class="choix3 m-2 bg-blue-400 hover:bg-blue-500 text-white py-1 px-4" @click="choixAbonnement('premium','100000 '),$router.push('/Client/Paiement/'+ $route.params.iduser)">Choisir</button></td>
                    </tr>
				</tbody>
			</table>
			</div>
		</div>
	</div>
            
        </div>
    </div>

</template>
<script>
//import { RouterLink } from 'vue-router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import '@/components/style/all.css'
import '@/components/style/header.css'
import '@/components/style/abonne.css'
//import $ from 'jquery'
    export default {
        name:'Abonnement',
        components:{
            // eslint-disable-next-line vue/no-unused-components
            Header,
						// eslint-disable-next-line vue/no-unused-components
            Footer
        },
        data(){
            return{ 
            
            
            
            modal_pricing_1:false,
            modal_pricing_2:false,
            modal_pricing_3:false,
                modal_connection:false,
                menu_mobile:false
                ,sections: [
          { id: 'Service', title: 'Service', isActive: false ,link:'/'},
          { id: 'Propos', title: 'Propos', isActive: false ,link:'/'},
          { id: 'Pricing', title: 'Pricing', isActive: false ,link:'/'},
          { id: 'Contact', title: 'Contact', isActive: false ,link:'/'},
          // Add more sections as needed
        ],

        abonnement:null,
        prix: null,

            }
        },mounted(){
        },
        methods:{
            choixAbonnement(data , prix){
                this.abonnement = data;
                this.prix = prix;
                localStorage.setItem("myAbonnement",this.abonnement);
                localStorage.setItem("myPrix",this.prix);
            },
            modal_connection_show:function(){
                if(this.modal_connection){
                    this.modal_connection=false
                    this.modal_back=false
                    
                } else {
                    this.modal_connection=true
                    this.modal_back=true
                }
            },
    data_modal_connection:function(props){
      this.modal_connection=props
    },
    data_menu_mobile:function(props){
      this.menu_mobile=props
    }   
            }
    }
</script>