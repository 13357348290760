<template>
    <hr>
    <div class="bg-white h-fit mx-4  items-center justify-around rounded p-4 flex">
            <div class="flex max-sm:flex-col justify-start items-center gap-4">
                <div class="flex gap-9">
                    <div class="text-center"><label for="">Prix</label></div>
                    <div class="flex max-sm:flex-col gap-2">
                        <div><input type="number" class="border rounded p-1" v-model="inpPrix1"></div>
                        <div><input type="number"  class="border rounded p-1" name="" id="" v-model="inpPrix2"></div>
                    </div>
                </div>
                <div class="flex justify-start items-center gap-4">
                    <div class="flex justify-start"><label for="">Categorie</label></div>
                    <div class="flex justify-start">
                       <select name="" class="p-1 rounded border" id="" v-model="inpCat">
                        <option v-for="item in Categories" :key="item.id"  class="border rounded " :value="item.nom">{{ item.nom }}</option>
                       </select>
                    </div>
                </div>
                <div class="flex gap-4 justify-start items-center">
                    <div><label for="">Produit</label></div>
                    <div class="flex">
                        <input type="text" name="" id="" v-model="inpProduit" class="border rounded p-1">
                    </div>
                </div>
                <div class="flex  border-slate-200 w-[80px] h-[40px] justify-center items-center  border-2 rounded overflow-hidden">
                    <div class="fas fa-xmark fa-2x p-1 hover:bg-gray-100 active:bg-gray-300 w-full h-full overflow-hidden"></div>
                    <div class="fas fa-search  fa-2x p-1 w-full h-full  hover:bg-gray-100 active:bg-gray-300 overflow-hidden"></div>
                </div>
            </div>
        </div>

    <hr>
</template>
<script>

import '@/components/style/all.css'
    export default {
        name: "Triage",
        data(){
            return {

            }
        }
    }
</script>