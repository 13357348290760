<template>
    
<div>
    <br><br><br><br>
    <div class=" flex">
        <div class="flex max-sm:w-full w-screen justify-end items-center mx-5 gap-4">
            <div class="flex border w-fit ">
                <div class="flex items-center justify-center">
                    <!-- <div class="border-r"><label for="" class="px-1">Prix</label></div> -->
                    <div class="border-r"><input type="number" class="p-1 w-full focus:outline-none placeholder:text-sm" placeholder="Prix entre ..." v-model="inpPrix1"></div>
                    <div class="border-r"><input type="number"  class="p-1 w-full focus:outline-none  placeholder:text-sm" placeholder="Et ..." name="" id="" v-model="inpPrix2"></div>
                </div>
                
                <div>
                    <div class="flex border-r ">
                        <input type="text " name="" id="" v-model="inpProduit" placeholder="Recherche ..." class="w-full  p-1 focus:outline-none  placeholder:text-sm">
                    </div>
                </div>
            </div>
            <div>
                <div class="py-1 px-4 text-white w-full h-full bg-blue-400 hover:bg-blue-500   placeholder:text-sm overflow-hidden focus:outline-none" @click="rechercheClient">Recherche</div>
            </div>
            
        </div>
        </div>
        <br><br>
    <table class="table table-hover table-tripped table-unbordered">
                <thead>
                    <th>Nom</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Adresse</th>
                    <th>Antananarivo</th>
                    <th>type</th>
                    <th>type</th>
                    <th>produit dispo</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr @click="modal_detail_show()">
                        <td>NOTEO</td>
                        <td>+261 34 43 345 34</td>
                        <td>Noteomada@gmail.com</td>
                        <td>Lot 234 Mahazoarivo Antananarivo</td>
                        <td>Entreprise</td>
                        <td>oeuf</td>
                        <td>800 Ar</td>
                        <td>28bJanvier 2023</td>
                        <td>Elevage</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
    <Pagination></Pagination>
    <Footer></Footer>
</div>
    <Header2 @modal_deconnection_show="data_modal_deconnnection"  @menu_mobile_show="data_menu_mobile"></Header2>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center" >
            <div class="flex justify-end items-center">  
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div>
                <div>
                    <h5>deconnnection</h5>
                </div>
                <div>
                    <form class="form form-check">
                        <button @click.prevent="" class="btn rounded bg-blue-600 text-white p-1"><Router-Link to="/Client/Accueil2">Se connecter</Router-Link></button><br>
                        <span>Vous n'avez pas de compte?</span><RouterLink to="/Client/Inscription"><span class="text-blue-400">s'inscrire</span></RouterLink>
                    </form>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!--modal menu mobile-->
    <div  class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class=" rounded fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/ProduitAgricole"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>
</template>
<script>
import { RouterLink } from 'vue-router'
import Footer from '@/components/Footer.vue'
import Header2 from '@/components/Header2.vue'
import Triage from '@/components/Triage.vue'
import Pagination from '@/components/Pagination.vue'

    export default {
        name:'Prix',
        components:{
            Footer,
            Header2,
            Triage,
            Pagination
        },
        data(){
            return{
                sections: [
          { id: 'Service', title: 'Service', isActive: false },
          { id: 'Propos', title: 'Propos', isActive: false },
          { id: 'Pricing', title: 'Pricing', isActive: false },
          { id: 'Contact', title: 'Contact', isActive: false },
          // Add more sections as needed
        ],menu_mobile2:false,
        modal_deconnection:false
            }
        },methods:{
            data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data
            }
        }
    }
</script>