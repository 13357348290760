<template >
    <div class="max-w-screen min-w-screen flex flex-col items-center max-lg:px-4" >
        <br>
        <input v-model="$route.params.iduser" class="hidden">
        <input v-model="abonnement" class="hidden">
        <input v-model="prix" class="hidden">
        <div class="w-full ">
                <div class="w-full  lg:mx-20">
                    <h4 class="text-left font-semibold text-2xl border-b">Mode de paiement</h4>
                    
                </div>
                <div class="w-full text-left flex flex-col justify-start items-start">
                    <div class="lg:mx-20 ">
                        <h5 class="font-semibold ">Choix de paiement</h5>
                        <p class="text-gray-600">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident a impedit cum eaque autem, optio architecto ex</p>
                    </div>
                    <div class="w-full lg:mx-20 flex justify-center items-center">
                        <div class="md:grid md:grid-cols-3 justify-center items-center gap-x-4">
                            <div class="flex flex-col">
                                <div class="">
                                    <div><img src="./../../assets/telma-cust-logo.jpg" class="max-md:w-3/12 md:w-1/12" alt=""></div>
                                    <div><span class="text-gray-600">Randrianasolo Jean Eugene</span></div>
                                </div>
                                <div class="text-left">
                                        <p class="text-gray-600">
                                            034 43 103 34 
                                        </p>
                                    
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <div class="">
                                    <div><img src="./../../assets/Orange-Logo.png" class="max-md:w-3/12 md:w-1/12" alt=""></div>
                                    <div><span class="text-gray-600">Randrianasolo Jean Eugene</span></div>
                                </div>
                                <div class="text-left">
                                    <p class="text-gray-600">
                                        032 43 103 34 
                                        </p>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <div class="">
                                    <div><img src="./../../assets/airtel-logo.jpg" class="max-md:w-3/12 md:w-1/12" alt=""></div>
                                    <div><span class="text-gray-600">Randrianasolo Jean Eugene</span></div>
                                </div>
                                <div class="text-left">
                                    <p class="text-gray-600">
                                            033 43 103 34 
                                            
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex flex-col justify-center items-start   py-4">
                        <form @submit.prevent="ajoutAbonnement" class="w-full">
                            <input v-model="$route.params.iduser" class="hidden">
                            
                            <div class="w-full lg:px-20">
                                <div class="w-full flex flex-col   gap-y-3">
                                    <div>
                                        <div>
                                            <h5 class="font-semibold ">
                                                Formulaire de paiement
                                            </h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label for="Objet" class="text-gray-500">Objet</label></div>
                                        <div class="border"><input type="text" class="w-full py-1 focus:outline-none" value="Abonnement" disabled></div>
                                    </div>
                                    <div class="flex gap-y-3 max-md:flex-col justify-start items-center gap-x-4 w-full">
                                        <div class="min-md:w-1/2 w-full">
                                            <div><label for="" class="text-gray-500">Abonnement choisi</label></div>
                                            <div  class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="abonnement" disabled></div>
                                        </div>
                                        <div class="min-md:w-1/2 w-full">
                                            <div><label for="" class="text-gray-500">Prix</label></div>
                                            <div  class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="prix" disabled></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div><label for="" class="text-gray-500">Description</label></div>
                                        <div class="border">
                                            <textarea name="" id=""  rows="5" class="w-full  focus:outline-none" v-model="description">

                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="flex justify-start items-center">
                                        <div class="w-fit bg-slate-700 p-1 flex">
                                            <div class="py-1 px-4 cursor-pointer bg-white inp1" @click="showing(1)" >Reference</div>
                                            <div class="py-1 px-4 text-white  cursor-pointer inp2 bg-slate-600" @click="showing(2)" >Piece joint</div>
                                        </div>
                                    </div>
                                    <div v-show="choice==false">
                                        <div><label for="" class="text-gray-500">Reference <span class="text-red-600">*</span></label></div>
                                        <div class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="refText"></div>
                                    </div>
                                    
                                    <div>
                                    <div v-show="choice==true" class="mb-3 p-1">
                                        <div><label for="" class="text-gray-500">Piece joint <span class="text-red-600">*</span></label></div>
                                        <div class="border"><input type="file" class="w-full py-1  focus:outline-none" @change="handleFileChange"></div>
                                    </div>
                                    <div  v-show="verificationRef">
                                        <p class="text-red-500">Veuillez remplir l'un de formulaire de réference soit par texte ou soit par image ou les deux.</p>
                                    </div>
                                    <div class="flex max-md:justify-center md:justify-end max-md:w-screen  items-center gap-x-4">
                                        <div class="max-md:5/12"><button class="px-9 py-1 max-md:w-full border-2 border-gray-600  text-gray-600" @click.prevent="$router.go(-1)">Annuler</button></div>
                                        <div class="max-md:5/12"><button type="submit" class=" max-md:w-full px-9 py-1 bg-blue-400 border-2 border-blue-400 hover:border-blue-500 hover:bg-blue-500 text-white" >Envoyer</button></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
        </div>
    </div>


  
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="ref">
        <div class="bg-white p-4">
            <div class="w-full flex justify-start items-center">
                <div>
                    <p>Vous avez remplir l'un de formulaire de réference.</p>
            </div>
        </div>
        
            <div class="w-full flex items-center justify-end">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white" @click="ref=false">OK</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center " v-show="modalValidation">
        <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="$router.push('/')"></div>
            </div>
            <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2">
            </div>
            <div class="w-9/12">
                <p class="text-gray-500">Votre abonnement est envoyé, veuillez attendre pour le valider.
                        Vous reçevrez de l'email si l'abonnement est effectué ou non. Merci !
                </p>
            </div>
            <div>
                <div @click="generer_pdf(),$router.push('/')" class="text-white px-4 py-1 bg-blue-400 hover:bg-blue-500 flex gap-x-4 justify-center items-center  cursor-pointer"><div class="fas fa-download"></div><div>Telecharger le facture</div></div>
            </div>
        </div>
       
    </div>
</template>
<script>
import Header2 from '@/components/Header.vue';
import axios from 'axios'
import jsPDF from 'jspdf';
import apiUrl from '../../apiConfig';
import Footer from '@/components/Footer.vue';
import $ from 'jquery'
export default {
    components:{
        // eslint-disable-next-line vue/no-unused-components
        Header2,Footer
    },
    data(){
        return{

            abonnement:'', prix:'', description:'', refText:'', refImage:'',
            choice:false,
            numero:null,
            operateur:null,
            verificationRef:false,
            ref:false,
            modalValidation:false,
            modal_num:false,
            credit:0,
            modal_verification:false,
            nomUser: '', adresseUser:'', descriptionAbo:''
            
        }
    },
    mounted(){
        this.getAbo();
        this.getUserName(); this.getUserAdresse();
    },
    methods:{
        generer_pdf:function(){
                const pdf=new jsPDF('p', 'mm', [210, 297]);
                //const img = require('@/assets/logoAgri.jpg')
                let yPos = 20; // Position verticale de départ
                const margin = 10; // Marge supérieure
                yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
                const pageHeight = pdf.internal.pageSize.height;
                if (yPos + 10 > pageHeight) {
                    pdf.addPage();
                    yPos = margin;
                }
                // pdf.addImage(img,'PNG',10,0,15,25)
                const date=new Date()

                pdf.setFont('Calibri','normal')
                pdf.setFontSize(12)

                pdf.text('AgiBusiness',195,20,'right')
                pdf.setFont('Calibri','normal')
                pdf.text('Lot VK 17 à Fenomanana Mahazoarivo Antananarivo',195,30,'right')
                pdf.text('Madagascar',195,40,'right')
                pdf.text('Facture d\'abonnement',10,50,'left')
                pdf.text('Date de facturation :',10,60,'left')
                pdf.text(date.toLocaleDateString(),60,60,'left');
                const echeanceDate=new Date(date)
                echeanceDate.setDate(date.getDate() + 31)
                pdf.text('Date d\'echeance :',10,70,'left')
                pdf.text(echeanceDate.toLocaleDateString(),60,70,'left');
                pdf.text('Facture à',10,85,'left')
                pdf.text(this.nomUser,10,95,'left')
                pdf.text(this.adresseUser,10,105,'left')
                pdf.text('Madagascar',10,115,'left')
                pdf.text(this.description,10,125)
                const startY=135
                 pdf.autoTable({
                     head: [['Description', 'Total']],
                     body: [
                          ['Abonnement Agribusiness ',this.prix + 'AR'],
                          [{ 
                              content: 'Sous-total',
                              styles: { halign: 'right' 

                          } },this.prix],
                        //   [{ 
                        //       content: 'Crédit',
                        //       styles: { halign: 'right' } 
                        //   }, this.credit],
                          [{ 
                              content: 'Total',
                              styles: { halign: 'right' } 
                          }, this.prix],

                         ],
                         startY: startY ,
                     });
                    
                pdf.output('dataurlnewwindow')
            },
        showing(data){
            const inp1=$('.inp1')
            const inp2=$('.inp2')
            if(data==1){
                this.choice=false
                if(inp1.hasClass('bg-slate-600')){
                    inp1.removeClass('bg-slate-600')
                }
                if(inp1.hasClass('text-white')){
                    inp1.removeClass('text-white')
                }
                inp2.addClass('text-white')
                inp1.addClass('bg-white')
                if(inp2.hasClass('bg-white')){
                    inp2.removeClass('bg-white')
                }
                inp2.addClass('bg-slate-600')
            } else {
                this.choice=true
                if(inp2.hasClass('bg-slate-600')){
                    inp2.removeClass('bg-slate-600')
                }
                if(inp2.hasClass('text-white')){
                    inp2.removeClass('text-white')
                }
                inp1.addClass('text-white')
                inp2.addClass('bg-white')
                if(inp1.hasClass('bg-white')){
                    inp1.removeClass('bg-white')
                }
                inp1.addClass('bg-slate-600')
            }
        },
        handleFileChange(event) {
            this.refImage = event.target.files[0];
        },

        ajoutAbonnement(){

            if(this.refText === '' && this.refImage === '')
            {
                this.verificationRef = true;
            }

            else
            {
                        // eslint-disable-next-line no-unused-vars
                    const formData = new FormData();
                    formData.append('iduser', this.$route.params.iduser);
                    formData.append('type', this.abonnement);
                    formData.append('montant', this.prix);
                    formData.append('description', this.description);
                    formData.append('refText', this.refText);
                    formData.append('refImage', this.refImage);

                    // eslint-disable-next-line no-unused-vars
                    axios.post(apiUrl + 'api/ajout_abonnement', formData).then(() => { 
                    
                        this.modalValidation = true;
                    
                    })
                    .catch(error => {(
                    console.log(error)
                    )});

            }

            

        },

        getUserName() {
            axios.get(apiUrl + 'api/user_name', { params: { iduser: this.$route.params.iduser } }).then(response => {
                this.nomUser = response.data[0].name;

            }).catch(error => {
                console.log(error)
            })
        },

        getUserAdresse() {
            axios.get(apiUrl + 'api/user_adresse', { params: { iduser: this.$route.params.iduser } }).then(response => {
                this.adresseUser = response.data[0].adresse;

            }).catch(error => {
                console.log(error)
            })
        },


        redirect(){
            console.log(this.operateur)
            if(this.operateur!==null)
            {
                this.$router.push('/Client/Verification')
            }
        },show(){
            // this.modal_num=true
            if(this.operateur==="Telma"){
                this.numero="0344310334"
            }
            
            if(this.operateur==="Orange"){
                this.numero="0324310334"
            }
            
            if(this.operateur==="Airtel"){
                this.numero="0334310334"
            }
           
        },

        getAbo(){
            this.abonnement=localStorage.getItem("myAbonnement");
            this.prix=localStorage.getItem("myPrix");
        }
    }
}
</script>