<template>

<div class="">
    <div class="md:px-8 max-md:px-2">

    <br><br><br><br><div class="max-sm:text-xs">
        <input v-model="$route.params.idcal" class="hidden">

        <div class="flex flex-col items-start justify-center gap-y-2">
            <div><span class="font-semibold">Nom :</span><span class="max-sm:px-4"> {{ nomPro }}</span></div>
            <div><span  class="font-semibold">Zone :</span> <span class="max-sm:px-4">{{ zone }}</span></div>
            <div><span  class="font-semibold">Observation :</span><span class="max-sm:px-4"> {{ observation }}</span></div>

        </div>
        <br><br>
    <div class="w-screen overflow-x-scroll">
       <div class="max-md:w-[160vw] max-sm:px-1">
        <div class="flex flex-row">
        <div class="w-1/12 md:p-3"></div>
        <div class="w-11/12">
            <div class=" grid grid-cols-12">
                <div class="p-3 border-r">Jan</div>
                <div class="p-3  border-r">Fev</div>
                <div class="p-3  border-r">Mar</div>
                <div class="p-3  border-r">Avr</div>
                <div class="p-3  border-r">Mai</div>
                <div class="p-3  border-r">Juin</div>
                <div class="p-3  border-r">Juil</div>
                <div class="p-3  border-r">Aou</div>
                <div class="p-3  border-r">Sep</div>
                <div class="p-3  border-r">Oct</div>
                <div class="p-3  border-r">Nov</div>
                <div class="p-3">Dec</div>
            </div>
        </div>
    </div>
    <div class="">
    <div class=" flex flex-row justify-center" ref="all">
        <div class="flex w-1/12 max-md:mr-4">
            <div class="grid grid-rows-6 gap-1">
                <div class="md:p-3 text-left">PrepaSol</div>
                <div class="md:p-3 text-left">Semis</div>
                <div class="md:p-3 text-left">Marcottage</div>
                <div class="md:p-3 text-left">Repiquage</div>
                <div class="md:p-3 text-left">Entretien</div>
                <div class="md:p-3 text-left">Fecondation</div>
            </div>
        </div>
        <div class="grid grid-flow-col w-11/12 border border-gray-100 border-spacing-4">
            <div class="grid grid-rows-6 gap-1">
                <div class="grid grid-cols-12  "  id="PrepaSol">
                    <div ref="element" class="p-3 ">
                        <div></div>
                        <div></div>
                    </div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
                <div class="grid grid-cols-12  "  id="Semis">
                    <div ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
                <div class="grid grid-cols-12  "  id="Marcottage">
                    <div ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
                <div class="grid grid-cols-12  "  id="Repiquage">
                    <div ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
                <div class="grid grid-cols-12  "  id="Entretien">
                    <div ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
                <div class="grid grid-cols-12  "  id="Fecondation">
                    <div ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                    <div  ref="element" class="p-3 "></div>
                </div>
            </div>
        </div>
    </div>
    </div>
       </div>
</div>
</div>
</div>
<Footer></Footer>

<Header2 @modal_deconnection_show="data_modal_deconnnection"  @menu_mobile_show="data_menu_mobile"></Header2>
<!--modal deconnection-->
<div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection" v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center" >
            <div class="flex justify-end items-center">  
                <div class="mx-4">
                    <button @click="modal_deconnection=false,modal_back=false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div>
                <div>
                    <h5>deconnnection</h5>
                </div>
                <div>
                    <form class="form form-check">
                        <button @click.prevent="" class="btn rounded bg-blue-600 text-white p-1"><Router-Link to="/Client/Accueil2">Se connecter</Router-Link></button><br>
                        <span>Vous n'avez pas de compte?</span><RouterLink to="/Client/Inscription"><span class="text-blue-400">s'inscrire</span></RouterLink>
                    </form>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <!-- modal menu mobile -->
    <div  class=" fixed top-0 modal-mobile flex  w-screen h-screen bg-black bg-opacity-50 items-center justify-center" v-show="menu_mobile2">
        <div class=" rounded fixed bg-white   w-[60vw] h-fit py-7 grid text-xs items-center justify-center sm:text-lg ">
                <div class="flex justify-end items-center">
                    <div class=""><button class="fa fa-times fa-2x" @click="menu_mobile2=false"></button></div>
                </div>
                <div class="p-6 flex flex-col gap-5 text-left">
                    <div>
                        <RouterLink to="/Client/Accueil2" class="font-bold">
                            Accueil
                        </RouterLink>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Produit</summary>
                            <div class="flex flex-col p-1 gap-1 ml-5">
                                <div><RouterLink to="/Client/Produit"><h6>produit culture</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Produit"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <router-link to="/Client/Calendrier" class="font-bold">Calendrier</router-link>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Marché</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Prix"><h6>Prix</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Client"><h6>Client</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Fournisseur"><h6>Fournisseur</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary class="font-bold">Outils à la decision</summary>
                            <div class="flex flex-col p-1 gap-1  ml-5">
                                <div><RouterLink to="/Client/Courbe"><h6>Courbe</h6></RouterLink></div>
                                <div><RouterLink to="/Client/Produit"><h6>produit agricole</h6></RouterLink></div>
                            </div>
                        </details>
                    </div>
                    
                </div>
        </div>
    </div>
    <!-- modal info calenfrier --></div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import apiUrl from '../../apiConfig';
import Header2 from '@/components/Header2.vue';
import Footer from '@/components/Footer.vue';
// eslint-disable-next-line no-unused-vars
// import { stringify } from 'postcss';
export default {
    name:"DetailsCalendrier",
    components:{
        Header2,
        Footer
    },
    data(){
        return{
            nomPro:'', zone:'', observation:'',
            PrepaSold1:0, PrepaSold2:0, PrepaSolf1:0, PrepaSolf2:0, Semisd1:0, Semisf1:0, Semisd2:0, Semisf2:0, Marcod1:0, Marcof1:0, Marcod2:0, Marcof2:0,
            Repd1:0, Repf1:0, Repd2:0, Repf2:0, Entretiend1:0, Entretienf1:0, Entretiend2:0, Entretienf2:0, Fecondationd1:0, Fecondationf1:0, Fecondationd2:0, Fecondationf2:0,
            Recolted1:0, Recoltef1:0, Recolted2:0, Recoltef2:0,
            menu_mobile2:false,
        modal_deconnection:false,
         //donnee utiliser dans le calendrier
            date_deb:[],
            date_fin:[],
            bgc:[
                'red','green','gray','orange','black','yellow'
            ]
        }
    },
    mounted(){
        
        this.detailCalendrier()
        this.detail_date()
        
        
    },
    methods:{
        detailCalendrier(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_calendrier_detail',{params:{idcal3: this.$route.params.idcal}}).then(response => {
                    this.nomPro = response.data[0].nomPro;
                    this.zone = response.data[0].zone;
                    this.observation = response.data[0].observation;
                })
                .catch(error => {(
                    console.log(error)
                )})
            },
            detail_date(){
                // eslint-disable-next-line no-unused-vars
                axios.get(apiUrl + 'api/affichage_date',{params:{idcal: this.$route.params.idcal}}).then(response => {
                    this.PrepaSold1 = response.data[0].prepaSold1;
                    console.log(this.PrepaSold1)
                    this.PrepaSolf1 = response.data[0].prepaSolf1;
                    this.PrepaSold2 = response.data[0].prepaSold2;
                    this.PrepaSolf2 = response.data[0].prepaSolf2;
                    this.Semisd1 = response.data[0].semisd1;
                    this.Semisf1 = response.data[0].semisf1;
                    this.Semisd2 = response.data[0].semisd2; 
                    this.Semisf2 = response.data[0].semisf2;
                    this.Marcod1 = response.data[0].marcod1; 
                    this.Marcof1 = response.data[0].marcof1;
                    this.Marcod2 = response.data[0].marcod2; 
                    this.Marcof2 = response.data[0].marcof2;
                    this.Repd1 = response.data[0].repd1; 
                    this.Repf1 = response.data[0].repf1;
                    this.Repd2 = response.data[0].repd2; 
                    this.Repf2 = response.data[0].repf2;
                    this.Entretiend1 = response.data[0].entretiend1; 
                    this.Entretienf1 = response.data[0].entretienf1;
                    this.Entretiend2 = response.data[0].entretiend2; 
                    this.Entretienf2 = response.data[0].entretienf2;
                    this.Fecondationd1 = response.data[0].fecondationd1; 
                    this.Fecondationf1 = response.data[0].fecondationf1;
                    this.Fecondationd2 = response.data[0].fecondationd2; 
                    this.Fecondationf2 = response.data[0].fecondationf2;
                    this.Recolted1 = response.data[0].recolted1; 
                    this.Recoltef1 = response.data[0].recoltef1;
                    this.Recolted2 = response.data[0].recolted2; 
                    this.Recoltef2 = response.data[0].recolted2;
                    this.date_deb=[this.PrepaSold1,this.Semisd1,this.Marcod1,this.Repd1,this.Entretiend1,this.Fecondationd1]
        this.date_fin=[this.PrepaSolf1,this.Semisf1,this.Marcof1,this.Repf1,this.Entretienf1,this.Fecondationf1]
        console.log(this.date_deb)
        this.tab()
                })
                .catch(error => {(
                    console.log(error)
                )})
            },
        data_menu_mobile(data){
                this.menu_mobile2=data
            },
            data_modal_deconnnection(data){
                this.modal_deconnection=data
            },
    tab(){
        const divs1 = $("#PrepaSol").children();
for (let i = this.date_deb[0] - 1; i <= this.date_fin[0]; i++) {
  divs1.eq(i).css("backgroundColor", this.bgc[0]);
}

const divs2 = $("#Semis").children();
for (let i = this.date_deb[1]; i <= this.date_fin[1]; i++) {
  divs2.eq(i).css("backgroundColor", this.bgc[1]);
}

const divs3 = $("#Marcottage").children();
for (let i = this.date_deb[2]; i <= this.date_fin[2]; i++) {
  divs3.eq(i).css("backgroundColor", this.bgc[2]);
}

const divs4 = $("#Repiquage").children();
for (let i = this.date_deb[3]; i <= this.date_fin[3]; i++) {
  divs4.eq(i).css("backgroundColor", this.bgc[3]);
}

const divs5 = $("#Entretien").children();
for (let i = this.date_deb[4]; i <= this.date_fin[4]; i++) {
  divs5.eq(i).css("backgroundColor", this.bgc[4]);
}

const divs6 = $("#Fecondation").children();
for (let i = this.date_deb[5]; i <= this.date_fin[5]; i++) {
  divs6.eq(i).css("backgroundColor", this.bgc[5]);
}
    }
}
    }
</script>