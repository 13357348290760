<template>
    <div class="modal_header transition-all   duration-500 w-screen h-fit bg-white  overflow-hidden" ref="modal_menu">
        <div class="">
            <div class="flex justify-between  px-4 bg-white h-fit overflow-hidden text-sm sm:text-base items-center">
                <div class="">
                    <router-link to="/">
                        <img src="./img/logovf.png" class="w-7 sm:w-10">
                    </router-link>
                </div>
                <div class="max-sm:hidden sm:block  overflow-hidden h-fit">
                    <div
                        class="flex max-lg:gap-5 lg:gap-10  overflow-hidden  max-sm:text-xs items-center justify-between h-fit">
                        <div v-for="(section, index) in sections" :key="index" class="px-3 h-fit">
                            <router-link
                                class="overflow-hidden no-underline text-gray-800 h-fit transition duration-500 hover:text-blue-500"
                                :to="`/#${section.id}`">{{ section.title }}</router-link>
                            <!-- <a class=" overflow-hidden  no-underline text-gray-800  h-fit  transition duration-500  hover:text-blue-500"  :href="`#${section.id}`" :class="{ active: section.isActive }">{{ section.title }}</a> -->
                        </div>
                    </div>
                </div>
                <div class="flex  items-center justify-end">
                    <div class="">
                        <router-link to="/Client/Connection">
                            <button
                                class="bg-green-600 hover:bg-green-500 py-1 px-4 text-white max-sm:text-sm my-3 mx-4">Se
                                connecter</button>
                        </router-link>
                    </div>
                    <div class="sm:hidden mr-6">
                        <button @click="menu()" ref="btn_menu"
                            class="active:bg-gray-200 fas text-black fa-bars fa-2x transition-all   duration-500"></button>
                    </div>
                </div>
            </div>
            <div :style="{ height: height }" class="trans bg-slate-500 w-screen overflow-hidden">
                <ul class="m-2">
                    <li v-for="item in sections" class="border-y">

                        <a :href="item.link" class=" no-underline p-2 m-2 text-white">{{ item.title }}</a>
                    </li>

                </ul>
            </div>
        </div>
    </div>


</template>
<script>
import { RouterLink } from 'vue-router'
import '@/components/style/header.css'
import $ from 'jquery'
import '@/components/style/all.css'
import '@/components/style/modals.css'


export default {


    data() {
        return {
            height: '0',
            modal_connection: false,
            menu_mobile: false,
            modal_back: false,
            btn_connection: true,
            show_password: false,
            inp_password: '',
            sections: [
                { id: 'Accueil', title: 'Accueil', isActive: true, link: '#' },
                { id: 'Service', title: 'Service', isActive: false, link: '/' },
                { id: 'Propos', title: 'Propos', isActive: false, link: '/' },
                { id: 'Pricing', title: 'Pricing', isActive: false, link: '/' },
                { id: 'Contact', title: 'Contact', isActive: false, link: '/' },
                // Add more sections as needed
            ],
        }
    }, mounted() {
        window.addEventListener("scroll", () => {
            // Get the current scroll position
            const scrollY = window.scrollY;
            if (this.$refs.modal_menu !== null || this.$refs.btn_menu !== null) {

                // Check if the scroll has reached the bottom
                if (scrollY < 70) {
                    // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
                    //   this.$refs.modal_menu.classList.add('bg-opacity-0');
                    this.$refs.modal_menu.classList.remove('shadow-md');
                    this.$refs.modal_menu.classList.remove('shadow-gray-400');
                    this.$refs.btn_menu.classList.remove('border-black');
                    this.$refs.btn_menu.classList.add('border-white');
                    this.$refs.modal_menu.classList.add('text-white');
                    this.$refs.modal_menu.classList.add('border-white');
                    this.$refs.modal_menu.classList.add('shadow-transparent');
                } else {
                    // Handle non-bottom scroll position
                    //console.log('Scroll position:', scrollY);
                    this.$refs.modal_menu.classList.remove('text-white');
                    this.$refs.modal_menu.classList.remove('border-white');
                    this.$refs.modal_menu.classList.remove('shadow-transparent');
                    this.$refs.btn_menu.classList.remove('border-white');
                    //   this.$refs.modal_menu.classList.remove('bg-opacity-0');
                    this.$refs.btn_menu.classList.add('border-black');
                    this.$refs.modal_menu.classList.add('shadow-md');
                    this.$refs.modal_menu.classList.add('shadow-gray-400');
                }
            }
        });
    },
    methods: {
        modal_connection_show: function () {


            this.$emit('modal_connection_show', !this.modal_connection)


        },
        handleScroll: function () {
            if (window.screenY > 3) {
                var a = document.querySelector('.headerContainer')
                a.classList.add('fixed')
            }
        },
        togglePassword: function () {
            this.show_password = !this.show_password
        }
        , menu: function () {
            //this.$emit('menu_mobile_show',!this.menu_mobile)
            if (this.height !== '0') {
                this.height = '0'
            } else {
                this.height = 'fit-content'
            }


        }, hide: function () {
            this.height = '0'

        }


    }
}

</script>
<style>
.animated {
    transition: all 300ms ease-in-out;

}
</style>