<template>

  <div class="modal-background" v-if="showAddModal"></div>
  <!--<div class="modal-background" v-if="showSuppressionModal"></div>-->
  
    
  <header class="navbar sticky-top p-0" data-bs-theme="white">
    
    <ul class="navbar-nav flex-row d-md-none mr-28 ml-3">
      <li class="nav-item text-nowrap">
        <div class="fas fa-bars-staggered fa-2x text-black pt-1 mr-2" style="width: 40px; height:38px; color: white;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
      </li>
    </ul>

    <div class="d-md-none text-xl text-green-600 mr-3">
      Agribusiness
    </div>

</header>

<div class="offcanvas offcanvas-end" style="z-index: 1050;" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
            
            <div style="display: inline-flex; padding-left: 20px; border-radius: 5px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
              <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
              <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
            </div>
        
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier agricole</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class="text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
            </div>
        
        </ul>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="sidebar fixed border h-full border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">

            <div class="" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                <div><img src="../../assets/logoAgri.jpg" class="logoList" style="width: 40px; height:38px; color: white;"></div>
                <div class=" mt-2 text-2xl text-green-600 ml-3 md:ml-1 md:text-xl md:mt-3">Agribusiness</div>
              </div>
              <div class="border-b-2 mb-3"></div>
            
            <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
              </div>
            
              <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; background-color: rgb(207, 205, 205); padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
                <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
              </div>
          
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200 py-1" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px; " aria-current="page" href="#">
                <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
              </div>

              <logout></logout>
          
          </ul>
        </div>
      </div>
      </div>

    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Utilisateur</h1>
      </div>

      <!--<div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <h4>Luciano</h4>
          </div>
          <div class="col-8 col-md-4">
            <input type="text"> 
          </div>
            
        </div>
        <div class="col-12 col-md-6">
          <h4>Esmerldo</h4><input type="text">
        </div>
      </div>

      <div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-3">
            <p>Produit:</p>
          </div>
          <div class="col-8 col-md-3">
            <input type="text" style="height: 24px; border-radius: 6px;" v-model="inpProduit"> 
          </div>   
        </div>
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <p>Catégorie:</p>
          </div>
          <div class="col-8 col-md-4">
            <select v-model="inpCat">
              <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
            </select>
          </div>   
        </div> 
      </div>
      <div class="row">
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-3">
            <p>Prix entre:</p>
          </div>
          <div class="col-8 col-md-3">
            <input type="number" style="height: 24px; border-radius: 6px;" v-model="inpPrix1">
          </div>   
        </div>
        <div class="row col-12 col-md-6">
          <div class="col-4 col-md-2">
            <p>Et:</p>
          </div>
          <div class="col-8 col-md-4">
            <input type="number" style="height: 24px; border-radius: 6px;" v-model="inpPrix2">
          </div>   
        </div> 
      </div>
      <div class="d-flex justify-content-center">      
            <button class="btn btn-sm btn-primary" @click="getClients(), init()">Initialiser</button>
            <button class="btn btn-sm btn-primary" style="margin-left: 10px;" @click="rechercheClient()">Rechercher</button>    
      </div><br>-->

      <div class="lg:flex input-group mb-3 d-flex lg:w-28">
          <div>
            <input type="text" class="form-control h-8 rounded-tr-none rounded-br-none" placeholder="Nom d'utilisateur..." aria-label="Nom d'utilisateur" aria-describedby="button-addon2" v-model="nomRecherche">
          </div>
          <div>
            <button class="btn btn-primary btn-sm" @click="rechercheUtilisateur">Rechercher</button>
          </div>
          
      </div><br>



      <div class="overflow-x-auto md:mx-full  max-sm:mx-4 ">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="">
                      <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden">Id</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">E-mail</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Adresse</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden">Role</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="user in users" :key="user.id">
                            <td class="hidden">{{ user.id }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{ user.name }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{user.email}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{user.contact}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{user.adresse}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{user.statut}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7 hidden">{{user.role}}</td>
                            
                            <td class="btns md:flex sm:flex md:px-6 sm:px-3 sm:py-2 md:py-4"><div class="fas fa-eye me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalDetail"  @click="id=user.id, nom=user.name, email=user.email, contact=user.contact,adresse=user.adresse,role=user.role, statut=user.statut "></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>


        

      <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      </div>

      


      </div>
      </div>

      <div class="modal" role="dialog" tabindex="-1" ref="myModalMenu" >
          <div class="modal-dialog" role="document">
                <div class="modal-content">               
                  <div class="modal-body">
                    <div><button class="btn btn-danger" @click="closeModalMenu">Retour</button></div>
                        <ul class="nav flex-column">
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                              <div><router-link to="./AccueilAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./FournisseurAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CalendrierAdmin"><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ElevageAdmin"><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CultureAdmin"><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./AbonnementAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./ComparaisonAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" style="text-decoration: none; color:black; font-size: 20px;">Comparaison</router-link></div>
                          </div>
                        </ul> 
                    </div>
                  </div>
                </div>
          </div>

    
    <div class="modal" tabindex="-1" role="dialog" ref="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mon Modal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Contenu du modal -->
            <p>Contenu du modal ici.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Fermer</button>
            <button type="button" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetail" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form >
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Détail</h4>
                    <input type="hidden" v-model="id">               
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Nom :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="nom" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Contact :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="contact" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Email :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="email" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Adresse :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="adresse" disabled>
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Statut :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="statut" disabled> 
                      </div>
                      

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="init">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

  

</template>

<script>
import apiUrl from '../../apiConfig';
import logout from '@/components/logoutAdmin.vue';

import axios from 'axios'
import 'datatables.net-responsive-bs5'
//import 'datatables.net';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.js';
//import DataTable from 'datatables.net-bs5';

import '../../../public/css/bootstrap.min.css';
import '../../../public/js/bootstrap.min.js';

import 'datatables.net-responsive-bs5'
//import DataTable from 'datatables.net-vue3';
export default {
  name: 'ClientAdmin',
  components: {
    logout
  },
  data () {
    return {
         
          showDetailModal: false,
          
          inpProduit: '', nomRecherche: '',
          idacteur1: '',
          inpPrix1:'',
          inpPrix2:'',
          inpCat:'', idacteur:'',
          showDiv: true,
          nom:'', contact:'', email:'', adresse:'', role:'', statut:'',
          user:{
            nom: "", contact:"", email:"", adresse:"", role:"", statut:''
          },
          isModModal: false,
          users:[],
    }
  },
  mounted() {
    this.getUtilisateurs();
  },

  methods: {
   

    getUtilisateurs(){
            axios.get(apiUrl + 'api/affichage_utilisateur').then(response => {
              this.users = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.users=[]
            })
      },

    rechercheUtilisateur(){
          axios.get(apiUrl + 'api/recherche_utilisateur', {params: {nomRecherche: this.nomRecherche}}).then(response => {
                this.users = response.data
              }).catch(error =>{
                console.log(error)
              })
      },

    init(){
      this.nom='', this.contact='', this.email='', this.adresse='', this.domaine='', this.description='', this.statut=''
    }

  },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../components/styleAdmin/dash1.css';
  
</style>

<head>
  <script src="/docs/5.3/assets/js/color-modes.js"></script>
  <link rel="canonical" href="https://getbootstrap.com/docs/5.3/examples/dashboard/">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@docsearch/css@3">

  <link href="/docs/5.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <!-- Favicons -->
  <link rel="apple-touch-icon" href="/docs/5.3/assets/img/favicons/apple-touch-icon.png" sizes="180x180">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-32x32.png" sizes="32x32" type="image/png">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-16x16.png" sizes="16x16" type="image/png">
  <link rel="manifest" href="/docs/5.3/assets/img/favicons/manifest.json">
  <link rel="mask-icon" href="/docs/5.3/assets/img/favicons/safari-pinned-tab.svg" color="#712cf9">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon.ico">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css" rel="stylesheet">
</head>


