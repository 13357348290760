<!-- eslint-disable vue/no-unused-components -->
<template>
    <div class="w-screen flex justify-center flex-col items-center">
        <br><br>
        <br>
        <div class=" flex w-11/12">
            <div
                class="flex max-sm:w-full w-screen max-md:flex-col max-md:justify-center max-md:items-start md:justify-between md:items-center  gap-4">
                <div class="flex justify-start items-center ">
                    <div class="flex justify-center items-center gap-x-3  w-fit">
                        <div class="fa fa-home  cursor-pointer" @click="$router.push('/Client/Accueil2')"></div>
                        <div class="fas fa-caret-right text-gray-500"></div>
                        <div class="w-fit cursor-pointer" @click="$router.push('/Client/Courbe')">
                            <span>Courbe</span>
                        </div>
                    </div>
                </div>
                <div class="md:flex max-md:flex-col  max-md:gap-2  gap-x-2 max-md:justify-end max-md:items-center">
                    <div class="flex border max-md:w-full md:w-fit justify-end items-center">
                        <div class="flex items-center justify-center">
                            <!-- <div class="border-r"><label for="" class="px-1">Prix</label></div> -->
                            <div class="border-r"><input type="text"
                                    class="p-1 w-full focus:outline-none placeholder:text-sm" placeholder="Origine ..."
                                    v-model="inpOrigine"></div>

                        </div>
                        <div class="max-md:flex max-md:w-full max-md:justify-end max-md:items-end">
                            <div class="border-r w-fit">
                                <input type="text " name="" id="" v-model="inpNom" placeholder="Nom du produit ..."
                                    class="w-fit  p-1 focus:outline-none  placeholder:text-sm">
                            </div>
                        </div>
                    </div>
                    <div class="max-md:w-full max-md:flex max-md:justify-end max-md:items-center">
                        <div class="py-1 px-4 max-md:my-4 text-white max-md:w-fit md:w-full h-full bg-green-600 hover:bg-green-500 hover:cursor-pointer  placeholder:text-sm overflow-hidden focus:outline-none"
                            @click="recherchePrix">Rechercher</div>
                    </div>
                </div>

            </div>
        </div>
        <br><br>
        <div class=" w-11/12">
            <div class=" w-full overflow-x-auto">
                <table class="w-full max-w-full">
                    <thead>
                        <th style="display: none;">Id</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Nom du produit</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Origine</th>
                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                            Unite</th>

                        <th
                            class="px-6 py-2 border-b border-gray-200 bg-gray-300 text-left font-semibold text-gray-600 ">
                        </th>
                    </thead>
                    <tbody class="text-gray-600 max-sm:text-sm">

                        <tr v-for="prix in prixs" :key="prix.idprix"
                            class="hover:bg-gray-100 cursor-pointer text-sm text-left"
                            @click="nomPro = prix.nomPro, origine = prix.origine, unite = prix.unite,
                            annee1 = prix.annee1, annee2 = prix.annee2, annee3 = prix.annee3, annee4 = prix.annee4, annee5 = prix.annee5">
                            <td style="display: none;">{{ prix.idacteur }}</td>

                            <td class="px-3 py-2 border-b border-gray-200">{{ prix.nomPro }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ prix.origine }}</td>
                            <td class="px-3 py-2 border-b border-gray-200">{{ prix.unite }}</td>

                            <td class="px-3 py-2 border-b border-gray-200">
                                <div @click="modal_courbe_show(),
                            nomPro = prix.nomPro, origine = prix.origine, unite = prix.unite, annee1 = prix.annee1,
                            annee2 = prix.annee2, annee3 = prix.annee3, annee4 = prix.annee4, annee5 = prix.annee5">
                                    <button class="fas fa-chart-column fa-2x"></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div><br><br><br><br><br><br>



        </div>

    </div>
    <Footer></Footer>
    <Header2 @modal_deconnection_show="data_modal_deconnnection" @menu_mobile_show="data_menu_mobile"></Header2>
    <!-- modal courbe -->

    <div class="modal_ajout flex w-screen h-screen justify-center items-center bg-black bg-opacity-50 "
        v-show="modal_courbe">
        <div class="rounded bg-white max-md:w-[100vw] max-md:h-[90wh] md:w-[80vw] md:h-[60wh] p-3">
            <div class="flex justify-end items-center">
                <div class="m-4"><button class="fa fa-times fa-2x" @click="modal_courbe = false"></button></div>
            </div>
            <Bar id="my-chart-id" :options="chartOptions" :data="chartData" class="w-full h-full overflow-x-auto" />
        </div>
    </div>
    <!--modal deconnection-->
    <div class="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center fixed top-0 left-0 modal-connection"
        v-show="modal_deconnection">
        <div class=" rounded box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
            <div class="flex justify-end items-center">
                <div class="mx-4">
                    <button @click="modal_deconnection = false, modal_back = false" class="fa fa-times fa-2x"></button>
                </div>
            </div>
            <div class="flex flex-col gap-3">
                <div>
                    <h5 class="font-bold text-xl">Deconnnection</h5>
                </div>
                <div class="flex gap-3">
                    <div>
                        <button class="btn rounded bg-gray-400 text-white p-1"
                            @click="modal_deconnection = false">Annuler</button>
                    </div>
                    <div>
                        <button class="btn rounded bg-blue-500 text-white p-1"><Router-Link to="/">Se
                                deconnecter</Router-Link></button><br>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </div>

</template>
<script>
import Header2 from '@/components/Header2.vue'
import axios from 'axios';
import apiUrl from '../../apiConfig';
import Footer from '@/components/Footer.vue'
import Courbe2 from '@/components/detailsCourbe.vue'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
    name: "Courbe",
    components: {
        Header2,
        Footer,
        // eslint-disable-next-line vue/no-unused-components
        Courbe2,
        Bar
    },
    data() {
        return {
            inpOrigine: '', inpNom: '',
            d: [],
            prixs: [],
            nomPro: '', idprix: '', origine: '', unite: '', annee1: 0, annee2: 0, annee3: 0, annee4: 0, annee5: 0,
            modal_courbe: false,
            sections: [
                { id: 'Service', title: 'Service', isActive: false },
                { id: 'Propos', title: 'Propos', isActive: false },
                { id: 'Pricing', title: 'Pricing', isActive: false },
                { id: 'Contact', title: 'Contact', isActive: false },
                // Add more sections as needed
            ],
            menu_mobile2: false,
            modal_deconnection: false,
            chartOptions: {
                responsive: true
            }
        }
    },
    computed: {
        chartData() {
            return {
                labels: ['2018', '2019', '2020', '2021', '2022'],
                datasets: [
                    {
                        label: this.nomPro,
                        data: [this.annee1, this.annee2, this.annee3, this.annee4, this.annee5],
                        backgroundColor: ['#fcba03', '#6ab818', '#1cd4ce', '#d6245d', '#abcabd']
                    },
                ],

            }
        }
    },
    mounted() {
        this.getPrixs();
    },
    methods: {
        getPrixs() {
            axios.get(apiUrl + 'api/affichage_prix').then(response => {
                this.prixs = response.data
            }).catch(error => {
                console.log(error)
                this.prixs = []
            })
        },
        recherchePrix() {
            axios.get(apiUrl + 'api/recherche_prix', { params: { inpNom: this.inpNom, inpOrigine: this.inpOrigine } }).then(response => {
                this.prixs = response.data

            }).catch(error => {
                console.log(error)
            })
        },

        modal_courbe_show: function () {
            if (this.modal_courbe) {
                this.modal_courbe = false
                this.modal_back = false
            } else {
                this.modal_courbe = true
                this.modal_back = true
            }
        },
        data_menu_mobile(data) {
            this.menu_mobile2 = data
        },
        data_modal_deconnnection(data) {
            this.modal_deconnection = data
        }
    }
}
</script>
