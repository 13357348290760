<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <br><br><br><br>
    <div class="modal_relative ">
      <div class="grid  justify-center items-center  lg:grid-cols-2 ">
        <div class="hidden lg:block">
          <img src="./../../components/img/undraw_Sign_up.png" class="img_inscri">
        </div>
        <div class="max-sm:w-screen">
          <div class="flex flex-col gap-1">
            <h3 class="font-semibold">INSCRIPTION</h3>
            <p class="max-sm:text-sm text-lg mx-2"> Complétez le formulaire ci-dessous pour débuter votre inscription.
            </p>
            <form @submit.prevent="inscription">
              <div class="flex justify-center items-center">
                <div class="text-left w-full lg:w-6/12 gap-y-2 flex flex-col justify-center  ">
                  <div class="w-full px-4">
                    <label for="Nom" class="max-sm:text-sm text-gray-500">Nom</label><br>
                    <input class="p-1  border border-gray-500 m-1 w-full" type="text" name="Nom" v-model="name"
                      required>
                  </div>
                  <div class="w-full px-4">
                    <label for="Contact" class="max-sm:text-sm text-gray-500">Contact</label><br>
                    <input class="p-1  border border-gray-500 m-1 w-full" type="text" name="Contact" v-model="contact"
                      required>
                  </div>
                  <div class="w-full px-4">
                    <label for="Email" class="max-sm:text-sm text-gray-500">Email</label><br>
                    <input class=" p-1  border border-gray-500 m-1 w-full" type="email" name="Email" v-model="email"
                      required>
                  </div>
                  <div class="w-full px-4">
                    <label for="Adresse" class="max-sm:text-sm text-gray-500">Adresse</label><br>
                    <input class=" p-1  border border-gray-500 m-1 w-full" type="text" name="Adresse" v-model="adresse"
                      required>
                  </div>
                  <div class="w-full px-4">
                    <label for="mot de passe" class="max-sm:text-sm text-gray-500">Mot de passe</label><br>
                    <input v-model="password" type="password" class="p-1  border border-gray-500 m-1 w-full" required>
                  </div>
                  <div class="w-full px-4">
                    <label for="confirm  mot de passe" class="max-sm:text-sm text-gray-500">Confirmer le mot de
                      passe</label><br>
                    <input v-model="confPassword" type="password" class="p-1  border w-full border-gray-500 m-1 "
                      required>
                  </div>
                </div>
              </div><br>
              <div>
                <p class="text-red-500">{{ message }}</p>
              </div>
              <div class="flex w-full justify-center max-md:px-5 max-md:gap-x-3 md:gap-x-10 items-center">
                <div class="max-md:w-1/2">
                  <router-link to="/">
                    <button type="reset"
                      class=" border-slate-600 max-md:w-full  border text-slate-600  px-4 py-1 max-sm:text-sm">Annuler</button>
                  </router-link>
                </div>
                <div class="max-md:w-1/2">
                  <button type="submit"
                    class=" bg-green-600 max-md:w-full hover:bg-green-500 text-white px-4 py-1 max-sm:text-sm">S'inscrire</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-screen h-screen bg-black bg-opacity-50 flex items-center cd justify-center fixed top-0 left-0 modal-connection"
      v-show="modal_connection">
      <div class="  box-shadow bg-white  w-fit h-fit py-6 px-4  grid items-center justify-center">
        <div class="flex justify-end items-center">
          <div class="mx-4">
            <button @click="modal_connection = false, modal_back = false" class="fa fa-times fa-2x">

            </button>
          </div>
        </div>
        <div>
          <div>
            <h5>BIENVENUE</h5>
            <h5>CHEZ AGRIBUSINESS</h5><br>
          </div>
          <div>
            <form action="http://127.0.0.1:8000/api/inscription" class="form form-check" method="POST"
              @submit.prevent="inscription">
              <input type="email" placeholder="Email" class="border border-3 m-2 p-1 "><br>
              <input type="password" placeholder="Mot de passe" class="border border-3 m-2   p-1"
                v-model="inp_password">
              <input type="text" placeholder="Mot de passe" class="border border-3 m-2   p-1" v-show="false">
              <br>
              <input type="checkbox" class="border border-3 m-2"><span>se souvenir de moi</span><br><br>
              <button @click.prevent="" class="btn  bg-green-600 text-white p-1"><Router-Link to="/Client/Accueil2">Se
                  connecter</Router-Link></button><br>
              <span>Vous n'avez pas de compte?</span>
              <RouterLink to="/Client/Inscription"><span class="text-green-600">s'inscrire</span></RouterLink>
            </form>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <!--modal menu mobile-->
  <div class="modal_header transition-all shadow-sm shadow-gray-300   duration-500 w-screen h-fit   overflow-hidden"
    ref="modal_menu">
    <div class="">
      <div class="flex justify-between  px-4 bg-white h-fit overflow-hidden  items-center">
        <div class="flex justify-start items.-center gap-x-3">
          <router-link to="/">
            <img src="../../components/img/logovf.png" class="w-7 sm:w-10">
          </router-link>
          <span class="font-bold md:pt-3 max-md:pt-2 mt-1">
            AGRIBUSINESS
          </span>
        </div>
        <div class="flex justify-end items-center gap-x-3">
          <div class="max-lg:hidden   overflow-hidden h-fit">
            <div class="flex  gap-x-4 overflow-hidden  max-sm:text-sm items-center justify-between h-fit">
              <div v-for="(section, index) in sections" :key="index" class="px-3 h-fit">
                <a class=" overflow-hidden  no-underline text-gray-800  h-fit  transition duration-500text-lg hover:text-blue-500"
                  :href="`/#${section.id}`" :class="{ active: section.isActive }">{{ section.title
                  }}</a>
              </div>
            </div>
          </div>
          <div class="flex  items-center justify-end py-2">
            <div class="lg:hidden ">
              <button @click="show()" ref="btn_menu"
                class="active:bg-gray-200 fas text-black fa-bars fa-2x transition-all   duration-500"></button>
            </div>
          </div>
        </div>
      </div>
      <Transition>
        <div v-show="modal_menu" class=" bg-slate-800 z-10 w-12/12 overflow-hidden">
          <ul class=" divide-y  flex flex-col items-center justify-start gap-y-3  w-11/12">
            <li v-for="item in sections" class="divide-y  text-left py-2 w-full">
              <a :href="item.link" class=" no-underline  text-white ">{{ item.title }}</a>
            </li>
            <div class="w-full flex justify-between items-center gap-x-2 py-2">
              <router-link to="/Client/Insciption" class="w-1/2"><button
                  class="h-8 border w-full border-white text-white ">S'inscrire</button></router-link>
              <router-link to="/Client/Connection" class="w-1/2"><button
                  class="h-8 bg-green-600 w-full hover:bg-green-500 text-white ">Se
                  connecter</button></router-link>
            </div>
          </ul>
        </div>
      </Transition>
    </div>
  </div>
  <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal1">
    <div class="bg-white p-4">
      <div class="w-full flex justify-end items-center">
        <div class="fa fa-times cursor-pointer" @click="modal1 = false"></div>
      </div>
      <div class="w-full flex justify-center items-center p-3">
        <div class="rounded-full p-2 fas fa-circle-notch fa-2x animate-spin text-gray-400"></div>
      </div>
      <div class="w-full flex justify-start items-center">
        <div>
          <div class="">
            <p class="text-gray-600">Votre abonnement est en cours de validation, veuillez attendre s'il vous plaît !
            </p>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="">
          <button class="px-4 py-1 bg-green-600 hover:bg-green-500 text-white" @click="modal1 = false">OK</button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal_ajout fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start"
    v-show="modalValidation">
    <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4 mt-20 rounded">
      <div class="w-full flex justify-end items-center">
        <div class="fa fa-times cursor-pointer" @click="modalValidation = false, init()"></div>
      </div>
      <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2 cursor-pointer"
        @click="modalValidation = false, init()"></div>
      <div class="w-9/12">
        <p class="text-gray-500">Inscription effectué !</p>
      </div>
    </div>
  </div>


</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(900px);
}

.notif {
  position: fixed;
  top: 4vh;
  right: 30vw;
  z-index: 5;
}
</style>
<script>
import axios from 'axios';
import apiUrl from '../../apiConfig';
// eslint-disable-next-line no-unused-vars
import {
  RouterLink
} from 'vue-router'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import '@/components/style/all.css'
import '@/components/style/header.css'
import '@/components/style/inscription.css'
export default {

  name: 'Accueil',
  components: {
    Header,
    Footer
  }, data() {
    return {
modal_menu:false,
      name: '', email: '', contact: '', adresse: '', password: '', confPassword: '',
      message: '', modalValidation: false,

      modal_connection: false,
      menu_mobile: false,
      pwd: '',
      confirm_pwd: '',
      no_matching: 'border-gray-600',
      sections: [{
        id: 'Service',
        title: 'Service',
        link: '/#Service'
      },
      {
        id: 'Propos',
        title: 'A propos',
        link: '/#Propos'
      },
      {
        id: 'Pricing',
        title: 'Abonnement',
        link: '/#Pricing'
      },
      {
        id: 'Contact',
        title: 'Contact',
        link: '/#Contact'
      },
        // Add more sections as needed
      ],

    }

  },

  methods: {
    show: function () {
        if (this.modal_menu == false) {
          this.modal_menu = true
        } else {
          this.modal_menu = false
        }
      },
    inscription() {

      if (this.password != this.confPassword) {
        this.message = "Veuillez confirmer votre mot de passe";
        this.confPassword = "";
      }
      else {

        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('contact', this.contact);
        formData.append('adresse', this.adresse);
        formData.append('password', this.password);

        axios.post(apiUrl + 'api/inscription', formData)
          .then(({ data }) => {
            console.log(data);
            try {
              this.modalValidation = true;
              //alert("Utilisateur inscrit");
            }
            catch (err) {
              alert("Utilisateur pas inscrit");
            }
          })

      }

    },

    init() {
      this.$router.push("/Client/Connection");
    },

    verification() {
      if (this.confirm_pwd != '') {
        if (this.confirm_pwd != this.pwd || this.confirm_pwd == '') {
          this.no_matching = 'border-red-400'
        } else {
          this.no_matching = 'border-green-400'
        }
      }
    },
    changeAttr: function () {
      // eslint-disable-next-line no-unused-vars
      var pwd = document.getElementsByClassName("pwd")
    },
    data_modal_connection: function (props) {
      this.modal_connection = props

    },
    methods: {
      inscription() {
        if (this.password != this.confPassword) {
          this.message = "Veuillez confirmer votre mot de passe";
          this.confPassword = "";
        } else {
          const formData = new FormData();
          formData.append('name', this.name);
          formData.append('email', this.email);
          formData.append('contact', this.contact);
          formData.append('adresse', this.adresse);
          formData.append('password', this.password);
          axios.post(apiUrl + 'api/inscription', formData)
            .then(({
              data
            }) => {
              console.log(data);
              try {
                alert("Utilisateur inscrit");
                this.$router.push("/Client/Connection");
              } catch (err) {
                alert("Utilisateur pas inscrit");
              }
            })
        }
      },
      verification() {
        if (this.confirm_pwd != '') {
          if (this.confirm_pwd != this.pwd || this.confirm_pwd == '') {
            this.no_matching = 'border-red-400'
          } else {
            this.no_matching = 'border-green-400'
          }
        }
      },
      changeAttr: function () {
        // eslint-disable-next-line no-unused-vars
        var pwd = document.getElementsByClassName("pwd")
      },
      data_modal_connection: function (props) {
        this.modal_connection = props
      },
      data_menu_mobile: function (props) {
        this.menu_mobile = props
      },
      show: function () {
        if (this.modal_menu == false) {
          this.modal_menu = true
        } else {
          this.modal_menu = false
        }
      }
    }
  }
}
</script>
