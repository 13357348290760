<template>

<section class="vh-100" style="background-color: #ede6e8;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <div class="col-md-6 col-lg-5 d-none d-md-block" style="position: relative;">
              <img src="../../assets/pexels-mark-stebnicki-2749165.jpg"
                alt="login form" class="h-100 w-100" style="border-radius: 1rem 0 0 1rem; object-fit: cover; object-position: center;" />
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">

                <form>

                  <!--<div class="d-flex align-items-center mb-3 pb-1">
                    <img src="../../assets/logoAgri.jpg" class="img-fuid w-14 h-14 me-2"/>
                    <span class="h1 fw-bold mb-0 mt-2 text-green-600">Agribusiness</span>
                  </div>-->

                  <h2 class="fw-normal font-semibold mb-3 pb-3 underline" style="letter-spacing: 1px;">Connexion</h2>

                  <div class="form-outline mb-4">
                    <h5 class="fw-normal " style="letter-spacing: 1px;">E-mail</h5>
                    <input type="email" placeholder="E-mail" id="form2Example17" class="border w-96 rounded h-10" />
                  </div>

                  <div class="form-outline mb-4">
                    <h5 class="fw-normal " style="letter-spacing: 1px;">Mot de passe</h5>
                    <input type="password" placeholder="Mot de passe" id="form2Example27" class="border w-96 rounded h-10" />
                  </div>

                  <div class="pt-1 mb-4">
                    <button class="btn btn-success w-96 " type="button" >Se connecter</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script>

export default{
  name: 'LoginAdmin',
}

</script>

<style>



</style>