<template>

  <div class="modal-background" v-if="showAddModal"></div>
  <!--<div class="modal-background" v-if="showSuppressionModal"></div>-->
  
    
  <header class="navbar sticky-top p-0" data-bs-theme="white">
    
    <ul class="navbar-nav flex-row d-md-none mr-28 ml-3">
      <li class="nav-item text-nowrap">
        <div class="fas fa-bars-staggered fa-2x text-black pt-1 mr-2" style="width: 40px; height:38px; color: white;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
      </li>
    </ul>

    <div class="d-md-none text-xl text-green-600 mr-3">
      Agribusiness
    </div>

</header>

<div class="offcanvas offcanvas-end" style="z-index: 1050;" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
            
            <div style="display: inline-flex; padding-left: 20px; border-radius: 5px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
              <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
              <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
            </div>
        
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier agricole</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class="text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
            </div>
        
        </ul>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="sidebar fixed h-full border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">

            <div class="" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                <div><img src="../../assets/logoAgri.jpg" class="logoList" style="width: 40px; height:38px; color: white;"></div>
                <div class=" mt-2 text-2xl text-green-600 ml-3 md:ml-1 md:text-xl md:mt-3">Agribusiness</div>
              </div>
              <div class="border-b-2 mb-3"></div>
            
            <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
                <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
              </div>
          
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200 py-1" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px; background-color: rgb(207, 205, 205);" aria-current="page" href="#">
                <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
              </div>

              <logout></logout>
          
          </ul>
        </div>
      </div>
      </div>

    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Elevage</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjout">Ajouter</button>
        </div>
      </div>

      <div class="lg:flex input-group mb-3 d-flex lg:w-28">
          <div>
            <input type="text" class="form-control h-8 rounded-tr-none rounded-br-none" placeholder="Nom d'élevage..." aria-label="Nom d'élevage" aria-describedby="button-addon2" v-model="nomRecherche">
          </div>
          <div>
            <button class="btn btn-primary btn-sm" @click="rechercheElevage">Rechercher</button>
          </div>
          
      </div><br>


      <div class="overflow-x-auto md:mx-full  max-sm:mx-4 ">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="">
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider hidden">Id</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Origine</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Catégorie</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="elevage in elevages" :key="elevage.idelevage" class="hover:bg-gray-100">
                            
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7 hidden">{{ elevage.idelevage }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{elevage.nom}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{elevage.origine}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{elevage.categorie}}</td>
                            
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7"><div class="fas fa-eye me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalDetail"  @click="idelevage=elevage.idelevage,image=elevage.image,nom1=elevage.nom,
                              origine1=elevage.origine,categorie1=elevage.categorie; affichageImage()"></div><div class="fas fa-pen-to-square text-blue-600 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalMod" @click="idelevage=elevage.idelevage,
                              nom1=elevage.nom, origine1=elevage.origine,categorie1=elevage.categorie"></div><div class="fas fa-trash-can text-red-700 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalSuppression" @click="idelevage3=elevage.idelevage"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>


      <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      </div>

      


      </div>
      </div>

      <div v-if="showAddModal">
        <div class="modals">
            <div><button class="btn btn-danger" @click="showAddModal=false">Retour</button></div>
            <ul class="nav flex-column">
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                  <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./ElevageAdmin"><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
              </div>
            </ul>
          
        </div>
      </div>

      <div class="modal" role="dialog" tabindex="-1" ref="myModalMenu" >
          <div class="modal-dialog" role="document">
                <div class="modal-content">               
                  <div class="modal-body">
                    <div><button class="btn btn-danger" @click="closeModalMenu">Retour</button></div>
                        <ul class="nav flex-column">
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                              <div><router-link to="./AccueilAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./FournisseurAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CalendrierAdmin"><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CultureAdmin"><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./AbonnementAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./ComparaisonAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" style="text-decoration: none; color:black; font-size: 20px;">Comparaison</router-link></div>
                          </div>
                        </ul> 
                    </div>
                  </div>
                </div>
          </div>

    
    <div class="modal" tabindex="-1" role="dialog" ref="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mon Modal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Contenu du modal -->
            <p>Contenu du modal ici.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Fermer</button>
            <button type="button" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjout" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_elevage" method="POST" @submit.prevent="saveElevage">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ajout</h4>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Image :</label></div>
                        <div class=""><input type="file" class="border w-80 rounded mb-4" ref="imageInput" @change="handleFileChange" required></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-4 pl-2" id="i" v-model="nom" required> </div>
                    </div>
                      
                      <div class="row5">
                        <div><label class="font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-4 pl-2" id="i" v-model="origine" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="font-semibold" for="">Catégorie :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-4 pl-2" id="i" v-model="categorie" required> </div>
                      </div>
                    
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutKarazany">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>


        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_karazany" method="POST"  @submit.prevent="ajoutKarazany">
                <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Ajout karazany</h4>
                    <input type="hidden"  v-model="idelevage2">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><textarea name="" id="i" cols="45" rows="3" v-model="nomKarazany" class="border rounded pl-2" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Description :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="description" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutFikarakarana">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutFikarakarana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_fikarakarana" method="POST"  @submit.prevent="ajoutFikarakarana">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fikarakarana</h4>
                    <input type="hidden" v-model="idelevage2">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Tanjona :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="tanjona" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Dingana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="dingana" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutSakafo">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutSakafo" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_sakafo" method="POST"  @submit.prevent="ajoutSakafo">
                <div class="modal-content">    
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Sakafo</h4>
                    <input type="hidden" v-model="idelevage2">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fotoana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fotoana" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fomba atao :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fombaAtao" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fatrany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fatrany" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjoutFonenany">Suivant</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjoutFonenany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_sakafo" method="POST"  @submit.prevent="ajoutFonenany">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fonenany</h4>
                    <input type="hidden" v-model="idelevage2">
                    <div class="row5">
                        <div><label class="font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="mombamomba" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Refiny :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="refiny" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class="font-semibold" for="">Fitaovana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fitaovana" required></textarea></div>
                    </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Terminer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalMod" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_elevage" method="POST" @submit.prevent="modElevage">
                <div class="modal-content">              
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Modification</h4><br>
                    <input type="hidden" v-model="idelevage">
                    <!--<div class="row5">
                        <div><label class="font-semibold" for="">Image :</label></div>
                        <div class=""><input type="file" class="border w-80 rounded mb-4" ref="imageInput" @change="modImage" required></div>
                    </div>-->
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="nom1" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="origine1" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Catégorie :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4 pl-2" id="i" v-model="categorie1" required> </div>
                      </div><br>
                      <div> <button type="submit" class="bg-blue-500 w-30 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-sm" data-bs-dismiss="modal">Modifier</button></div><br>

                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalModKarazany" @click="detail_karazany">Karazany</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalModFikarakarana" @click="detail_fikarakarana">Fikarakarana</button>
                      </div>
                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalModSakafo" @click="detail_sakafo">Sakafo</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalModFonenany" @click="detail_fonenany">Fonenany</button>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_elevage_karazany" method="POST" @submit.prevent="modElevageKarazany">
              <div class="modal-content">
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Karazany</h4>
                    <input type="hidden" v-model="idelevage">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="nom2" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Description :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="description1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailKarazany">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModFikarakarana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_elevage_fikarakarana" method="POST" @submit.prevent="modElevageFikarakarana">
              <div class="modal-content">
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fikarakarana</h4>
                    <input type="hidden" v-model="idelevage">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Tanjona :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="tanjona1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Dingana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="dingana1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailFikarakarana">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModSakafo" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_elevage_sakafo" method="POST" @submit.prevent="modElevageSakafo">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Sakafo</h4>
                    <input type="hidden" v-model="idelevage">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fotoana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fotoana1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fomba atao :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fombaAtao1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fatrany :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fatrany1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailSakafo">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalModFonenany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_elevage_fonenany" method="POST" @submit.prevent="modElevageFonenany">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fonenany</h4>
                    <input type="hidden" v-model="idelevage">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="mombamomba1" required></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Refiny :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="refiny1" required></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fitaovana :</label></div>
                        <div><textarea class="border rounded pl-2" name="" id="i" cols="45" rows="3" v-model="fitaovana1" required></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-toggle="modal" data-bs-target="#myModalMod"  @click="initDetailFonenany">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalMod">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalSuppression" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/suppression_elevage" method="POST" @submit.prevent="suppressionElevage(); suppressionKarazany(); suppressionFikarakarana();
            suppressionSakafo(); suppressionFonenany()">
                <div class="modal-content">           
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Suppression</h4>
                    <input type="hidden" v-model="idelevage3">
                    <div class="row5">
                        <div>Voulez-vous supprimer cet élevage ?</div>
                    </div>                    

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-sm btn-danger"  data-bs-dismiss="modal">Supprimer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetail"  style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="" method="">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Détail</h4>
                    <input v-model="idelevage" class="hidden">
                      <div class="row5">
                        <div><label class="font-semibold">Image:</label></div>
                        <div class="flex items-center justify-center">
                          <img :src="getImageUrl(image)" class="" alt="Image">
                        </div>
                      </div><br>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="nom1" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Origine :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="origine1" disabled> </div>
                      </div>
                      <div class="row5">
                        <div><label class=" font-semibold" for="">Catégorie :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-4" id="i" v-model="categorie1" disabled> </div>
                      </div><br>

                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalDetailKarazany" @click="detail_karazany" >Karazany</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalDetailFikarakarana" @click="detail_fikarakarana">Fikarakarana</button>
                      </div>
                      <div class="btn btn-group">
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" data-bs-toggle="modal" data-bs-target="#myModalDetailSakafo" @click="detail_sakafo">Sakafo</button>
                        <button class="bg-blue-500 w-30 hover:bg-blue-600 text-white py-1 px-2 rounded text-sm" type="button" style="margin-left: 30px;" data-bs-toggle="modal" data-bs-target="#myModalDetailFonenany" @click="detail_fonenany">Fonenany</button>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" @click="initDetail">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

        

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailKarazany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_karazany" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">karazany</h4>
                    <input v-model="idelevage" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Nom :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="nom2" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Description :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="description1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailKarazany">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailFikarakarana" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_fikarakarana" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fikarakarana</h4>
                    <input v-model="idelevage" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Tanjona :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="tanjona1" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Dingana :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="dingana1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailFikarakarana">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailSakafo" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_sakafo" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Sakafo</h4>
                    <input v-model="idelevage" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fotoana :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="fotoana1" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fomba atao :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="fombaAtao1" disabled></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fatrany :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="fatrany1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailSakafo">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalDetailFonenany" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/affichage_sakafo" method="POST">
              <div class="modal-content">             
                  <div class="modal-body">
                    <h4 style="font-weight: 700; text-decoration: underline;">Fonenany</h4>
                    <input v-model="idelevage" class="hidden">
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Mombamomba :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="mombamomba1" disabled></textarea></div>
                      </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Refiny :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="refiny1" disabled></textarea></div>
                    </div>
                    <div class="row5">
                        <div><label class=" font-semibold" for="">Fitaovana :</label></div>
                        <div><textarea class="border rounded" name="" id="i" cols="45" rows="3" v-model="fitaovana1" disabled></textarea></div>
                    </div>
                                        
                  </div>
                  <div class="modal-footer" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="initDetailFonenany">Fermer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

</template>

<script>
import apiUrl from '../../apiConfig';
import logout from '@/components/logoutAdmin.vue';

import axios from 'axios'

import 'datatables.net-responsive-bs5'
//import 'datatables.net';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.js';
//import DataTable from 'datatables.net-bs5';

import 'datatables.net-responsive-bs5'

export default {
  name: 'ElevageAdmin',
  components: {
    logout
  },
  data () {
    return {
          showModificationModal: false, showSuppressionModal: false, AjoutClientModal: false,
          showNoir: false, showAddModal: false, showDetailModal: false, isModalOpen: false,
          idelevage2: '', showDiv: true, idelevage3: '',
          nom1:'', origine1: '', categorie1:'', nom2:'',
          fotoana:'', fombaAtao:'', fatrany:'', fotoana1:'', fombaAtao1:'', fatrany1:'',
          nomKarazany: '', description: '', nomKarazany1: '', description1: '', image1: '',
          tanjona:'', dingana:'', tanjona1:'', dingana1:'',
          mombamomba:'', refiny:'', fitaovana:'', mombamomba1:'', refiny1:'', fitaovana1:'',
          idelevage:'',nom: '', image: '', origine: '', categorie: '',
          elevage:{
            image:"",nom: "", origine:"", categorie:""
          },
          isModModal: false,
          elevages:[], categories:[], karazany:[],
          divWidth: 15, selectedImage: null, divWidth2: 85,
          imageUrl: null,
    }
  },
  mounted() {
    this.getElevages();
  },

  methods: {
    
    getImageUrl(image) {
      // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
      return apiUrl + `images/${image}`;
    },

   

    affichageImage(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_image', {params:{idelevage: this.elevage.idelevage}}).then(response => {
        const img = response.data.image;
        this.imageUrl= img;
      })
      .catch(error => {(
              console.log(error)
            )}); 
    },
    

    getElevages(){
            axios.get(apiUrl + 'api/affichage_elevage').then(response => {
              this.elevages = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.fournisseurs=[]
            })
      },

    handleFileChange(event) {
      this.image = event.target.files[0];
    },

    modImage(event) {
      this.image1 = event.target.files[0];
    },

    initDetail(){
      this.nom1='', this.origine1='', this.categorie1=''
    },
    initDetailKarazany(){
      this.nom2='', this.description1=''
    },
    initDetailFikarakarana(){
      this.dingana1='', this.tanjona1=''
    },
    initDetailSakafo(){
      this.fombaAtao1='', this.fombaAtao1='', this.fatrany1=''
    },
    initDetailFonenany(){
      this.mombamomba1='', this.refiny1='', this.fitaovana1=''
    },

    saveElevage(){           
            // eslint-disable-next-line no-unused-vars
            const formData = new FormData();
            formData.append('nom', this.nom);
            formData.append('origine', this.origine);
            formData.append('categorie', this.categorie);
            formData.append('image', this.image);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_elevage', formData).then(response => { 
              const idelevage1 = response.data.idelevage;
              //console.log(idelevage1);
              this.idelevage2= idelevage1;
              
                this.image = null; this.nom= '', this.origine='', this.categorie=''
              
            })
            .catch(error => {(
              console.log(error)
            )}); 
    },

    rechercheElevage(){
          axios.get(apiUrl + 'api/recherche_elevage', {params: {nomRecherche: this.nomRecherche}}).then(response => {
                this.elevages = response.data
              }).catch(error =>{
                console.log(error)
              })
      },

    ajoutKarazany(){           
            const formData = new FormData();
            formData.append('nom', this.nomKarazany);
            formData.append('idelevage2', this.idelevage2);
            formData.append('description', this.description);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_karazany', formData).then(response => {
              
                this.nomKarazany='', this.description='';
                console.log('Ajout karazany avec succes');
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutFikarakarana(){           
            const formData = new FormData();
            formData.append('tanjona', this.tanjona);
            formData.append('idelevage2', this.idelevage2);
            formData.append('dingana', this.dingana);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fikarakarana', formData).then(response => {
             
                this.tanjona='', this.dingana='';
                console.log('Ajout fikarakarana avec succes');
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutSakafo(){           
            const formData = new FormData();
            formData.append('fotoana', this.fotoana);
            formData.append('idelevage2', this.idelevage2);
            formData.append('fombaAtao', this.fombaAtao);
            formData.append('fatrany', this.fatrany);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_sakafo', formData).then(response => {
              
                this.fotoana='', this.fombaAtao='', this.fatrany='';
                console.log('Ajout sakafo avec succes');
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    ajoutFonenany(){           
            const formData = new FormData();
            formData.append('mombamomba', this.mombamomba);
            formData.append('idelevage2', this.idelevage2);
            formData.append('refiny', this.refiny);
            formData.append('fitaovana', this.fitaovana);

            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fonenany', formData).then(response => {

                this.getElevages();
                this.mombamomba='', this.refiny='', this.fitaovana='', this.idelevage2='';
                console.log('Ajout fonenany avec succes');
              
            })
            .catch(error => {(
              console.log(error)
            )});
    },

    modElevage(){

            const formData = new FormData();
            formData.append('idelevage', this.idelevage);
            formData.append('nom', this.nom1);
            formData.append('origine', this.origine1);
            formData.append('categorie', this.categorie1);
            

      axios.post(apiUrl + 'api/modification_elevage', {idelevage:this.idelevage, nom1:this.nom1, origine1:this.origine1,categorie1:this.categorie1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getElevages();
                  this.nom1=''; this.origine1='', this.categorie1='';
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modElevageKarazany(){
      axios.post(apiUrl + 'api/modification_elevage_karazany', {idelevage:this.idelevage, nom2:this.nom2, description1:this.description1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getElevages();
                  this.nom2=''; this.description1='';
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modElevageFikarakarana(){
      axios.post(apiUrl + 'api/modification_elevage_fikarakarana', {idelevage:this.idelevage, tanjona1:this.tanjona1, dingana1:this.dingana1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getElevages();
                  this.tanjona1=''; this.dingana1='';
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modElevageSakafo(){
      axios.post(apiUrl + 'api/modification_elevage_sakafo', {idelevage:this.idelevage, fotoana1:this.fotoana1, fombaAtao1:this.fombaAtao1, fatrany1:this.fatrany1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getElevages();
                  this.fotoana1=''; this.fombaAtao1='', this.fatrany1='';
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modElevageFonenany(){
      axios.post(apiUrl + 'api/modification_elevage_fonenany', {idelevage:this.idelevage, mombamomba1:this.mombamomba1, refiny1:this.refiny1, fitaovana1:this.fitaovana1
              // eslint-disable-next-line no-unused-vars
            }).then(response => {
                
                  
                  this.getElevages();
                  this.mombamomba1=''; this.refiny1=''; this.fitaovana1='';
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    detail_karazany(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_karazany',{params:{idelevage3: this.idelevage}}).then(response => {
        this.nom2 = response.data[0].nom;
        this.description1 = response.data[0].description;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_fikarakarana(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_fikarakarana',{params:{idelevage3: this.idelevage}}).then(response => {
        this.tanjona1 = response.data[0].tanjona;
        this.dingana1 = response.data[0].dingana;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_sakafo(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_sakafo',{params:{idelevage3: this.idelevage}}).then(response => {
        this.fotoana1 = response.data[0].fotoana;
        this.fombaAtao1 = response.data[0].fombaAtao;
        this.fatrany1 = response.data[0].fatrany;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    detail_fonenany(){
      // eslint-disable-next-line no-unused-vars
      axios.get(apiUrl + 'api/affichage_fonenany',{params:{idelevage3: this.idelevage}}).then(response => {
        this.mombamomba1 = response.data[0].mombamomba;
        this.refiny1 = response.data[0].refiny;
        this.fitaovana1 = response.data[0].fitaovana;
      })
      .catch(error => {(
        console.log(error)
      )})
    },

    suppressionElevage(){
            axios.post(apiUrl + 'api/suppression_elevage', {idelevage:this.idelevage3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                if(confirm('Fournisseur supprimé avec succès')){
                  this.closeModalSuppression;
                  this.getElevages();
                }
              }).catch(error => {(
                console.log(error)
              )});
    },
     suppressionKarazany(){
            axios.post(apiUrl + 'api/suppression_karazany', {idelevage:this.idelevage3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionFikarakarana(){
            axios.post(apiUrl + 'api/suppression_fikarakarana', {idelevage:this.idelevage3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionSakafo(){
            axios.post(apiUrl + 'api/suppression_sakafo', {idelevage:this.idelevage3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },
    suppressionFonenany(){
            axios.post(apiUrl + 'api/suppression_fonenany', {idelevage:this.idelevage3
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    modificationFournisseur(){
            axios.post(apiUrl + 'api/modification_fournisseur', {idfourni:this.idfourni, nom:this.nom, contact:this.contact,email:this.email,adresse:this.adresse,
              // eslint-disable-next-line no-unused-vars
              statut:this.statut,prodDispo:this.prodDispo,prixUnitaire:this.prixUnitaire,quantite:this.quantite,nomcat:this.nomcat}).then(response => {
                if(confirm('Fournisseur modifié avec succès')){
                  this.closeModalModification();
                  this.getFournisseurs();
                }
              }).catch(error => {(
                console.log(error)
              )});
    },


    // Ajoutez la logique de mise à jour des données ici
  },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../components/styleAdmin/dash1.css';
  
</style>

<head>
  <script src="/docs/5.3/assets/js/color-modes.js"></script>
  <link rel="canonical" href="https://getbootstrap.com/docs/5.3/examples/dashboard/">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@docsearch/css@3">

  <link href="/docs/5.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <!-- Favicons -->
  <link rel="apple-touch-icon" href="/docs/5.3/assets/img/favicons/apple-touch-icon.png" sizes="180x180">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-32x32.png" sizes="32x32" type="image/png">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-16x16.png" sizes="16x16" type="image/png">
  <link rel="manifest" href="/docs/5.3/assets/img/favicons/manifest.json">
  <link rel="mask-icon" href="/docs/5.3/assets/img/favicons/safari-pinned-tab.svg" color="#712cf9">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon.ico">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css" rel="stylesheet">
</head>


