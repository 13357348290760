<template>
    <div>
        <br><br><br>
        <div>

        </div>
        <Footer></Footer>
    </div>
    <Header2></Header2>
</template>
<script>
 import Header2 from '@/components/Header.vue';
 import Footer from '@/components/Footer.vue';
export default {
    components:{
        Header2,Footer
    }
}
</script>