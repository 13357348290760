<template>
  <br>
  <router-link to="../Client/Connection" style="text-decoration: none; color: black">
    <div class="flex pl-6 hover:bg-gray-200 hover:cursor-pointer">
      <div class="fas fa-circle-left text-red-600 py-2"></div>
      <div class="text text-xl py-1 pl-3">Deconnexion</div>
    </div>
  </router-link>
</template>

<script>
export default {
  data(){
    return {

    }
  }
}
</script>