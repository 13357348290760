import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import store from './store'
import '@/assets/tailwind.css'
//import 'bootstrap/dist/css/bootstrap.min.css'
import '../public/css/dashboard.css'
//import '../public/css/bootstrap.min.css'
import '../public/js/bootstrap.min.js'
import '@/assets/fontawesome/css/all.css'
import '@/components/style/modals.css'
// import VuePDF from 'vue-pdf';
createApp(App).use(router).use(store).mount('#app')

