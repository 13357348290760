<template lang="">
    <div class=" w-11/12 ">
                <div class="flex flex-col justify-start w-full items-center">
                    <div class="">
                        <h1 class="max-sm:text-xl sm:text-3xl  font-semibold">
                            Contact
                        </h1>
                    </div>
                    <div class="flex flex-col ">
                        <div class="w-full ">
                            <div class="w-full ">
                                <h2 class="
                      text-dark
                      mb-6
                      uppercase
                      font-bold
                      text-xl
                      ">
                                </h2>
                                <p
                                    class="max-sm:text-sm text-justify text-body-color  leading-relaxed mb-9 text-gray-600">
                                    N'hesitez pas à nous contacter pour toute question, demande d'information ou
                                    assistance.  Nous sommes là pour vous aider.
                                </p>
                            </div>
                        </div>
                        <div class="w-full  flex justify-center">
                            <form class="max-md:w-full md:w-8/12 flex flex-col items-start justify-center bg-white p-4 shadow-sm rounded shadow-gray-300"
                                @submit.prevent="envoyerMessage">
                                <div class="mb-3 w-full">
                                    <input type="text" placeholder="Votre nom" class="
                            w-full
                            placeholder:text-sm
                            py-1
                            px-1
                            text-body-color text-base
                            border border-[f0f0f0]
                            outline-none
                            focus-visible:shadow-none
                            focus:border-primary
                            " v-model="nom" required />
                                </div>
                                <div class="mb-3 w-full">
                                    <input type="email" placeholder="votre e-mail" class="
                            w-full
                            placeholder:text-sm
                            py-1
                            px-1
                            text-body-color text-base
                            border border-[f0f0f0]
                            outline-none
                            focus-visible:shadow-none
                            focus:border-primary
                            " v-model="email" required />
                                </div>
                                <div class="mb-3 w-full">
                                    <input type="text" placeholder="Votre numéro" class="
                            w-full
                            placeholder:text-sm
                            py-1
                            px-1
                            text-body-color text-base
                            border border-[f0f0f0]
                            outline-none
                            focus-visible:shadow-none
                            focus:border-primary
                            " v-model="numero" required />
                                </div>
                                <div class="mb-3 w-full">
                                    <textarea rows="6" placeholder="Votre message" class="
                            w-full
                            
                            py-1
                            px-1
                            text-body-color text-base
                            border border-[f0f0f0]
                            resize-none
                            outline-none
                            placeholder:text-sm
                            focus-visible:shadow-none
                            focus:border-primary
                            " v-model="message" required></textarea>
                                </div>
                                <div class="w-full">
                                    <button type="submit" class="
                            w-full
                            text-white
                            bg-green-600 hover:bg-green-500
                            px-1
                            border border-green-600 hover:border-green-500
                             py-1
                            transition
                            ">
                                        Envoyer
                                    </button>
                                </div>
                            </form>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_ajout fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start"
        v-show="modalValidation">
        <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4 mt-20">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modalValidation = false"></div>
            </div>
            <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2"></div>
            <div class="w-9/12">
                <p class="text-gray-500">Votre message a été bien envoyé !</p>
            </div>
        </div>
    </div>
</template>
<script>
import '@/components/style/header.css';
import '@/components/style/bodyAccueil.css';
import '@/components/style/all.css';
import axios from 'axios'
import apiUrl from '../apiConfig';
export default {
    data() {
        return {
            nom: '',
            email: '',
            numero: '',
            message: '',
            modalValidation: false,
        }
    },
    methods: {
        envoyerMessage() {
            const formData = new FormData();
            formData.append('nom', this.nom);
            formData.append('email', this.email);
            formData.append('numero', this.numero);
            formData.append('message', this.message);
            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/envoyer_message', formData).then(response => {
                this.nom = '', this.email = '', this.numero = '', this.message = '';
                this.modalValidation = true;
                console.log('Message envoyer avec succès');
            })
                .catch(error => {
                    (
                        console.log(error)
                    )
                });
        },
    },

}
</script>
<style lang="">

</style>