// store.js

import { createStore } from 'vuex';

const store = createStore({
    state: {
        user: null,
        typeAbonnement: null,
    },
    getters: {
      userId: state => state.user ? state.user.id : null,
      userName: state => state.user ? state.user.name : null,
      userEmail: state => state.user ? state.user.email : null,
      userAbonnement: state => state.typeAbonnement ? state.typeAbonnement : null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        
        clearUser(state) {
            state.user = null;
        },

        setAbonnement(state, typeAbonnement) {
          state.typeAbonnement = typeAbonnement;
        },

        clearAbonnement(state) {
            state.typeAbonnement = null;
        },
    },
});

export default store;
