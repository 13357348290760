<template>

  <div class="modal-background" v-if="showAddModal"></div>
  <!--<div class="modal-background" v-if="showSuppressionModal"></div>-->
  
    
  <header class="navbar sticky-top p-0" data-bs-theme="white">
    
    <ul class="navbar-nav flex-row d-md-none mr-28 ml-3">
      <li class="nav-item text-nowrap">
        <div class="fas fa-bars-staggered fa-2x text-black pt-1 mr-2" style="width: 40px; height:38px; color: white;" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>
      </li>
    </ul>

    <div class="d-md-none text-xl text-green-600 mr-3">
      Agribusiness
    </div>

</header>

<div class="offcanvas offcanvas-end" style="z-index: 1050;" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
            
            <div style="display: inline-flex; padding-left: 20px; border-radius: 5px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Accueil</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
              <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
              <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
            </div>
        
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
            </div>            
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
            </div>
                      
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
            </div>
          
            <div style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
            </div>

            <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
              <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
              <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class="text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
            </div>
        
        </ul>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="sidebar fixed border border-right col-md-3 col-lg-2 h-full p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">

            <div class="" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                <div><img src="../../assets/logoAgri.jpg" class="logoList" style="width: 40px; height:38px; color: white;"></div>
                <div class=" mt-2 text-2xl text-green-600 ml-3 md:ml-1 md:text-xl md:mt-3">Agribusiness</div>
              </div>
              <div class="border-b-2 mb-3"></div>
            
            <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AccueilAdmin"><div class="fas fa-house text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Statistique</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./UtilisateurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./UtilisateurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Utilisateur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <!--<div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; margin-top: 4px; height: 18px; "></router-link></div>-->
                <div><router-link to="./ClientAdmin"><div class="fas fa-users text-black pt-1 mr-2"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ClientAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Client</router-link></div>
              </div>
          
              <div class="hover:bg-gray-200 py-1" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px; background-color: rgb(207, 205, 205);" aria-current="page" href="#">
                <div><router-link to="./FournisseurAdmin"><div class="fas fa-user text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Fournisseur</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CalendrierAdmin"><div class="fas fa-calendar-days h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Calendrier</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ActeurAdmin"><div class="fas fa-user-graduate h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Acteur</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ElevageAdmin"><div class="fas fa-cow h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Elevage</router-link></div>
              </div>            
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CultureAdmin"><div class="fas fa-leaf h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./CultureAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Culture</router-link></div>
              </div>
                        
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./AbonnementAdmin"><div class="fas fa-coins h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Abonnement</router-link></div>
              </div>
            
              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><div class="fas fa-list h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Catégorie</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./ComparaisonAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Comparaison</router-link></div>
              </div>

              <div class="hover:bg-gray-200" style="display: inline-flex; padding-left: 20px; margin-bottom: 10px; padding-top: 5px;" aria-current="page" href="#">
                <div><router-link to="./PrixAdmin"><div class="fas fa-balance-scale h-7 text-black pt-1 mr-2 w-5"></div></router-link></div>
                <div style="margin-left: 5px;"><router-link to="./PrixAdmin" class=" text-gray-700" style="text-decoration: none; font-size: 20px;">Prix</router-link></div>
              </div>

              <logout></logout>
          
          </ul>
        </div>
      </div>
      </div>

    <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Fournisseur</h1>
        <div class="btn-toolbar mb-2 mb-md-0">
          <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#myModalAjout">Ajouter</button>
        </div>
      </div><br>


      <div class="lg:flex lg:bg-gray-200 sm:bg-gray-50 sm:pt-5 sm:rounded lg:px-20">
  <!-- Colonne 1 -->
    
      <div class="w-full md:flex lg:w-1/2">

        <div class="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 p-2">
          <div class="flex">
            <div class="w-1/3 md:w-1/2 sm:w-1/2 lg:text-right me-2">
              <p class="md:ml-0 sm:ml-2">Produit:</p>
            </div>
            <div class="w-2/3 md:w-1/2 sm:w-1/2">
              <input class="border border-black w-full h-6 rounded-md" type="text" v-model="inpProduit">
            </div>
          </div>
        </div>

      <!-- Colonne 2 -->

      <div class="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 p-2">
        <div class="flex">
          <div class="w-1/3 md:w-1/2 sm:w-1/2 lg:text-right me-2">
            <p>Catégorie:</p>
          </div>
          <div class="w-2/3 md:w-1/2 sm:w-1/2 ">
            <select v-model="inpCat" class="border border-black w-full h-6 rounded-md">
              <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
            </select>
          </div>
        </div>
      </div>

      </div>
  

      <div class="md:flex">

        <div class="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 p-2">
        <div class="flex">
          <div class="w-1/3 md:w-1/2 lg:text-right me-2">
            <p>Prix entre:</p>
          </div>
          <div class="w-2/3 md:w-1/2">
            <input class="border border-black w-full h-6 rounded-md" type="number" v-model="inpPrix1">
          </div>
        </div>
      </div>
    
      <div class="w-full lg:w-1/2 md:w-1/2 sm:w-1/2 p-2">
        <div class="flex">
          <div class="w-1/3 md:w-1/2 lg:text-right me-2">
            <p >Et:</p>
          </div>
          <div class="w-2/3 md:w-1/2 flex">
            <input class="border border-black w-full h-6 rounded-md" type="number" v-model="inpPrix2">
          </div>
        </div>
      </div>

      </div>
  

</div>
<div class="w-full md:w-full p-2">
    <div class="flex">
      <div class="w-1/2 md:w-1/2">
        <button class="btn btn-sm btn-primary" style="margin-left: -48px;" @click="getFournisseurs(), init()">Liste fournisseurs</button> 
      </div>
      <div class="w-1/2 md:w-1/2">
        <button class="btn btn-sm btn-primary " @click="rechercheFournisseur()">Rechercher</button>
      </div>
    </div>
  </div><br>


      

  <div class="overflow-x-auto md:mx-full  max-sm:mx-4 ">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="">
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Adresse</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Produit disponible</th>
                        <th class="md:px-6 md:py-3 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="fournisseur in fournisseurs" :key="fournisseur.idfourni">
                            <td style="display: none;">{{ fournisseur.idfourni }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{ fournisseur.nom }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{fournisseur.contact}}</td>
                            <td style="display: none;">{{fournisseur.email}}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{fournisseur.adresse}}</td>
                            <td style="display: none;">{{ fournisseur.statut }}</td>
                            <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 max-sm:text-xs md:text-sm whitespace-no-wrap px-7">{{ fournisseur.prodDispo }}</td>
                            <td style="display: none;">{{ fournisseur.prixUnitaire }}</td>
                            <td style="display: none;">{{ fournisseur.quantite }}</td>
                            <td style="display: none;">{{ fournisseur.nomcat }}</td>
                            <td class="btns md:flex sm:flex md:px-6 sm:px-3 sm:py-2 md:py-4"><div class="fas fa-eye me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalDetail" @click="idfourni=fournisseur.idfourni,nom=fournisseur.nom, contact=fournisseur.contact, email=fournisseur.email,adresse=fournisseur.adresse,statut=fournisseur.statut,
                              prodDispo=fournisseur.prodDispo,prixUnitaire=fournisseur.prixUnitaire,unite=fournisseur.unite,quantite=fournisseur.quantite,nomcat=fournisseur.nomcat"></div><div class="fas fa-pen-to-square text-blue-600 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalModification" @click="idfourni=fournisseur.idfourni,nom=fournisseur.nom, contact=fournisseur.contact, email=fournisseur.email,adresse=fournisseur.adresse,statut=fournisseur.statut,
                              prodDispo=fournisseur.prodDispo,prixUnitaire=fournisseur.prixUnitaire,unite=fournisseur.unite,quantite=fournisseur.quantite,nomcat=fournisseur.nomcat"></div><div class="fas fa-trash-can text-red-700 me-3 hover:cursor-pointer" data-bs-toggle="modal" data-bs-target="#myModalSuppression" @click="idfourni1=fournisseur.idfournii"></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>

      <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
      </div>

      


      </div>
      </div>

      <div v-if="showAddModal">
        <div class="modals">
            <div><button class="btn btn-danger" @click="showAddModal=false">Retour</button></div>
            <ul class="nav flex-column">
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                  <div><router-link to="./AccueilAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./AccueilAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./CalendrierAdmin"><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CalendrierAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                  <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></div>
                  <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
              </div>
              <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                  <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
              </div>
            </ul>
          
        </div>
      </div>

      <div class="modal" role="dialog" tabindex="-1" ref="myModalMenu" >
          <div class="modal-dialog" role="document">
                <div class="modal-content">               
                  <div class="modal-body">
                    <div><button class="btn btn-danger" @click="closeModalMenu">Retour</button></div>
                        <ul class="nav flex-column">
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px; margin-top: 15px" aria-current="page" href="#">
                              <div><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Tableau de bord</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/user.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Utilisateur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ClientAdmin"><img src="../../assets/customer.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Client</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./FournisseurAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./FournisseurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Fournisseur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><img src="../../assets/calendar.png" class="logoList" style="width: 15px; color: white; "></div>
                              <div style="margin-left: 5px;"><router-link to="./ClientAdmin" style="text-decoration: none; color:black; font-size: 20px;">Calendrier agricole</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ActeurAdmin"><img src="../../assets/actor.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ActeurAdmin" style="text-decoration: none; color:black; font-size: 20px;">Acteur</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./ElevageAdmin"><img src="../../assets/animals.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ElevageAdmin" style="text-decoration: none; color:black; font-size: 20px;">Elevage</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./CultureAdmin"><img src="../../assets/culture.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CultureAdmin" style="text-decoration: none; color:black; font-size: 20px;">Culture</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 10px;" aria-current="page" href="#">
                              <div><router-link to="./AbonnementAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./AbonnementAdmin" style="text-decoration: none; color:black; font-size: 20px;">Abonnement</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./CategorieAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./CategorieAdmin" style="text-decoration: none; color:black; font-size: 20px;">Catégorie</router-link></div>
                          </div>
                          <div style="display: inline-flex; margin-left: 20px; margin-bottom: 15px;" aria-current="page" href="#">
                              <div><router-link to="./ComparaisonAdmin"><img src="../../assets/home.png" class="logoList" style="width: 15px; color: white; "></router-link></div>
                              <div style="margin-left: 5px;"><router-link to="./ComparaisonAdmin" style="text-decoration: none; color:black; font-size: 20px;">Comparaison</router-link></div>
                          </div>
                        </ul> 
                    </div>
                  </div>
                </div>
          </div>
 
    <div class="modal" tabindex="-1" role="dialog" ref="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mon Modal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Contenu du modal -->
            <p>Contenu du modal ici.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Fermer</button>
            <button type="button" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>

    
        <div class="modal fade" role="dialog" tabindex="-1" id="myModalAjout" style="z-index: 1050;" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_fournisseur" method="POST" @submit.prevent="saveFournisseur">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style=" text-decoration: underline;">Ajout</h4>            
                    <div class="row5">
                        <div><label class="" for="">Nom :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.nom" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Contact :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.contact" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Email :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.email" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Adresse :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.adresse" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Statut :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.statut" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Produit disponible :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.prodDispo" required></div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Prix unitaire (en Ar) :</label></div>
                        <div><input type="number" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.prixUnitaire" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Unité :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.unite" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Quantité :</label></div>
                        <div><input type="text" class=" border w-80 rounded mb-1 pl-2" id="i" v-model="fournisseur.quantite" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Catégorie :</label></div>
                        <!--<div><input type="text" class="inps" id="i" v-model="client.domaine" required> </div>-->
                        <select v-model="fournisseur.nomcat" class=" border w-80 rounded mb-1 pl-2">
                          <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                        </select>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary me-5" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal fade" role="dialog" tabindex="-1" id="myModalSuppression" style="z-index: 1050;">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/suppression_client" method="POST" @submit.prevent="suppressionFournisseur">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style=" text-decoration: underline;">Suppression</h4>  
                    <input v-model="idfourni1" type="hidden">        
                    <div class="row5">
                        <div>Voulez-vous supprimer ce fournisseur ?</div>
                    </div>                    

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-sm btn-secondary me-4" data-bs-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-sm btn-sm btn-danger" data-bs-dismiss="modal">Supprimer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

      <div class="modal" role="dialog" tabindex="-1" id="myModalModification" style="z-index: 1050;">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/modification_fournisseur" method="POST" @submit.prevent="modificationFournisseur">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style=" text-decoration: underline;">Modification</h4>  
                    <input type="hidden" v-model="idfourni">               
                    <div class="row5">
                        <div><label class=" " for="">Nom :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="nom" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Contact :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="contact" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Email :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="email" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Adresse :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="adresse" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Statut :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="statut" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Produit disponible :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="prodDispo" required></div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Prix unitaire (en Ar) :</label></div>
                        <div><input type="number" class="border w-80 rounded mb-1 pl-2" id="i" v-model="prixUnitaire" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Unité :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="unite" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Quantité :</label></div>
                        <div><input type="text" class="border w-80 rounded mb-1 pl-2" id="i" v-model="quantite" required> </div>
                      </div>
                      <div class="row5">
                        <div><label class="" for="">Catégorie :</label></div>
                        <!--<div><input type="text" class="inps" id="i" v-model="client.domaine" required> </div>-->
                        <select v-model="nomcat" class="border w-80 rounded mb-1 pl-2">
                          <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                        </select>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" >Fermer</button>
                    <button type="submit" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Enregistrer</button>
                  </div>
                </div>
              </form>
          </div>
        </div>

        <div class="modal" role="dialog" tabindex="-1" id="myModalDetail" style="z-index: 1050;">
          <div class="modal-dialog" role="document">
            <form action="http://127.0.0.1:8000/api/ajout_client" method="POST">
                <div class="modal-content">                
                  <div class="modal-body">
                    <h4 style=" text-decoration: underline;">Détail</h4>
                    <input type="hidden" v-model="idcli"> 
                    <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Nom :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="nom" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Contact :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="contact" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Email :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="email" disabled>
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Adresse :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="adresse" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Statut :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="statut" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Produit demandé :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="prodDispo" disabled>
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Prix unitaire (en Ar) :</label>
                        <input type="number" class="ml-3 bg-white w-40" id="i" v-model="prixUnitaire" disabled>
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Unité :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="unite" disabled>
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class=" " for="">Quantité :</label>
                        <input type="text" class="ml-3 bg-white" id="i" v-model="quantite" disabled> 
                      </div>
                      <div class="flex lg:ml-24 md:ml-24 mb-2 sm:ml-8">
                        <label class="" for="">Catégorie :</label>
                        <!--<div><input type="text" class="inps" id="i" v-model="client.domaine" required> </div>-->
                        <input type="text" class="ml-3 bg-white" id="i" v-model="nomcat" disabled>
                      </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-center" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal" @click="init1">Fermer</button>
                    
                  </div>
                </div>
              </form>
          </div>
        </div>

  

</template>

<script>
import apiUrl from '../../apiConfig';
import logout from '@/components/logoutAdmin.vue';

import axios from 'axios'
import 'datatables.net-responsive-bs5'
//import 'datatables.net';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.js';
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net-bs5';

import 'datatables.net-responsive-bs5'
//import DataTable from 'datatables.net-vue3';
export default {
  name: 'FournisseurAdmin',
  components: {
    logout
  },
  data () {
    return {
          showModificationModal: false, showSuppressionModal: false, AjoutClientModal: false, showAddModal: false, showDetailModal: false,
          isModalOpen: false, catSelection: null, inpProduit: '', inpPrix1:'', idfourni1: '', inpPrix2:'', inpCat:'', showDiv: true,
          nom:'', contact:'', email:'', adresse:'', type:'', prodDispo:'', prixUnitaire:'',unite:"", quantite:'', nomcat:'', idfourni:'',
          fournisseur:{
            nom: "", contact:"", email:"", adresse:"", type:"", prodDispo:"", prixUnitaire:"", unite:"", quantite:"", nomcat:""
          },
          isModModal: false, fournisseurs:[], categories:[], divWidth: 15, divWidth2: 85,
    }
  },
  mounted() {
    this.getFournisseurs();
    this.categorieSelection();
  },

  methods: {

    getFournisseurs(){
            axios.get(apiUrl + 'api/affichage_fournisseur').then(response => {
              this.fournisseurs = response.data
              // eslint-disable-next-line no-undef
              
            }).catch(error =>{
              console.log(error)
              this.fournisseurs=[]
            })
    },
    categorieSelection(){
            axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
              this.categories = response.data
            }).catch(error =>{
              console.log(error)
              
            })
    },
    saveFournisseur(){
            // eslint-disable-next-line no-unused-vars
            axios.post(apiUrl + 'api/ajout_fournisseur', this.fournisseur).then(response => { 
             
                this.getFournisseurs();
                this.fournisseur.nom= ""; this.fournisseur.contact=""; this.fournisseur.email=""; this.fournisseur.adresse=""; this.fournisseur.statut=""; this.fournisseur.prodDispo=""; this.fournisseur.prixUnitaire="";
                this.fournisseur.quantite=""; this.fournisseur.nomcat=""; this.fournisseur.unite="";
              
            })
            .catch(error => {(
              console.log(error)
            )});
            
          },
    suppressionFournisseur(){
            axios.post(apiUrl + 'api/suppression_fournisseur', {idfourni:this.idfourni1
              // eslint-disable-next-line no-unused-vars
                }).then(response => {
                
                  
                  this.getFournisseurs();
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    rechercheFournisseur(){
            axios.get(apiUrl + 'api/recherche_fournisseur', {params: {inpProduit: this.inpProduit, inpPrix1:this.inpPrix1, inpPrix2:this.inpPrix2, inpCat:this.inpCat}}).then(response => {
              this.fournisseurs = response.data
            }).catch(error =>{
              console.log(error)
            })
    },

    modificationFournisseur(){
            axios.post(apiUrl + 'api/modification_fournisseur', {idfourni:this.idfourni, nom:this.nom, contact:this.contact,email:this.email,adresse:this.adresse,
              // eslint-disable-next-line no-unused-vars
              statut:this.statut,prodDispo:this.prodDispo,prixUnitaire:this.prixUnitaire,unite:this.unite,quantite:this.quantite,nomcat:this.nomcat}).then(response => {
                
                  this.getFournisseurs();
                  this.nom='', this.contact='', this.email='', this.adresse='', this.statut='', this.prodDispo='', this.prixUnitaire='',this.unite='', this.quantite='', this.nomcat=''
                
              }).catch(error => {(
                console.log(error)
              )});
    },

    init(){
        this.inpProduit='', this.inpPrix1='', this.inpPrix2='', this.inpCat=''
    },

    init1(){
      this.nom='', this.contact='', this.email='', this.adresse='', this.statut='', this.prodDispo='', this.prixUnitaire='', this.quantite='', this.nomcat='' 
    },

    // Ajoutez la logique de mise à jour des données ici
  },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../components/styleAdmin/dash1.css';
  
</style>

<head>
  <script src="/docs/5.3/assets/js/color-modes.js"></script>
  <link rel="canonical" href="https://getbootstrap.com/docs/5.3/examples/dashboard/">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@docsearch/css@3">

  <link href="/docs/5.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">

  <!-- Favicons -->
  <link rel="apple-touch-icon" href="/docs/5.3/assets/img/favicons/apple-touch-icon.png" sizes="180x180">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-32x32.png" sizes="32x32" type="image/png">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon-16x16.png" sizes="16x16" type="image/png">
  <link rel="manifest" href="/docs/5.3/assets/img/favicons/manifest.json">
  <link rel="mask-icon" href="/docs/5.3/assets/img/favicons/safari-pinned-tab.svg" color="#712cf9">
  <link rel="icon" href="/docs/5.3/assets/img/favicons/favicon.ico">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.min.css" rel="stylesheet">
</head>


